<template>
  <div class="el-header">
    <div class="head_logo">
      <!--            <img src="@/assets/image/logo.png">-->
      安山PDA仓库管理系统
    </div>
    <div class="userinfo">
      <el-dropdown trigger="hover">
                    <span class="el-dropdown-link userinfo-inner">
                        欢迎您，{{ userInfo.username }}
                         <el-avatar v-if="!userInfo.headPortraitStr" class="imgs" icon="el-icon-user-solid"></el-avatar>
<img v-else class="imgs" :src="'data:'+userInfo.photoType+';base64,'+userInfo.headPortraitStr" alt="">
                    </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item divided @click="getUpdatePwd">修改密码</el-dropdown-item>
            <el-dropdown-item divided @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import localStore from "../../utils/storage";
import { Base64 } from 'js-base64';

export default {
  name: "Header",
  data() {
    return {
      user: localStore.get('warehouse_userInfo'),
      userInfo: {},
      dialogArr: []
    }
  },

  mounted() {
    this.user = localStore.get('warehouse_userInfo');
    if (this.user) {
      this.userInfo.id = this.user.id
      this.userInfo.username = this.user.empName
      this.$router.push('/')
    } else {
      this.$router.push('/Login')
    }
  },
  methods: {
    //修改密码
    getUpdatePwd() {
      this.$prompt('请输入新密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /^.{3,8}$/,
        inputPattern: /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}/,
        inputType: 'password',
        inputErrorMessage: '密码格式不正确，密码中必须包含字母、数字、至少8个字符，最多30个字符',
        closeOnClickModal: false
      }).then(({value}) => {
        this.postFormRequest("/sys/tb-employee/updatePassword", {
          id: this.userInfo.id,
          // newPwd: value
          newPwd: Base64.encode(value),
        }).then(data => {
          if (data.success) {
            this.$message.info('密码修改成功')
            this.$router.push('/Login')
          } else {
            this.$message.info('密码修改失败')
          }
        }).catch(() => {
          this.$message.info('密码修改失败')
        })
      }).catch(() => {
      });
    },
    //退出登录
    logout() {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        localStore.remove('warehouse_userInfo')
        localStore.remove('warehouse_username')
        localStore.remove('warehouse_token')
        this.$router.push('/Login')
        location.reload();
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出登录'
        });
      });
    },
  }
}
</script>

<style scoped>
/*头部样式*/
.el-header {
  width: calc(100% - 50px);
  display: flex;
  background: #ffffff;
}

.head_logo {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.head_logo img {
  width: 90px;
  margin-right: 10px;
}

.head_logo span {
  background: #4F8AFF;
  font-size: 12px;
  color: #ffffff;
  padding: 2px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
  margin-left: 5px;
  box-sizing: border-box;
}

.userinfo {
  text-align: right;
  float: right;
}

.userinfo .userinfo-inner {
  cursor: pointer;
  color: #000000;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.userinfo .userinfo-inner .imgs {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: 5px 0px 5px 10px;
  float: right;
}

</style>
