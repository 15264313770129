<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="垛位名称/编码：">
          <el-input v-model="selectForm.pileName" placeholder="请输入垛位名称/编码" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="所属库存地：">
          <el-select v-model="selectForm.stockId" placeholder="请选择所属库存地" size="mini"
                     clearable @change="clearChange">
            <el-option v-for="(item,index) in stockIdList" :key="index" :label="item.stockName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="垛位状态：">
          <el-select v-model="selectForm.status" placeholder="请选择垛位状态" size="mini"
                     clearable @change="clearChange">
            <el-option label="正常" value="0"></el-option>
            <el-option label="禁用" value="1"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button class="btn" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增垛位</el-button>
      </div>
      <el-table
          :data="tbStackingList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="pileCode"
            label="垛位编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="pileName"
            label="垛位名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="stackingDesc"
            label="垛位描述"
            align="center">
          <template #default="scope">
            {{ scope.row.stackingDesc ? scope.row.stackingDesc : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="stockName"
            label="所属库存地"
            align="center">
        </el-table-column>
        <el-table-column
            prop="upperLimit"
            label="库存上限值（捆）"
            align="center">
        </el-table-column>
        <el-table-column
            prop="upperLimitWarn"
            label="库存上限预警值（捆）"
            align="center"
            width="180px">
        </el-table-column>
        <el-table-column
            prop="status"
            label="垛位状态"
            align="center">
          <template #default="scope">
            {{ scope.row.status == 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               :title="title"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbStackingForm" :rules="rules" label-position="top" ref="tbStackingForm">
          <el-form-item label="垛位编码" prop="pileCode">
            <el-input v-model="tbStackingForm.pileCode" placeholder="请填写垛位编码" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="垛位名称" prop="pileName">
            <el-input v-model="tbStackingForm.pileName" placeholder="请填写垛位名称" maxlength="20" clearable
                      type="text" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="垛位描述" prop="stackingDesc">
            <el-input v-model="tbStackingForm.stackingDesc" maxlength="50" placeholder="请填写垛位描述" clearable
                      type="text"
                      size="mini"></el-input>
          </el-form-item>
          <el-form-item label="所属库存地" prop="stockId">
            <el-select v-model="tbStackingForm.stockId" placeholder="请选择所属库存地" size="mini" clearable>
              <el-option v-for="(item,index) in stockIdList" :key="index" :label="item.stockName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="库存上限值" prop="upperLimit">
            <el-input v-model="tbStackingForm.upperLimit" maxlength="7" placeholder="请填写库存上限值" clearable
                      type="text"
                      size="mini"></el-input>
          </el-form-item>
          <el-form-item label="库存上限预警值" prop="upperLimitWarn">
            <el-input v-model="tbStackingForm.upperLimitWarn" maxlength="7" placeholder="请填写库存上限预警值"
                      clearable type="text"
                      size="mini"></el-input>
          </el-form-item>
          <el-form-item label="垛位状态" prop="status">
            <el-select v-model="tbStackingForm.status" placeholder="请选择垛位状态" size="mini" clearable>
              <el-option label="正常" :value="0"></el-option>
              <el-option label="禁用" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbStackingForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
// import localStore from "../utils/storage"
export default {
  name: "StackingManagement",
  data() {
    const blurText = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('编码不能为空'))
      } else {
        if (this.rowInfo) {
          if (this.rowInfo.pileCode == value) {
            callback()
          }
        } else if (!/^[A-Za-z0-9]+$/.test(value)) {
          callback(new Error('垛位编码格式错误'))
        }
        this.postFormRequest("/tb-pile-info/exists", {pileCode: value}).then(data => {
          if (data.data) {
            callback(new Error('编码存在'))
          } else {
            callback()
          }
        });
      }
    };
    const blurText1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('名称不能为空'))
      } else {
        if (this.rowInfo) {
          if (this.rowInfo.pileName == value) {
            callback()
          }
        }
        this.postFormRequest("/tb-pile-info/existsName", {pileName: value}).then(data => {
          if (data.data) {
            callback(new Error('名称存在'))
          } else {
            callback()
          }
        });
      }
    }
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        pileName: '',
        pileCode: '',
        stockId: '',
        status: ''
      },
      stockIdList: [],
      tbStackingList: [],
      showDrawer: false,
      title: '',
      rowInfo: null,
      tbStackingForm: {
        id: '',
        pileCode: '',
        pileName: '',
        stackingDesc: '',
        stockId: '',
        upperLimit: '',
        warning: '',
        status: 0,
      },
      loading: false,
      drawerAction: 'add',
      rules: {
        pileCode: [
          {
            required: true,
            message: '请输入垛位编号',
            trigger: 'blur'
          },
          {validator: blurText, trigger: 'blur'}
        ],
        pileName: [
          {
            required: true,
            message: '请输入垛位名称',
            trigger: 'blur'
          },
          {validator: blurText1, trigger: 'blur'}
        ],
        stockId: [
          {
            required: true,
            message: '请选择所属库存地',
            trigger: 'change'
          },
        ],
        upperLimit: [
          {
            required: true,
            message: '请输入库存上限值',
            trigger: 'blur'
          },
        ],
        upperLimitWarn: [
          {
            required: true,
            message: '请输入库存上限预警值',
            trigger: 'blur'
          }
        ],
      }
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.selectStock();
  },
  methods: {
    //查询所有的库存地
    selectStock() {
      this.postFormRequest("/tb-stock-addr/selectStock").then(res => {
        if (res.success) {
          this.stockIdList = res.data;
        }
      }).catch(() => {

      })
    },
    //查询
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-pile-info/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.selectStock();
      this.rowInfo = null;
      if (action == 'add') {
        this.title = '新增垛位'
        this.drawerAction = 'add'
      } else {
        this.title = '修改垛位'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.rowInfo = row;
        this.tbStackingForm['id'] = row['id'];
        this.tbStackingForm['pileCode'] = row['pileCode'];
        this.tbStackingForm['pileName'] = row['pileName'];
        this.tbStackingForm['stackingDesc'] = row['stackingDesc'];
        this.tbStackingForm['stockId'] = row['stockId'];
        this.tbStackingForm['upperLimit'] = row['upperLimit'];
        this.tbStackingForm['upperLimitWarn'] = row['upperLimitWarn'];
        this.tbStackingForm['status'] = row['status'];
      } else {
        this.tbStackingForm = {
          pileCode: '',
          pileName: '',
          stackingDesc: '',
          stockId: '',
          upperLimit: '',
          upperLimitWarn: '',
          status: '',
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-pile-info/save", this.tbStackingForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.tbStackingForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-pile-info/updateById", this.tbStackingForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.tbStackingForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.tbStackingForm = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.tbStackingForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize;
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-pile-info/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbStackingList = res.data.records;
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["tbStackingForm"] != undefined) {
        this.$refs["tbStackingForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

