<template>
        <div style="width: 100%">
            <div class="subMain">
                <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
                    <el-form-item class="row" label="规格名称/编码：">
                        <el-input v-model="selectForm.specTypeName" placeholder="请输入规格名称/编码" size="mini"
                                  clearable type="text" @change="clearChange"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="btn" size="mini" @click="readData">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
        <div class="main">
            <div style="text-align: right">
                <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增
                </el-button>
            </div>
            <el-table
                    :data="InventoryList"
                    border
                    stripe
                    :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                    :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        :index="table_index"
                        fixed="left"
                        width="80">
                </el-table-column>
                <el-table-column
                        label="规格类型编码"
                        prop="specTypeCode"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="规格类型名称"
                        prop="specTypeName"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="规格状态"
                        prop="status"
                        align="center">
                    <template #default="scope">
                        {{ scope.row.status==0 ? '正常' : '禁用' }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="创建时间"
                        align="center">
                    <template #default="scope">
                        {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="updateTime"
                        label="更新时间"
                        align="center">
                    <template #default="scope">
                        {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        fixed="right"
                        align="center">
                    <template v-slot="scope">
                        <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑
                        </el-button>
                        <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currPage"
                    :page-sizes="[10,20,30,40]"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <!--新增修改-->
            <el-drawer custom-class="drawerMain"
                       v-model="showDrawer"
                       v-if="showDrawer"
                       :title="title"
                       direction="rtl">
                <div class="drawer_content">
                    <el-form :model="InventoryForm" :rules="rules" label-position="top" ref="InventoryForm">
                        <el-form-item label="规格类型编码" prop="specTypeCode">
                            <el-input v-model="InventoryForm.specTypeCode" placeholder="请填写规格类型编码" clearable type="text"
                                      size="mini" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="规格类型名称" prop="specTypeName">
                            <el-input v-model="InventoryForm.specTypeName" placeholder="请填写规格类型名称" clearable type="text"
                                      size="mini" maxlength="20"></el-input>
                        </el-form-item>
                        <el-form-item label="规格状态" prop="status">
                            <el-select v-model="InventoryForm.status" placeholder="请选择规格状态" size="mini"
                                       clearable>
                                <el-option v-for="(item,index) in statusList" :key="index" :label="item.label"
                                           :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <div class="drawer-footer-height"></div>
                    <div class="dialog-footer">
                        <el-button @click="cancelForm" size="mini">取 消</el-button>
                        <el-button type="primary" size="mini" @click="InventoryClick('InventoryForm',drawerAction)"
                                   :loading="loading">{{ loading ? '提交中...' : '确 定' }}
                        </el-button>
                    </div>
                </div>

            </el-drawer>
        </div>
    </div>
</template>

<script>
    // import localStore from "../utils/storage";
    import {statusList} from '../common/baseData';

    const _statusList = statusList;
    export default {

        name: "TbSpecInfoMessagePage",
        data() {
            const blurText = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('规格信息编码不能为空'))
                } else {
                    if (this.rowInfo) {
                        if (this.rowInfo.specTypeCode == value) {
                            callback()
                        }
                    }else if (!/^[A-Za-z0-9]+$/.test(value)) {
                      callback(new Error('规格信息编码格式错误'))
                    }
                    this.postFormRequest("/tb-spec-type/exists", {specTypeCode: value}).then(data => {
                        if (data.data) {
                            callback(new Error('规格信息编码存在'))
                        } else {
                            callback()
                        }
                    });
                }
            }
            return {
                total: 2,
                currPage: 1,
                rowInfo: null,
                pageSize: 10,
                selectForm: {specTypeCode: '', specTypeName: ''},
                codeList: [],
                specList: [],
                statusList: _statusList,
                InventoryList: [],
                // 修改
                showDrawer: false,
                InventoryForm: {
                    id: '',
                    specTypeCode: '',
                    specTypeName: '',
                    status: '',
                },
                title: '',
                drawerAction: 'add',
              rules: {
                specTypeCode: [
                  {
                    required: true,
                    message: '请输入规格类型编码',
                    trigger: 'blur'
                  },
                    {validator: blurText, trigger: 'blur'}
                ],
                specTypeName: [
                  {
                    required: true,
                    message: '请输入规格类型名称',
                    trigger: 'blur'
                  }
                ],
                status: [
                  {
                    required: true,
                    message: '请选择规格状态',
                    trigger: 'change'
                  }
                ],
              }
            }
        },
        mounted() {
            this.currentPageChanged(this.currPage, this.pageSize);
        },
        methods: {
            //清空查询自动加载列表
            readData() {
                this.handleCurrentChange(1);
            },
          clearChange(){
            this.currentPageChanged(this.currPage, this.pageSize);
          },
            // 删除
            handleDelete(id) {
                if (id >= 0) {
                    this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.getRequest("/tb-spec-type/delete/" + id).then(res => {
                            if (res.success) {
                                this.$message.success("删除成功");
                                this.currentPageChanged(this.currPage, this.pageSize);
                            }
                        })
                    }).catch(() => {
                    })
                }
            },
            //展示抽屉的方式
            showDrawerFunc(action, row) {
                this.rowInfo = null;
                if (action == 'add') {
                    this.title = '新增规格信息'
                    this.drawerAction = 'add'
                } else {
                    this.title = '编辑规格信息'
                    this.drawerAction = 'edit'
                }
                this.showDrawer = true;//展开抽屉
                if (row) {
                    this.rowInfo = row;
                    this.InventoryForm['id'] = row['id'];
                    this.InventoryForm['specTypeCode'] = row['specTypeCode'];
                    this.InventoryForm['specTypeName'] = row['specTypeName'];
                    this.InventoryForm['status'] = row['status'];
                } else {
                    this.InventoryForm = {
                        id: '',
                        specTypeCode: '',
                        specTypeName: '',
                        status: '',
                    }
                }
            },
            //提交按钮被点击时
            InventoryClick(formName, action) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (action == 'add') {
                            //this. InventoryForm.parentId ? this. InventoryForm.parentId : 0;
                            this.postFormRequest("/tb-spec-type/save", this.InventoryForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false
                                        this.$message.success('添加成功')
                                        this.$refs.InventoryForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    this.$message.error(res.msg)
                                    return false;
                                }
                            })
                        } else {
                            this.postFormRequest("/tb-spec-type/updateById", this.InventoryForm).then(res => {
                                if (res.success) {
                                    this.$message.success('修改成功')
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$refs.InventoryForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    this.$message.error(res.msg)
                                    return false;
                                }
                            })
                        }
                    }
                })
            },
            //取消按钮
            cancelForm() {
                this.form = {}
                this.loading = false;
                this.showDrawer = false;
                this.$refs.InventoryForm.resetFields();
            },
            // 分页
            table_index(index) {
                return (this.currPage - 1) * this.pageSize + index + 1
            },
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            },
            handleCurrentChange: function (currPage) {
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);

            },
            currentPageChanged: function (currPage, pageSize) {
                this.selectForm['current'] = currPage
                this.selectForm['size'] = pageSize
                let requestForm = {}
                for (let key in this.selectForm) {
                    if (this.selectForm[key]) {
                        requestForm[key] = this.selectForm[key]
                    }
                }
                this.postFormRequest("/tb-spec-type/listByPage", requestForm
                ).then(res => {
                    if (res.success) {
                        this.InventoryList = res.data.records
                        this.total = res.data.total
                    }
                })
            },
        },
        watch: {
            showDrawer: function () {
                if (this.$refs["InventoryForm"] != undefined) {
                    this.$refs["InventoryForm"].resetFields();
                }
            }
        },
    }
</script>

<style lang="scss">
</style>
