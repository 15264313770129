<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="加工中心名称/编码：">
          <el-input v-model="selectForm.cncName" placeholder="请输入加工中心名称/编码" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增</el-button>
      </div>
      <el-table
          :data="InventoryList"
          border
          stripe
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            fixed="left"
            width="80">
        </el-table-column>
        <el-table-column
            label="加工中心编码"
            prop="cncCode"
            align="center">
        </el-table-column>
        <el-table-column
            label="加工中心名称"
            prop="cncName"
            align="center">
        </el-table-column>
        <el-table-column
            label="上级生产中心"
            prop="parentCncName"
            align="center">
          <template #default="scope">
            {{ scope.row.parentCncName ? scope.row.parentCncName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="加工中心状态 "
            prop="status"
            align="center">
          <template #default="scope">
            {{ scope.row.status == 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
            label="描述"
            prop="describeCnc"
            align="center">
          <template #default="scope">
            {{ scope.row.describeCnc ? scope.row.describeCnc : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            fixed="right"
            align="center">
          <template v-slot="scope">
            <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[10,20,30,40]"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!--新增修改-->
      <el-drawer custom-class="drawerMain"
                 v-model="showDrawer"
                 v-if="showDrawer"
                 :title="title"
                 direction="rtl">
        <div class="drawer_content">
          <el-form :model="InventoryForm" :rules="rules" label-position="top" ref="InventoryForm">
            <el-form-item label="加工中心编码" prop="cncCode">
              <el-input v-model="InventoryForm.cncCode" placeholder="请输入加工中心编码" size="mini"
                        clearable type="text" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="加工中心名称" prop="cncName">
              <el-input v-model="InventoryForm.cncName" placeholder="请输入加工中心名称" size="mini"
                        clearable type="text" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="上级生产中心" prop="parentId">
              <el-select v-model="InventoryForm.parentId" placeholder="请选择上级品类" size="mini" clearable>
                <el-option v-for="(item,index) in parentList" :key="index" :label="item.cncName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="加工中心状态" prop="status">
              <el-select v-model="InventoryForm.status" placeholder="请选择上级品类" size="mini" clearable>
                <el-option v-for="(item,index) in statusList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="描述" prop="describe">
              <el-input v-model="InventoryForm.describeCnc" placeholder="请输入描述" size="mini"
                        clearable type="text" maxlength="20"></el-input>
            </el-form-item>
          </el-form>
          <div class="drawer-footer-height"></div>
          <div class="dialog-footer">
            <el-button @click="cancelForm" size="mini">取 消</el-button>
            <el-button type="primary" size="mini" @click="submitBtnClick('InventoryForm',drawerAction)"
                       :loading="loading">{{ loading ? '提交中...' : '确 定' }}
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
// import localStore from "../utils/storage";

import {statusList} from '../common/baseData'; //仓库
const _statusList = statusList;
export default {
  name: "TbCncInfoPage",
  data() {
    const blurText = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('加工中心编码不能为空'))
      } else {
        if (this.rowInfo) {
          if (this.rowInfo.cncCode == value) {
            callback()
          }
        } else if (!/^[A-Za-z0-9]+$/.test(value)) {
          callback(new Error('加工中心编码格式错误'))
        }
        this.postFormRequest("/tb-cnc-info/exists", {cncCode: value}).then(data => {
          if (data.data) {
            callback(new Error('加工中心编码存在'))
          } else {
            callback()
          }
        });
      }
    };
      const blurText1 = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('加工中心名称不能为空'))
          } else {
              if (this.rowInfo) {
                  if (this.rowInfo.cncName == value) {
                      callback()
                  }
              }
              this.postFormRequest("/tb-cnc-info/existsName", {cncName: value}).then(data => {
                  if (data.data) {
                      callback(new Error('加工中心名称存在'))
                  } else {
                      callback()
                  }
              });
          }
      }
    return {
      // 列表
      total: 2,
      currPage: 1,
      pageSize: 10,
      parentList: [],
      selectForm: {name: ''},
      InventoryList: [],
      rowInfo: null,
      statusList: _statusList,
      // 修改
      showDrawer: false,
      InventoryForm: {
        id: '',
        cncCode: '',
        cncName: '',
        parentId: '',
        status: '',
        describe: '',
      },
      title: '',
      drawerAction: 'add',
      rules: {
        cncCode: [
          {
            required: true,
            message: '请输入加工中心编码',
            trigger: 'blur'
          },
          {validator: blurText, trigger: 'blur'}
        ],
        cncName: [
          {
            required: true,
            message: '请输入加工中心名称',
            trigger: 'blur'
          },
            {validator: blurText1, trigger: 'blur'}
        ],
        parentId: [
          {
            required: false,
            message: '请选择上级生产中心',
            trigger: 'change'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择加工中心状态',
            trigger: 'change'
          }
        ],
        describeCnc: [
          {
            required: false,
            message: '描述',
            trigger: 'blur'
          }
        ],
      }
    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);

  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },

    //查询所有品类
    selectCnC() {
      this.postFormRequest("/tb-cnc-info/selectCnC").then(res => {
        if (res.success) {
          this.parentList = res.data;
        }
      }).catch(() => {
      })
    },

    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-cnc-info/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.rowInfo = null;
      this.selectCnC();
      if (action == 'add') {
        this.title = '新增加工中心'
        this.drawerAction = 'add'
      } else {
        this.title = '修改加工中心'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.rowInfo = row;
        this.InventoryForm['id'] = row['id'];
        this.InventoryForm['cncCode'] = row['cncCode'];
        this.InventoryForm['cncName'] = row['cncName'];
        this.InventoryForm['parentId'] = row['parentId'];
        this.InventoryForm['status'] = row['status'];
        this.InventoryForm['describeCnc'] = row['describeCnc'];
      } else {
        this.InventoryForm = {
          id: '',
          cncCode: '',
          cncName: '',
          parentId: '',
          status: '',
          describeCnc: '',
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-cnc-info/save", this.InventoryForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-cnc-info/updateById", this.InventoryForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.InventoryForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-cnc-info/listByPage", requestForm).then(res => {
        if (res.success) {
          this.InventoryList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["InventoryForm"] != undefined) {
        this.$refs["InventoryForm"].resetFields();
      }
    }
  }
}
</script>

<style lang="scss">
</style>
