<template>
    <div>
        <ul class="icon-list clearfix">
            <li v-for="(item,index) in iconList" @click="clickIcon(item)" :key="index">
                <i :class="item"></i>
                <!--<span class="icon-name">{{item}}</span>-->
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "icon-list",
        data() {
            return {
                iconList: ["el-icon-menu", "el-icon-s-tools", "el-icon-setting", "el-icon-user-solid", "el-icon-user", "el-icon-phone", "el-icon-phone-outline", "el-icon-more", "el-icon-more-outline", "el-icon-star-on", "el-icon-star-off", "el-icon-s-goods", "el-icon-goods", "el-icon-warning", "el-icon-warning-outline", "el-icon-question", "el-icon-info", "el-icon-s-help", "el-icon-help", "el-icon-picture", "el-icon-picture-outline", "el-icon-picture-outline-round", "el-icon-camera-solid", "el-icon-camera", "el-icon-video-camera-solid", "el-icon-video-camera", "el-icon-message-solid", "el-icon-bell", "el-icon-s-cooperation", "el-icon-s-order", "el-icon-s-platform", "el-icon-s-fold", "el-icon-s-unfold", "el-icon-s-operation", "el-icon-s-promotion", "el-icon-s-home", "el-icon-s-release", "el-icon-s-ticket", "el-icon-s-management", "el-icon-s-open", "el-icon-s-shop", "el-icon-s-marketing", "el-icon-s-flag", "el-icon-s-comment", "el-icon-s-finance", "el-icon-s-claim", "el-icon-s-custom", "el-icon-s-opportunity", "el-icon-s-data", "el-icon-s-check", "el-icon-s-grid", "el-icon-share", "el-icon-d-caret"]
            }
        }, mounted() {
        },
        methods: {
            clickIcon(item) {
               this.$emit('clickIcon',item)
            }
        }
    }
</script>

<style scoped>
    .icon-list {
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-right: 0;
        margin-bottom: 10px;
    }

    .icon-list li {
        float: left;
        width: 16.66%;
        text-align: center;
        height: 50px;
        color: #666;
        font-size: 13px;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-right: -1px;
        margin-bottom: -1px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .icon-list li:after {
        content: "";
        height: 100%
    }

    .icon-list li span {
        line-height: normal;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
        color: #99a9bf;
        transition: color .15s linear
    }

    .icon-list li i {
        display: block;
        font-size: 32px;
        /*margin-bottom: 15px;*/
        color: #606266;
        transition: color .15s linear
    }

    .icon-list li .icon-name {
        display: inline-block;
        padding: 0 3px;
        height: 1em
    }

    .icon-list li:hover i, .icon-list li:hover span {
        color: #5cb6ff
    }
</style>
