<template>
    <div style="width: 100%">
        <div class="subMain">
            <el-form
                    :inline="true"
                    :model="selectForm"
                    class="el-form-inline"
                    size="mini"
            >
                <el-col span="8">
                    <el-form-item label="销售订单：" prop="saleOrderId">
                        <el-input
                                v-model="selectForm.saleOrderId"
                                placeholder="请填写销售订单："
                                clearable
                                type="text"
                                size="mini"
                                @change="clearChange"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="物料：" prop="materialCode">
                        <el-select
                                v-model="selectForm.materialCode"
                                placeholder="请选择物料"
                                size="mini"
                                @change="clearChange"
                                clearable
                        >
                            <el-option
                                    v-for="(item, index) in tbProductInfos"
                                    :key="index"
                                    :label="
                  item.cinvName + ' ' + item.cinvStd + ' ' + item.steelGradeName
                "
                                    :value="item.cinvCode"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="车牌号：" prop="carCode">
                        <el-input
                                v-model="selectForm.carCode"
                                placeholder="请填写车牌号："
                                clearable
                                type="text"
                                size="mini"
                                @change="clearChange"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="执行状态：" prop="invoiceStatus">
                        <el-select
                                v-model="selectForm.invoiceStatus"
                                placeholder="执行状态"
                                size="mini"
                                @change="clearChange"
                                clearable
                        >
                            <el-option
                                    v-for="(item, index) in invoiceStatusList"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col span="8">
                    <el-form-item label="订单生成时间：" prop="startTime">
                        <el-date-picker
                                type="datetime"
                                size="mini"
                                v-model="selectForm.startTime"
                                clearable
                                :editable="false"
                                placeholder="请选择生成开始时间"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="~" prop="endTime">
                        <el-date-picker
                                type="datetime"
                                size="mini"
                                v-model="selectForm.endTime"
                                clearable
                                :editable="false"
                                placeholder="请选择生成结束时间"
                        >
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button class="btn" type="primary" size="mini" @click="readData"
                        >查询
                        </el-button
                        >
                    </el-form-item>
                </el-col>
            </el-form>
        </div>
        <div class="main">
            <div style="text-align: right">
                <el-button
                        type="primary"
                        class="addButton"
                        @click="showDrawerFunc('add')"
                        size="mini"
                >新增
                </el-button
                >
                <p style="text-align: left; padding: 10px 0 0 10px; font-size: 16px">
                    订单发运总件数:{{ orderCountSum ? orderCountSum : 0 }} 订单发运总重:{{
                    parseFloat(orderWeightSum ? orderWeightSum : 0).toFixed(3)
                    }}(t) 实际发运总件数:{{
                    realCountSum ? realCountSum : 0
                    }}
                    实际发运总重:{{
                    parseFloat(realWeightSum ? realWeightSum : 0).toFixed(3)
                    }}(t) 实际发运车次:{{ sumCar ? sumCar : 0 }}
                    空车出厂车次：{{emptyCount ? emptyCount : 0}}
                </p>
            </div>
            <el-table
                    :data="tbDeliveryList"
                    stripe
                    border
                    :header-row-style="{
          background: '#DEEBF6',
          color: '#000000',
          height: '50px',
          fontWeight: 'Medium',
        }"
                    :header-cell-style="{
          background: '#DEEBF6',
          color: '#000000',
          height: '50px',
          fontWeight: 'Medium',
        }"
                    max-height="300px"
            >
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        :index="table_index"
                        width="80"
                        fixed="left"
                >
                </el-table-column>
                <!--  <el-table-column
                    prop="orderCode"
                    label="主单单号"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="orderId"
                    label="子单单号"
                    align="center"
                    width="100">
                </el-table-column>-->
                <el-table-column
                        prop="saleOrderId"
                        label="销售订单"
                        align="center"
                        width="160"
                >
                </el-table-column>
                <el-table-column prop="carCode" label="车号" align="center" width="110">
                </el-table-column>
                <el-table-column prop="cinvName" label="物料名称" align="center">
                </el-table-column>
                <el-table-column prop="specs" label="规格" align="center">
                </el-table-column>
                <el-table-column
                        prop="steelGrade"
                        label="钢种"
                        align="center"
                        width="100"
                >
                </el-table-column>
                <el-table-column prop="orderCount" label="发运数量" align="center">
                </el-table-column>
                <el-table-column
                        prop="orderWeight"
                        label="发运重量(t)"
                        align="center"
                        width="100"
                >
                    <template #default="scope">
                        {{
                        scope.row.orderWeight
                        ? parseFloat(
                        scope.row.orderWeight / 1000
                        ? scope.row.orderWeight / 1000
                        : "-"
                        ).toFixed(3)
                        : "-"
                        }}
                    </template>
                </el-table-column>

                <el-table-column prop="realCount" label="实际数量" align="center">
                </el-table-column>
                <el-table-column
                        prop="realWeight"
                        label="实际重量(t)"
                        align="center"
                        width="100"
                >
                    <template #default="scope">
                        {{
                        scope.row.realWeight
                        ? parseFloat(
                        scope.row.realWeight / 1000
                        ? scope.row.realWeight / 1000
                        : 0
                        ).toFixed(3)
                        : "-"
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="invoiceStatus" label="执行状态" align="center">
                    <template #default="scope">
                        <p v-if="scope.row.invoiceStatus == 1">待执行</p>
                        <p v-if="scope.row.invoiceStatus == 2">拣货中</p>
                        <p v-if="scope.row.invoiceStatus == 3">已完成</p>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="deliveryTime"
                        label="发运时间"
                        align="center"
                        width="170"
                >
                    <template #default="scope">
                        <p v-if="scope.row.invoiceStatus == 1">-</p>
                        <p v-if="scope.row.invoiceStatus == 3">
                            {{
                            scope.row.deliveryTime
                            ? dateYMDhmsFormat(scope.row.deliveryTime)
                            : "-"
                            }}
                        </p>
                        <p v-if="scope.row.invoiceStatus == 4">
                            {{
                            scope.row.deliveryTime
                            ? dateYMDhmsFormat(scope.row.deliveryTime)
                            : "-"
                            }}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="empName"
                        label="发运执行人"
                        align="center"
                        width="100"
                >
                    <template #default="scope">
                        {{ scope.row.empName ? scope.row.empName : "-" }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="订单生成时间"
                        align="center"
                        width="170"
                >
                    <template #default="scope">
                        {{
                        scope.row.createTime
                        ? dateYMDhmsFormat(scope.row.createTime)
                        : "-"
                        }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="auditor"
                        label="审核人"
                        align="center"
                        width="110">
                </el-table-column>
                <el-table-column
                        prop="aditTime"
                        label="审核时间"
                        align="center"
                        width="110">
                    <template #default="scope">
                        {{
                        scope.row.aditTime ? dateYMDhmsFormat(scope.row.aditTime) : "-"
                        }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="reviewReason"
                        label="审核原因"
                        align="center"
                        width="110">
                </el-table-column>
                <el-table-column
                        prop="auditStatus"
                        label="审核状态"
                        align="center"
                        width="110">
                    <template #default="scope">
                        <p v-if="scope.row.auditStatus==0">未审核</p>
                        <p v-else>已审核</p>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="200">
                    <template #default="scope">
                        <div v-if="scope.row.invoiceStatus == 1">
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="loadingClick('add', scope.row)"
                            >拣货
                            </el-button
                            >
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="loadingKong(scope.row)"
                            >空出
                            </el-button
                            >
                            <!--<el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑</el-button>-->
                            <!--<el-button type="text" size="small" @click="handleDelete(scope.row.id)">删除</el-button>-->
                        </div>
                        <div v-if="scope.row.invoiceStatus == 2">
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="loadingClick('edit', scope.row)"
                            >重新拣货
                            </el-button
                            >
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="deliverClick(scope.row)"
                            >拣货完成
                            </el-button
                            >
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="detailsClick(scope.row)"
                            >详情
                            </el-button
                            >
                        </div>
                        <div v-if="scope.row.invoiceStatus == 3">
                            <el-button type="text" size="small" @click="shipment(scope.row)"
                            >撤销
                            </el-button
                            >
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="detailsClick(scope.row)"
                            >详情
                            </el-button
                            >
                        </div>
                        <div v-if="scope.row.invoiceStatus == 4">
                            <el-button
                                    type="text"
                                    size="small"
                                    @click="detailsClick(scope.row)"
                            >详情
                            </el-button
                            >
                        </div>
                        <el-button v-if="scope.row.auditStatus==0"
                                   type="text"
                                   size="small"
                                   @click="ToExamineClick(scope.row)"
                        >审核
                        </el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="elp"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageSize"
                    :total="total"
                    layout="total, sizes, prev, pager, next, jumper"
            >
            </el-pagination>
            <!--新增修改-->
            <el-drawer
                    custom-class="drawerMain"
                    v-model="showDrawer"
                    v-if="showDrawer"
                    :title="title"
                    direction="rtl"
            >
                <div class="drawer_content">
                    <el-form
                            :model="InventoryForm"
                            :rules="rules"
                            label-position="top"
                            ref="InventoryForm"
                    >
                        <el-form-item label="发货通知单主单编号" prop="orderCode">
                            <el-input
                                    v-model="InventoryForm.orderCode"
                                    placeholder="请输入发货通知单主单编号"
                                    size="mini"
                                    clearable
                                    type="text"
                                    maxlength="20"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="发货通知单子单编号" prop="orderId">
                            <el-input
                                    v-model="InventoryForm.orderId"
                                    placeholder="请输入发货通知单子单编号"
                                    size="mini"
                                    clearable
                                    type="text"
                                    maxlength="20"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="物料" prop="materialCode">
                            <el-select
                                    v-model="InventoryForm.materialCode"
                                    placeholder="请选择物料"
                                    size="mini"
                                    clearable
                            >
                                <el-option
                                        v-for="(item, index) in tbProductInfos"
                                        :key="index"
                                        :label="item.cinvName + ' ' + item.cinvStd"
                                        :value="item.cinvCode"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="发运数量" prop="orderCount">
                            <el-input
                                    v-model="InventoryForm.orderCount"
                                    placeholder="请输入发运数量"
                                    size="mini"
                                    clearable
                                    type="text"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="发运重量" prop="orderWeight">
                            <el-input
                                    v-model="InventoryForm.orderWeight"
                                    placeholder="请输入发运重量"
                                    size="mini"
                                    clearable
                                    type="text"
                            ></el-input>
                        </el-form-item>

                        <el-form-item label="车牌号" prop="carCode">
                            <el-input
                                    v-model="InventoryForm.carCode"
                                    placeholder="请输入车牌号"
                                    size="mini"
                                    clearable
                                    type="text"
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="drawer-footer-height"></div>
                    <div class="dialog-footer">
                        <el-button size="mini" @click="cancelForm">取 消</el-button>
                        <el-button
                                type="primary"
                                size="mini"
                                @click="InventoryClick('InventoryForm', drawerAction)"
                        >确 定
                        </el-button>
                    </div>
                </div>
            </el-drawer>
        </div>

        <!--    发运通知单明细-->
        <div class="detailsMain">
            <span class="header">发运通知单明细</span><br/>
            <span style="font-size: 16px">发运通知单号：{{ saleOrderId }}</span>
            <el-table
                    :data="tableList"
                    stripe
                    border
                    :header-row-style="{
          background: '#DEEBF6',
          color: '#000000',
          height: '50px',
          fontWeight: 'Medium',
        }"
                    :header-cell-style="{
          background: '#DEEBF6',
          color: '#000000',
          height: '50px',
          fontWeight: 'Medium',
        }"
                    max-height="300px"
            >
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        :index="table_index"
                        width="80"
                >
                </el-table-column>
                <el-table-column prop="cInvName" label="物料名称" align="center">
                </el-table-column>
                <el-table-column prop="cInvCode" label="物料编码" align="center">
                </el-table-column>
                <el-table-column prop="cInvStd" label="规格型号" align="center">
                </el-table-column>
                <el-table-column prop="stoveCode" label="炉号" align="center">
                </el-table-column>
                <el-table-column prop="batchCode" label="批号" align="center">
                </el-table-column>
                <el-table-column prop="bundleCode" label="捆号" align="center">
                </el-table-column>
                <el-table-column prop="stockName" label="仓库名称" align="center">
                </el-table-column>
                <el-table-column prop="pileName" label="垛位名称" align="center">
                </el-table-column>
                <el-table-column prop="stockTime" label="入库时间" align="center">
                    <template #default="scope">
                        {{
                        scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : "-"
                        }}
                    </template>
                </el-table-column>
                <el-table-column prop="actWeight" label="入库实重(t)" align="center">
                    <template #default="scope">
                        {{
                        scope.row.actWeight
                        ? parseFloat(
                        scope.row.actWeight / 1000
                        ? scope.row.actWeight / 1000
                        : "-"
                        ).toFixed(3)
                        : "-"
                        }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--    装车-->
        <el-dialog
                custom-class="dialogContent"
                :title="title"
                :before-close="handleClose"
                v-model="dialogVisible"
                v-if="dialogVisible"
                width="60%"
        >
            <div class="header">
                <span class="rowtxt">发货通知单编码：{{ ruleForm.saleOrderId }}</span>
                <span class="rowtxt">通知单重量：{{ ruleForm.orderWeight }}</span>
                <span class="rowtxt">通知单捆数：{{ ruleForm.orderCount }}</span>
                <span class="rowtxt">当前锁定重量：{{ weight }}</span>
                <span>当前锁定捆数：{{ bundle }}</span>
                <div style="float: right">
                    <el-button
                            type="primary"
                            size="mini"
                            @click="submitBtnClick('ruleForm', drawerAction)"
                    >确定
                    </el-button>
                </div>
            </div>
            <div class="line"></div>
            <div class="classmain">
                <el-form
                        :inline="true"
                        :model="processForm"
                        class="el-form-inline"
                        size="mini"
                >
                    <el-form-item class="row" label="库存地址：">
                        <el-select
                                v-model="processForm.stockAddrId"
                                placeholder="请选择库存地址"
                                size="mini"
                                clearable
                        >
                            <el-option
                                    v-for="(item, index) in addressList"
                                    :key="index"
                                    :label="item.stockName"
                                    :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item class="row" label="垛位：">
                        <el-select
                                v-model="processForm.pileId"
                                placeholder="请选择垛位"
                                size="mini"
                                clearable
                        >
                            <el-option
                                    v-for="(item, index) in pileList"
                                    :key="index"
                                    :label="item.pileName"
                                    :value="item.id"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="捆号：" prop="bundleCode">
                        <el-input
                                v-model="processForm.bundleCode"
                                placeholder="请填写捆号"
                                clearable
                                type="text"
                                size="mini"
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button size="mini" @click="queryClick">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table
                        :data="dataList"
                        ref="multipleSelectionTable"
                        stripe
                        border
                        :row-key="(row) => row.id"
                        :header-row-style="{
            background: '#DEEBF6',
            color: '#000000',
            height: '50px',
            fontWeight: 'Medium',
          }"
                        :header-cell-style="{
            background: '#DEEBF6',
            color: '#000000',
            height: '50px',
            fontWeight: 'Medium',
          }"
                        @select-all="handleSelectionAll"
                        @select="handleSelectionChange"
                        @selection-change="handleSelectChange"
                        max-height="400px"
                >
                    <el-table-column
                            type="selection"
                            align="center"
                            fixed="left"
                    >
                    </el-table-column>
                    <el-table-column
                            type="index"
                            align="center"
                            label="序号"
                            :index="table_index2"
                            width="80"
                            fixed="left"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="bundleCode"
                            label="捆号"
                            align="center"
                            width="120"
                    >
                    </el-table-column>
                    <el-table-column prop="materialCode" label="物料编码" align="center">
                    </el-table-column>
                    <el-table-column prop="stockName" label="库存地址" align="center">
                    </el-table-column>
                    <el-table-column prop="pileName" label="垛位" align="center">
                    </el-table-column>
                    <el-table-column
                            prop="actWeight"
                            label="入库重量"
                            align="center"
                            width="130"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="cncName"
                            label="加工中心"
                            align="center"
                            width="120"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="stockTime"
                            label="入库时间"
                            align="center"
                            width="110"
                    >
                        <template #default="scope">
                            {{
                            scope.row.stockTime ? dateYMDFormat(scope.row.stockTime) : "-"
                            }}
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        class="elp"
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="currPage2"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="pageSize2"
                        :page-count="total2"
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </el-dialog>

        <!--审核-->
        <el-dialog
                custom-class="dialogMain"
                title="订单审核"
                v-model="dialogToExamine"
                v-if="dialogToExamine"
                width="25%">
            <el-form :inline="true" ref="ExamineForm" :model="ExamineForm" class="el-form-inline" size="mini"
                     label-width="120px"
                     label-position="left" :rules="rulesExamine">
                <el-form-item label="审核原因：" prop="reviewReason">
                    <el-input v-model="ExamineForm.reviewReason"
                              placeholder="请填写审核原因"
                              clearable
                              :rows="10"
                              type="textarea"
                              size="mini"></el-input>
                </el-form-item>
            </el-form>
            <div class="drawer-footer-height"></div>
            <div class="dialog-footer">
                <el-button size="mini" @click="dialogToExamine=false">否</el-button>
                <el-button type="primary" size="mini" @click="SubmitExamine('ExamineForm')">是
                </el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {invoiceStatusList} from "../common/baseData"; //执行状态
    const _invoiceStatusList = invoiceStatusList;
    export default {
        name: "DeliveryNoteToExamine",
        data() {
            const blurText = (rule, value, callback) => {
                if (value === "") {
                    callback(new Error("编码不能为空"));
                } else {
                    if (this.rowInfo) {
                        if (this.rowInfo.orderId == value) {
                            callback();
                        }
                    } else if (!/^[A-Za-z0-9]+$/.test(value)) {
                        callback(new Error("库存编码格式错误"));
                    }
                    this.postFormRequest("/tb-shipping-order-new/existsName", {
                        orderId: value,
                    }).then((data) => {
                        if (data.data) {
                            callback(new Error("编码存在"));
                        } else {
                            callback();
                        }
                    });
                }
            };
            return {
                pageSelection: [],//每一页是否全选
                isInit: false,
                rowInfo: null,
                total: 2,
                currPage: 1,
                pageSize: 10,
                // 修改
                showDrawer: false,
                selectForm: {
                    saleOrderId: "",
                    carCode: "",
                    startTime: "",
                    endTime: "",
                    materialCode: "",
                    invoiceStatus: "",
                },
                invoiceStatusList: _invoiceStatusList,
                saleOrderId: "",
                tbDeliveryList: [],
                tableList: [],
                dialogVisible: false,
                loading: false,
                materialCode: "",
                invoiceStatus: "",
                tbProductInfos: [],
                InventoryForm: {
                    id: "",
                    stockCode: "",
                    orderId: "",
                    materialCode: "",
                    orderCount: "",
                    orderWeight: "",
                    carCode: "",
                },
                orderCountSum: "",
                bundle: 0,
                orderWeightSum: "",
                realCountSum: "",
                realWeightSum: "",
                emptyCount: '',
                sumCar: "",
                id: "",
                ruleForm: {
                    id: "",
                    saleOrderId: "",
                    orderWeight: "",
                    orderCount: "",
                },
                processForm: {
                    stockAddrId: "",
                    pileId: "",
                    bundleCode: "",
                },
                title: "",
                drawerAction: "add",
                addressList: [],
                pileList: [],
                dataList: [],
                multipleSelection: [],
                rules: {
                    orderCode: [
                        /* {validator: blurText, trigger: 'blur', required: true}*/
                        {
                            required: true,
                            message: "请输入主单号",
                            trigger: "blur",
                        },
                    ],
                    orderId: [
                        /*{validator: blurText1, trigger: 'blur', required: true}*/
                        {validator: blurText, trigger: "blur", required: true},
                    ],
                    materialCode: [
                        {
                            required: true,
                            message: "请选择物料",
                            trigger: "blur",
                        },
                    ],
                    orderCount: [
                        {
                            required: true,
                            message: "请输入发运数量",
                            trigger: "blur",
                        },
                    ],
                    orderWeight: [
                        {
                            required: true,
                            message: "请输入发运重量",
                            trigger: "blur",
                        },
                    ],
                    carCode: [
                        {
                            required: true,
                            message: "请输入车牌号",
                            trigger: "blur",
                        },
                    ],
                },
                //装车
                total2: 2,
                currPage2: 1,
                pageSize2: 10,
                dialogToExamine: false,
                ExamineForm: {
                    id: '',
                    reviewReason: ''
                },
                rulesExamine: {
                    reviewReason: [
                        {
                            required: true,
                            message: "请输入审核原因",
                            trigger: "blur",
                        },
                    ],
                }
            };
        },

        mounted() {
            this.getNowTime();
            this.currentPageChanged(this.currPage, this.pageSize);
            this.materialCodee();
        },
        computed: {
            weight: function () {
                //解决重新拣货查询，数据不统一
                const s = new Set();
                let a = 0;
                if (this.multipleSelection.length > 0) {
                    let arr = [];
                    this.multipleSelection.forEach((info) => {
                        arr = arr.concat(info);
                    });

                    arr.forEach(info => {
                        if (!s.has(info.id)) {
                            s.add(info.id);
                            a += info.actWeight;
                        }
                    })
                    return a;
                }
                return 0;
            },

        },
        methods: {
            // 审核
            ToExamineClick(row) {
                this.dialogToExamine = true;
                if (row) {
                    this.ExamineForm['id'] = row['id'];
                }
            },
            // 提交审核
            SubmitExamine(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        console.info(111, this.ExamineForm)
                        this.postFormRequest("/tb-shipping-order-new/orderReview", this.ExamineForm).then(res => {
                            if (res.success) {
                                // 动画关闭需要一定的时间
                                setTimeout(() => {
                                    this.loading = false;
                                    this.dialogToExamine = false
                                    this.$message.success('审核成功')
                                    this.$refs.ExamineForm.resetFields();
                                }, 400);
                                this.currPage = 1;
                                this.currentPageChanged(this.currPage, this.pageSize);
                            } else {
                                this.loading = false;
                                this.$message.error(res.msg)
                                return false;
                            }
                        })
                    }
                })
            },
            //清空查询自动加载列表
            readData() {
                this.handleCurrentChange(1);
            }
            ,

            getNowTime() {
                let date = new Date();
                let dates = new Date(date.getTime() - 24 * 60 * 60 * 1000);
                let y = date.getFullYear();
                let m = date.getMonth() + 1;
                let r = date.getDate();
                let s = "07:20:00";

                let ys = dates.getFullYear();
                let ms = dates.getMonth() + 1;
                let rs = dates.getDate();

                let end = y + "-" + m + "-" + r + " " + s;
                let start = ys + "-" + ms + "-" + rs + " " + s;
                this.selectForm.startTime = start;
                this.selectForm.endTime = end;
            }
            ,
            //清空查询自动加载列表
            clearChange() {
                this.currentPageChanged(this.currPage, this.pageSize);
            }
            ,
            // 分页
            table_index(index) {
                return index + 1;
            }
            ,
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            }
            ,
            handleCurrentChange: function (currPage) {
                this.saleOrderId = null;
                this.tableList = null;
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);
            }
            ,
            currentPageChanged: function (currPage, pageSize) {
                this.selectForm["current"] = currPage;
                this.selectForm["size"] = pageSize;
                let requestForm = {};
                for (let key in this.selectForm) {
                    if (this.selectForm[key]) {
                        if (key == "startTime") {
                            requestForm[key] = this.selectForm[key]
                                ? this.dateYMDhmsFormat(this.selectForm[key])
                                : "";
                        } else if (key == "endTime") {
                            requestForm[key] = this.selectForm[key]
                                ? this.dateYMDhmsFormat(this.selectForm[key])
                                : "";
                        } else {
                            requestForm[key] = this.selectForm[key];
                        }
                    }
                }
                this.postFormRequest(
                    "/tb-shipping-order-new/listByPage",
                    requestForm
                ).then((res) => {
                    if (res.success) {
                        this.tbDeliveryList = res.data.tbShippingOrderNewPage.records;
                        this.total = res.data.tbShippingOrderNewPage.total;
                        if (res.data.listShingSum[0]) {
                            this.orderCountSum = res.data.listShingSum[0].orderCount;
                            this.orderWeightSum = res.data.listShingSum[0].orderWeight / 1000;
                            this.realCountSum = res.data.listShingSum[0].realCount;
                            this.realWeightSum = res.data.listShingSum[0].realWeight / 1000;
                            this.emptyCount = res.data.emptyCount;
                            this.sumCar = res.data.listShingSum[0].sumCar;
                        } else {
                            this.orderCountSum = 0;
                            this.orderWeightSum = 0;
                            this.realCountSum = 0;
                            this.realWeightSum = 0;
                            this.emptyCount = 0;
                        }
                    }
                });
            }
            ,
            //删除
            handleDelete(id) {
                if (id >= 0) {
                    this.$confirm("确认要删除吗?删除后信息将不可恢复", "删除提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    })
                        .then(() => {
                            this.getRequest(
                                "/tb-shipping-order-new/deleteLetterOfAdvice/" + id
                            ).then((res) => {
                                if (res.success) {
                                    this.$message.success("删除成功");
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                }
                            });
                        })
                        .catch(() => {
                        });
                }
            }
            ,
            // 分页 end
            //获取查询的仓库地址和垛位
            getAddress() {
                this.postFormRequest("/bus/tb-stock-in/selectStackTheStorage").then(
                    (res) => {
                        if (res.success) {
                            this.addressList = res.data.tbStockAddr;
                            this.pileList = res.data.tbPileInfos;
                        }
                    }
                );
            }
            ,
            //装车分页
            table_index2(index) {
                return (this.currPage2 - 1) * this.pageSize2 + index + 1;
            }
            ,
            handleSizeChange2: function (pageSize) {
                this.pageSize2 = pageSize?pageSize:1;
                this.handleCurrentChange2(this.currPage2);
            }
            ,
            handleCurrentChange2: function (currPage) {
                this.currPage2 = currPage?currPage:1;
                this.currentPageChanged2(this.currPage2, this.pageSize2);
            }
            ,
            currentPageChanged2: function (currPage, pageSize) {
                this.processForm["current"] = currPage;
                this.processForm["size"] = pageSize;
                this.processForm["materialCode"] = this.materialCode;
                this.processForm["id"] = this.id;
                let requestForm = {};
                for (let key in this.processForm) {
                    if (this.processForm[key]) {
                        requestForm[key] = this.processForm[key];
                    }
                }
                if (this.invoiceStatus == 1) {
                    this.postFormRequest(
                        "/tb-shipping-order-new/truckLoading",
                        requestForm
                    ).then((res) => {
                        if (res.success) {
                            this.dataList = res.data.records;
                            this.total2 = res.data.pages;
                        }
                    });
                }
                if (this.invoiceStatus == 2) {
                    this.postFormRequest(
                        "/tb-shipping-order-new/cancelTheLoading",
                        requestForm
                    ).then((res) => {
                        if (res.success) {
                            this.dataList = res.data.listReal.content;
                            this.total2 = res.data.listReal.totalPages;

                            if (this.isInit) {
                                this.bundle = res.data.listId ? res.data.listId.length : 0;
                                this.isInit = false;
                            }
                            this.pageSelection[this.currPage2 - 1] = {};
                            this.pageSelection[this.currPage2 - 1]['size'] = res.data.listReal.numberOfElements;
                            this.selectTableRow(this.dataList, res.data.listId);
                        }
                    });
                }
            }
            ,
            //装车查询
            queryClick() {
                //解决重新拣货查询，数据不统一
                this.multipleSelection = [];
                this.handleCurrentChange2(this.currPage2);
            }
            ,
            //查询编码名称和规格
            materialCodee() {
                this.postFormRequest("/bus/tb-stock-real/materialCode").then((res) => {
                    if (res.success) {
                        this.tbProductInfos = res.data;
                    }
                });
            }
            ,
            //获取发运通知单明细
            detailsClick(row) {
                this.getRequest("/pda_api/stockOut/orderDetail/" + row.id).then((res) => {
                    this.saleOrderId = row.saleOrderId;
                    if (res.success) {
                        this.tableList = res.data.lockedList;
                    } else {
                        this.tableList = null;
                    }
                });
            }
            ,
            //判断元素在数组中是否存在
            contains(id, arr) {
                for (var i = 0; i < arr.length; i++) {
                    if (id === arr[i]) {
                        return true;
                    }
                }
                return false;
            }
            ,
            //回显列表中选中的行
            selectTableRow(rows, checkedIds) {

                this.$refs.multipleSelectionTable.clearSelection();
                let checkedCount = 0;
                let selectedRow = this.multipleSelection[this.currPage2 - 1];
                let idArr = [];
                if (selectedRow) {
                    selectedRow.forEach(item => {
                        idArr.push(item.id);
                    })
                } else {
                    idArr = checkedIds;
                }
                let selectedIds = idArr;
                for (let i = 0; i < rows.length; i++) {
                    let id = rows[i].id;
                    if (checkedCount == selectedIds.length) {
                        break;
                    }

                    if (this.contains(id, selectedIds)) {
                        checkedCount++;
                        rows[i]['checkedCount'] = selectedIds.length;
                        this.$refs.multipleSelectionTable.toggleRowSelection(rows[i]);
                        //this.multipleSelection.push(rows[i]);
                    }
                }
            }
            ,

            handleSelectionAll(selection) {
                console.log("handleSelectionAll")
                let bundle = this.bundle;
                let i = this.currPage2 - 1;
                bundle += selection.length;
                if (selection.length > 0) {
                    if (this.multipleSelection[i]) {
                        bundle -= this.multipleSelection[i].length == selection.length ? 0 : this.multipleSelection[i].length;
                    }
                    this.multipleSelection[i] = JSON.parse(JSON.stringify(selection));
                } else {
                    bundle -= this.multipleSelection[i].length;
                    this.multipleSelection[i] = [];
                }
                this.bundle = bundle;
            }
            ,
            handleSelectChange(selection) {
                if (selection.length > 0) {
                    /*if (!row.pileName) {
                      this.$message.warning("不能选择没有垛位的捆号");
                      this.$refs.multipleSelectionTable.toggleRowSelection(row, false);
                      return;
                    }*/
                    let targetSelection = selection.filter(item => item['checkedCount']);
                    if (targetSelection.length > 0) {
                        this.multipleSelection[this.currPage2 - 1] = JSON.parse(JSON.stringify(selection));
                        selection.forEach(item => {
                            delete item['checkedCount'];
                        });
                    }
                    console.log("handleSelectChange2")

                }
            }
            ,
            //获取列表勾选框信息
            handleSelectionChange(selection, row) {
                if (selection.length > 0) {
                    /*if (!row.pileName) {
                      this.$message.warning("不能选择没有垛位的捆号");
                      this.$refs.multipleSelectionTable.toggleRowSelection(row, false);
                      return;
                    }*/

                    // console.log("handleSelectionChange")
                    // if(this.pageSelection[this.currPage2-1] && selection.length == this.pageSize2){
                    //   return;
                    // }
                    this.multipleSelection[this.currPage2 - 1] = JSON.parse(JSON.stringify(selection));
                    let arr = selection.filter(item => {
                        return item.id == row.id;
                    });
                    if (arr.length > 0) {
                        this.bundle += 1;
                    } else {
                        this.bundle -= 1;
                    }
                    console.info(this.multipleSelection);
                }

            }
            ,

            //装车列表
            loadingClick(action, row) {
                this.pageSelection = [];
                this.isInit = true;
                this.saleOrderId = null;
                this.tableList = null;
                this.multipleSelection = [];
                this.currPage2 = 1;
                if (action == "add") {
                    this.title = "装车";
                    this.drawerAction = "add";
                    this.materialCode = row.materialCode;
                    this.invoiceStatus = row.invoiceStatus;
                    this.handleCurrentChange2(this.currPage2);
                } else {
                    this.title = "重新拣货";
                    this.drawerAction = "edit";
                    this.materialCode = row.materialCode;
                    this.invoiceStatus = row.invoiceStatus;
                    this.id = row.id;
                    this.handleCurrentChange2(this.currPage2);
                }
                if (row) {
                    this.ruleForm["id"] = row["id"];
                    this.ruleForm["saleOrderId"] = row["saleOrderId"];
                    this.ruleForm["orderWeight"] = row["orderWeight"];
                    this.ruleForm["orderCount"] = row["orderCount"];
                }
                this.getAddress();
                this.dialogVisible = true;
            }
            ,
            //装车提交按钮
            submitBtnClick(formName, action) {
                this.loading = true;
                let ids = [];
                let weight = [];
                this.multipleSelection.forEach((ele) => {
                    ele.forEach(item => {
                        ids.push(item.id);
                        weight.push(item.actWeight);
                    })
                });
                if (action == "add") {
                    this.postRequest("/tb-shipping-order-new/undoCompletedConfirm", {
                        id: this.ruleForm.id,
                        list: ids,
                    }).then((res) => {
                        if (res.success) {
                            // 动画关闭需要一定的时间
                            setTimeout(() => {
                                this.loading = false;
                                this.dialogVisible = false;
                                this.$message.success("添加成功");
                                /* this.$refs.ruleForm.resetFields();*/
                            }, 400);
                            this.currPage = 1;
                            this.currentPageChanged(this.currPage, this.pageSize);
                        } else {
                            this.loading = false;
                            return false;
                        }
                    });
                } else {
                    this.postRequest("/tb-shipping-order-new/pickingAgain", {
                        id: this.ruleForm.id,
                        list: ids ? ids : "",
                        weight: weight ? weight : "",
                    }).then((res) => {
                        if (res.success) {
                            // 动画关闭需要一定的时间
                            setTimeout(() => {
                                this.loading = false;
                                this.dialogVisible = false;
                                /*this.$refs.ruleForm.resetFields();*/
                            }, 400);
                            this.currPage = 1;
                            this.currentPageChanged(this.currPage, this.pageSize);
                        } else {
                            this.loading = false;
                            return false;
                        }
                    });
                }
            }
            ,
            //
            //展示抽屉的方式
            showDrawerFunc(action, row) {
                this.rowInfo = null;
                if (action == "add") {
                    this.title = "新增";
                    this.drawerAction = "add";
                } else {
                    this.title = "编辑";
                    this.drawerAction = "edit";
                }
                this.showDrawer = true; //展开抽屉
                if (row) {
                    this.rowInfo = row;
                    this.InventoryForm["id"] = row["id"];
                    this.InventoryForm["orderCode"] = row["orderCode"];
                    this.InventoryForm["orderId"] = row["orderId"];
                    this.InventoryForm["materialCode"] = row["materialCode"];
                    this.InventoryForm["orderCount"] = row["orderCount"];
                    this.InventoryForm["orderWeight"] = row["orderWeight"];
                    this.InventoryForm["carCode"] = row["carCode"];
                } else {
                    this.InventoryForm = {
                        id: "",
                        stockCode: "",
                        orderId: "",
                        materialCode: "",
                        orderCount: "",
                        orderWeight: "",
                        carCode: "",
                    };
                }
            }
            ,
            //提交按钮被点击时
            InventoryClick(formName, action) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (action == "add") {
                            this.postFormRequest(
                                "/tb-shipping-order-new/addLetterOfAdvice",
                                this.InventoryForm
                            ).then((res) => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$message.success("添加成功");
                                        this.$refs.InventoryForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    this.$message.error(res.msg);
                                    return false;
                                }
                            });
                        } else {
                            this.postFormRequest(
                                "/tb-shipping-order-new/editLetterOfAdvice",
                                this.InventoryForm
                            ).then((res) => {
                                if (res.success) {
                                    this.$message.success("修改成功");
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$refs.InventoryForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    this.$message.error(res.msg);
                                    return false;
                                }
                            });
                        }
                    }
                });
            }
            ,
            //空出loadingKong
            loadingKong(row) {
                this.$confirm("是否确认空出", "确认空出提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then(() => {
                    this.saleOrderId = null;
                    this.tableList = null;
                    this.postFormRequest("/tb-shipping-order-new/voidance", {
                        id: row.id,
                        orderId: row.orderId,
                    }).then((res) => {
                        if (res.success) {
                            this.$message.warning("空出成功");
                        } else {
                            this.$message.warning("空出失败");
                        }
                        this.currentPageChanged(this.currPage, this.pageSize);
                    });
                });
            }
            ,
            //取消按钮
            cancelForm() {
                this.form = {};
                this.loading = false;
                this.showDrawer = false;
                this.$refs.InventoryForm.resetFields();
            }
            ,
            //确认发货
            deliverClick(row) {
                this.$confirm("是否确认发货", "确认发货提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.saleOrderId = null;
                        this.tableList = null;

                        this.postFormRequest("/tb-shipping-order-new/confirmShipmentButton", {
                            id: row.id,
                            orderCode: row.orderCode,
                            orderId: row.orderId,
                            realCount: row.realCount,
                            materialCode: row.materialCode,
                            wareCode: row.wareCode,
                            cInvName: row.cinvName,
                        }).then((res) => {
                            if (res.success) {
                                this.$message.success("确认发货");
                                this.currentPageChanged(this.currPage, this.pageSize);
                            } else {
                                this.$message.error(res.msg);
                                this.currentPageChanged(this.currPage, this.pageSize);
                            }
                        });
                    })
                    .catch(() => {
                    });
            }
            ,
            //取消出货
            shipment(row) {
                this.$confirm("是否取消出货", "取消装车提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.saleOrderId = null;
                        this.saleOrderId = null;
                        this.postFormRequest("/tb-shipping-order-new/undoCompleted", {
                            id: row.id,
                            orderId: row.orderId,
                            orderCode: row.orderCode,
                            cInvName: row.cinvName,
                        }).then((res) => {
                            if (res.success) {
                                this.$message.success("取消成功");
                                this.currentPageChanged(this.currPage, this.pageSize);
                            } else {
                                this.$message.error("取消失败");
                                this.currentPageChanged(this.currPage, this.pageSize);
                            }
                        });
                    })
                    .catch(() => {
                    });
            }
            ,
            //关闭弹窗
            handleClose() {
                this.dialogVisible = false;
            }
            ,
        },
        watch: {
            dialogVisible: function () {
                if (this.$refs["ruleForm"] != undefined) {
                    this.$refs["ruleForm"].resetFields();
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .elp {
        text-align: right;
    }

    .detailsMain {
        margin-top: 20px;
    }

    .detailsMain .header {
        border-left: 5px solid #4f8aff;
        padding-left: 5px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 25px;
    }

    /deep/ .dialogContent {
        height: 70%;
        overflow: hidden;
    }

    /deep/ .dialogMain {
        height: 30%;
        .el-dialog__body {
            height: 70%;
            display: flex;
        }
    }

    /deep/ .dialogContent .el-dialog__header {
        padding: 10px 20px 10px;
    }

    /deep/ .dialogContent .el-dialog__body {
        padding: 0 20px;
    }

    .header {
        line-height: 30px;
        height: 30px;
    }

    .rowtxt {
        margin-right: 20px;
    }

    .line {
        border-bottom: 1px solid black;
        padding-bottom: 10px;
    }

    .classmain {
        padding: 20px 0;
        box-sizing: border-box;
    }

    /deep/ .el-textarea__inner {
        width: 300px;
    }
</style>

