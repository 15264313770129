<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">

        <el-form-item class="row" label="物料编码:">
          <el-input v-model="selectForm.cInvCode" placeholder="请输入物料编码" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="物料名称:">
          <el-input v-model="selectForm.cInvName" placeholder="请输入物料名称" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="规格型号:">
          <el-input v-model="selectForm.cInvStd" placeholder="请输入规格型号" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-table
          :data="tbMaterialInfoList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            fixed="left"
            label="序号"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="cinvCode"
            label="物料编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="cinvName"
            label="物料名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="cinvStd"
            label="规格型号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="steelGradeName"
            label="钢种名称"
            fixed="right"
            align="center">
        </el-table-column>
        <el-table-column
            prop="branchCount"
            label="支数"
            align="center">
        </el-table-column>
        <el-table-column
            prop="theoWeight"
            label="理重"
            align="center">
        </el-table-column>
        <el-table-column
            align="center"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-dialog custom-class="drawerMain"
               v-model="showDialog"
               v-if="showDialog"
               title="修改信息"
               width="40%"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbMaterialInfoForm" :rules="rules" label-position="top" ref="tbMaterialInfoForm">
          <el-form-item label="支数" prop="branchCount">
            <el-input v-model="tbMaterialInfoForm.branchCount" placeholder="请输入支数" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="理重" prop="theoWeight">
            <el-input v-model="tbMaterialInfoForm.theoWeight" placeholder="请输入理重" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbMaterialInfoForm')"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TbMaterialInfoPage",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      tbMaterialInfoList: [],
      showDialog: false,
      rowInfo: null,
      tbMaterialInfoForm: {
        id: '',
        branchCount: '',
        theoWeight: '',
      },
      typeList: [],
      selectForm: {
        materialCode: null,
        materialName: null,
        cInvCode: '',
        cInvName: '',
        cInvStd: '',
      },
      loading: false,
      rules: {
        theoWeight:[{ required: true, message: '请输入理重', trigger: 'blur'}]
      }

    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
  },
  methods: {
    //查询
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.showDialog = true;//展开抽屉
      if (row) {
        this.rowInfo = row;
        this.tbMaterialInfoForm['id'] = row['id'];
        this.tbMaterialInfoForm['branchCount'] = row['branchCount'];
        this.tbMaterialInfoForm['theoWeight'] = row['theoWeight'];
      } else {
        this.tbMaterialInfoForm = {
          id: '',
          branchCount: '',
          theoWeight: '',
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
            this.postFormRequest("/tb-product-info/updateById", this.tbMaterialInfoForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDialog = false;
                  this.$refs.tbMaterialInfoForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.$message.error('修改失败')
                this.loading = false;
                return false;
              }
            })
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDialog = false;
      this.$refs.tbMaterialInfoForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/sys/tb-material-info/finishedProduct", requestForm).then(res => {
        if (res.success) {
          this.tbMaterialInfoList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDialog: function () {
      if (this.$refs["tbMaterialInfoForm"] != undefined) {
        this.$refs["tbMaterialInfoForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

