<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="菜单名称:">
          <el-input v-model="selectForm.menuName" placeholder="请输入菜单名称" size="mini"
                    clearable type="text"></el-input>
        </el-form-item>
        <el-form-item class="row" label="菜单URL:">
          <el-input v-model="selectForm.menuURL" placeholder="请输入菜单URL" size="mini"
                    clearable type="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button class="addButton" type="primary" @click="showDrawerFunc('add')" size="mini">新增信息</el-button>
      </div>
      <el-table
          :data="tbMenuList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="menuName"
            label="菜单名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="menuIcon"
            label="菜单图标"
            align="center">
          <template #default="scope">
            <i :class="scope.row.menuIcon ? scope.row.menuIcon : '-'"></i>
          </template>
        </el-table-column>
        <el-table-column
            prop="menuURL"
            label="菜单URL"
            align="center">
          <template #default="scope">
            {{ scope.row.menuURL ? scope.row.menuURL : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="parentId"
            label="上级菜单"
            align="center">
          <template #default="scope">
            {{ scope.row.parentId ? scope.row.parentId : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="ordered"
            label="排序"
            align="center">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="120"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               :title="title"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbMenuForm" :rules="rules" label-position="top" ref="tbMenuForm">
          <el-form-item label="菜单名称" prop="menuName">
            <el-input v-model="tbMenuForm.menuName" placeholder="请填写菜单名称" clearable type="text" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="菜单图标" prop="menuIcon">
            <el-input v-model="tbMenuForm.menuIcon" placeholder="请填写菜单图标"
                      @click="setIcon" type="text" size="mini"></el-input>
            <el-dialog
                width="60%"
                title="请选择菜单图标"
                v-model="innerVisible"
                append-to-body>
              <icon-list @clickIcon="clickIcon" style="height: 400px;overflow-y: scroll;"></icon-list>
            </el-dialog>
          </el-form-item>
          <el-form-item label="菜单URL" prop="menuURL">
            <el-input v-model="tbMenuForm.menuURL" placeholder="请填写菜单URL" clearable type="text" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="上级菜单" prop="parentId">
            <el-select v-model="tbMenuForm.parentId" placeholder="请填写上级菜单" size="mini" clearable>
              <el-option v-for="(item,index) in tbMenuList" :key="index" :label="item.menuName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="ordered">
            <el-input v-model="tbMenuForm.ordered" placeholder="请填写排序" clearable type="text" size="mini"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbMenuForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
import IconList from "../components/icon-list/icon-list";

export default {
  name: "TbMenuPage",
  components: {IconList},
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      tbMenuList: [],
      showDrawer: false,
      title: '',
      tbMenuForm: {
        menuName: '',
        menuIcon: '',
        menuURL: '',
        parentId: '',
        ordered: ''
      },
      selectForm: {
        menuName: null,
        menuURL: null,
      },
      innerVisible: false,//选择图标弹出框
      loading: false,
      drawerAction: 'add',
      rules: {
        menuName: [
          {
            required: true,
            message: '请输入菜单名称',
            trigger: 'blur'
          }
        ],
        menuIcon: [
          {
            required: true,
            message: '请输入菜单图标',
            trigger: 'blur'
          }
        ],
        menuURL: [
          {
            required: true,
            message: '请输入菜单URL',
            trigger: 'blur'
          }
        ],
        // parentId: [
        //   {
        //     required: true,
        //     message: '请输入上级菜单',
        //     trigger: 'blur'
        //   }
        // ],
        ordered: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          }
        ],
      }
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    // 查询所有菜单
    selectMenu() {
      this.postFormRequest("/tb-menu/selectMenu").then(res => {
        if (res.success) {
          this.tbMenuList = res.data;
        }
      })
    },

    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-menu/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //菜单图标弹出框方法
    setIcon() {
      this.innerVisible = true;
    },
    //点击设置菜单图标
    clickIcon(item) {
      this.innerVisible = false;
      if (item) {
        this.tbMenuForm.menuIcon = item
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      if (action == 'add') {
        this.title = '新增信息'
        this.drawerAction = 'add'
      } else {
        this.title = '修改信息'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.tbMenuForm['id'] = row['id'];
        this.tbMenuForm['menuName'] = row['menuName'];
        this.tbMenuForm['menuIcon'] = row['menuIcon'];
        this.tbMenuForm['menuURL'] = row['menuURL'];
        this.tbMenuForm['parentId'] = row['parentId'];
        this.tbMenuForm['ordered'] = row['ordered'];
      } else {
        this.tbMenuForm = {
          id: '',
          menuName: '',
          menuIcon: '',
          menuURL: '',
          parentId: '',
          ordered: ''
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-menu/save", this.tbMenuForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.tbMenuForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-menu/updateById", this.tbMenuForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.tbMenuForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.tbMenuForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-menu/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbMenuList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["tbMenuForm"] != undefined) {
        this.$refs["tbMenuForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

