<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="库存地址：">
          <el-select v-model="defaultStockIndex" placeholder="请选择库存地址" size="mini" clearable @change="stockChange">
            <el-option v-for="(item,index) in addressList" :key="index" :label="item.stockName"
                       :value="index"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div class="items" v-for="(item, index) in pileList" :key="index">
        <!--        饼状图-->
        <PieChart :pie-chart-data="[{value: item.countNumber, name: '已用库存'}, {value: item.upperLimit-item.countNumber,name: '库存余量'}]"
                  :pile-id="item.pileId" :stock-addr-id="addressList[defaultStockIndex].stockId"></PieChart>
        <div style="float: left;line-height: 50px">
          <span>{{ item.pileName }}</span><br>
          <span>库存上限：{{ item.upperLimit }}捆</span><br>
          <span>已用库存：{{ item.countNumber }}捆</span><br>
          <span>库存余量：{{ item.upperLimit-item.countNumber }}捆</span></div>
      </div>
    </div>

  </div>
</template>

<script>
import PieChart from "@/components/pieChart/pieChart";

export default {
  name: "InventoryMonitoring",
  components: {PieChart},
  data() {
    return {
      defaultStockIndex: 0,
      selectForm: {
        stockAddrId: '',
      },
      addressList: [],
      pileList: [],
    }
  },
  mounted() {
    this.getAddress();
  },
  methods: {
    stockChange(index){
      this.pileList = this.addressList[index].children;
    },
    //查询库存地
    getAddress() {
      this.postFormRequest("/bus/tb-stock-carry-forward/realTimeInventoryMonitoring").then(res => {
        if (res.success) {
          this.addressList = res.data;
          this.pileList = res.data[this.defaultStockIndex].children;
        }
      })
    },
  }
}
</script>

<style scoped>
.items {
  position: relative;
  width: 33%;
  height: 50%;
  float: left;
  border: 1px solid #eee;
  margin: 5px 0 0 4px;
  border-radius: 5px;
}
</style>
