<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="时间：">
          <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.date"
                          clearable :editable="false" placeholder="请选择时间" :disabledDate="disabledDate">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="getQuery">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="downloadExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main" style="overflow-x: scroll;">
      <table id="mainTable">
      </table>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';

export default {
  name: "ReportManagement",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        date: new Date()
      },
      tableList: [],
      dataList: [],
      //禁用当前时间往后的日期
      disabledDate(time) {
        return time.getTime() > Date.now()
      },
    }
  },
  mounted() {
    this.getTableList(() => {
      this.renderTable()
    })
  },
  methods: {
    renderTable() {
      let mainTable = $("#mainTable");
      let tableHtml = this.renderHeader() + this.renderBody();
      mainTable.html(tableHtml);
      // 入库
      document.getElementById('ruStockKun').innerText = this.dataList.ruStockKun
      document.getElementById('ruStock').innerText = this.dataList.ruStock/1000
      document.getElementById('ruLeiStockKun').innerText = this.dataList.ruLeiStockKun
      document.getElementById('ruLeiStock').innerText = this.dataList.ruLeiStock/1000
      document.getElementById('randomLengthBundleHe').innerText = this.dataList.randomLengthBundleHe
      document.getElementById('randomLengthWeight').innerText = this.dataList.randomLengthWeight/1000
      document.getElementById('randomLengthLeiBundleHe').innerText = this.dataList.randomLengthLeiBundleHe
      document.getElementById('randomLengthLeiWeight').innerText = this.dataList.randomLengthLeiWeight/1000
      // 出库
      document.getElementById('chuStockKun').innerText = this.dataList.chuStockKun
      document.getElementById('chuStock').innerText = this.dataList.chuStock/1000
      document.getElementById('chuLeiStockKun').innerText = this.dataList.chuLeiStockKun
      document.getElementById('chuLeiStock').innerText = this.dataList.chuLeiStock/1000
      document.getElementById('chuRandomLengthBundleHe').innerText = this.dataList.chuRandomLengthBundleHe
      document.getElementById('chuRandomLengthWeight').innerText = this.dataList.chuRandomLengthWeight/1000
      document.getElementById('chuRandomLengthLeiBundleHe').innerText = this.dataList.chuRandomLengthLeiBundleHe
      document.getElementById('chuRandomLengthLeiWeight').innerText = this.dataList.chuRandomLengthLeiWeight/1000
      //不合格
      document.getElementById('belowTheMarkCount').innerText = this.dataList.belowTheMarkCount
      document.getElementById('belowTheMarkWeight').innerText = this.dataList.belowTheMarkWeight/1000
      document.getElementById('belowTheMarkCountYue').innerText = this.dataList.belowTheMarkCountYue
      document.getElementById('belowTheMarkWeightYue').innerText = this.dataList.belowTheMarkWeightYue/1000
      // 非定尺
      document.getElementById('stoSumRandomLengthBundle').innerText = this.dataList.stoSumRandomLengthBundle
      document.getElementById('stoSmRandomLengthWeight').innerText = this.dataList.stoSmRandomLengthWeight/1000
      //库存
      document.getElementById('sumNumber').innerText = this.dataList.sumNumber
      document.getElementById('sumStock').innerText = this.dataList.sumStock/1000

    },
    renderHeader() {
      let header = "<thead>";
      header +=
        "<tr><td colspan='4' class='firstheadlockspan'>名称型号</td><td colspan='8'>入库</td><td colspan='8'>出库</td><td colspan='4'>不合格</td><td colspan='2'>非定尺</td><td rowspan='2'>库存件数</td><td rowspan='2' class='headlock'>库存重量(t)</td></tr>";
      header +=
        "<tr><td class='firstheadlock'>物料名称</td><td class='firstheadlock2'>物料编码</td><td class='firstheadlock3'>规格</td><td class='firstheadlock4'>长度</td>" +
        "<td>本日入库件数</td><td>本日入库重量(t)</td><td>入库累计件数</td><td>入库累计重量(t)</td>" +
        "<td>本日非定尺入库件数</td><td>本日非定尺入库重量(t)</td><td>非定尺入库累计件数</td><td>非定尺入库累计重量(t)</td>" +
        "<td>本日出库件数</td><td>本日出库总重(t)</td><td>出库累计件数</td><td>出库累计重量(t)</td>" +
        "<td>本日非定尺出库件数</td><td>本日非定尺出库重量(t)</td><td>非定尺出库累计件数</td><td>非定尺出库累计重量(t)</td>" +
        "<td>本日不合格件数</td><td>本日不合格重量(t)</td><td>不合格累计件数</td><td>不合格累计重量(t)</td>" +
        "<td>非定尺库存件数</td><td>非定尺库存重量(t)</td></tr>";
      header +=
        "<tr><td colspan='4' class='firstheadlockspan'>总合计</td><td id='ruStockKun'></td><td id='ruStock'></td><td id='ruLeiStockKun'></td><td id='ruLeiStock'></td>" +
        "<td id='randomLengthBundleHe'></td><td id='randomLengthWeight'></td><td id='randomLengthLeiBundleHe'></td><td id='randomLengthLeiWeight'></td>" +
        "<td id='chuStockKun'></td><td id='chuStock'></td><td id='chuLeiStockKun'></td><td id='chuLeiStock'></td>" +
        "<td id='chuRandomLengthBundleHe'></td><td id='chuRandomLengthWeight'></td><td id='chuRandomLengthLeiBundleHe'></td><td id='chuRandomLengthLeiWeight'></td>" +
        "<td id='belowTheMarkCount'></td><td id='belowTheMarkWeight'></td><td id='belowTheMarkCountYue'></td><td id='belowTheMarkWeightYue'></td>" +
        "<td id='stoSumRandomLengthBundle'></td><td id='stoSmRandomLengthWeight'></td><td id='sumNumber'></td><td id='sumStock' class='headlock'></td></tr>";
      header += "</thead>";
      return header;
    },
    renderBody() {
      let body = "<tbody>";
      body += this.renderTr(this.tableList);
      body += "</tbody>";
      return body;
    },
    renderTr(list) {
      let body = "";
      list.forEach((item) => {
        if (item.children) {
          body +=
            "<tr><td colspan='4' class='firstbodylock'>" +
            item.stockName +
            "</td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td>" +
            "<td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td></td><td>" +
            +item.openingInventoryBundleSum +
            "</td><td class='bodylock'>" +
            item.actWeightSum / 1000 +
            "</td></tr>";
          let tr = this.renderTr(item.children);
          body += tr;
        } else {
          body +=
            "<tr><td class='firstbodylock'>" +
            item.productName +
            "</td><td class='firstbodylock2'>" +
            item.steelGradeCode +
            "</td><td class='firstbodylock3'>" +
            item.specValue +
            "</td><td class='firstbodylock4'>" +
            item.length +
            "</td>" +
            "<td>" +
            item.stockInBundle +
            "</td><td>" +
            item.sameDayStockInWeight / 1000 +
            "</td><td>" +
            item.leiOpeningInventoryBundle +
            "</td><td>" +
            item.leiSameDayStockInWeight / 1000 +
            "</td>" +
            "<td>" +
            item.randomLengthBundle +
            "</td>" +
            "<td>" +
            item.randomLengthWeight / 1000 +
            "</td><td>" +
            item.randomLengthLeiBundle +
            "</td><td>" +
            item.randomLengthLeiWeight / 1000 +
            "</td>" +
            "<td>" +
            item.sameDayStockOutBundle +
            "</td>" +
            "<td>" +
            item.sameDayStockOutWeight / 1000 +
            "</td><td>" +
            item.leiSameDayStockOutBundle +
            "</td><td>" +
            item.leiSameDayStockOutWeight / 1000 +
            "</td>" +
            "<td>" +
            item.chuRandomLengthBundle +
            "</td><td>" +
            item.chuRandomLengthWeight / 1000 +
            "</td>" +
            "<td>" +
            item.chuRandomLengthLeiBundle +
            "</td><td>" +
            item.chuRandomLengthLeiWeight / 1000 +
            "</td>" +
            "<td>" +
            item.belowTheMarkCount +
            "</td><td>" +
            item.belowTheMarkWeight / 1000 +
            "</td>" +
            "<td>" +
            item.belowTheMarkCountLei +
            "</td><td>" +
            item.belowTheMarkWeightLei / 1000 +
            "</td>" +
            "<td>" +
            item.sumRandomLengthBundle +
            "</td><td>" +
            item.sumRandomLengthWeight / 1000 +
            "</td><td>" +
            item.openingInventoryBundle +
            "</td>" +
            "<td class='bodylock'>" +
            item.actWeight / 1000 +
            "</td></tr>";
        }
      });
      return body;
    },
//获取列表
    getTableList(callback) {
      let newDate = moment(new Date()).format("yyyy-MM-DD");
      let date = this.selectForm.date;
      this.postFormRequest("/tb-stock-addr/statementInquire", {
        date: date == null ? newDate : moment(date).format("yyyy-MM-DD"),
      }).then(res => {
        if (res.success) {
          this.tableList = res.data.product
          this.dataList = res.data.stock
          if (typeof callback == "function") {
            callback();
          }
          this.$message.success('查询成功');
        }
      })
    },
    //查询
    getQuery() {
      this.getTableList(() => {
        this.renderTable()
      })
    },
    //导出
    downloadExcel() {
      let newDate = moment(new Date()).format("yyyy-MM-DD");
      let date = this.selectForm.date
      this.postDownload("/tb-stock-addr/theReportIsDerived", {
        date: date == null ? newDate : moment(date).format("yyyy-MM-DD"),
      }).then(res => {
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', '日报表')
        a.click()
      })
    },
  }
}
</script>

<style scoped>
.main {
  width: 98%;
  overflow:scroll;
  height: 350px;
}
.main table {
  border-collapse: collapse;
  width: auto;
  text-align: center;
  line-height: 50px;
  height: auto;
  overflow-x: hidden;
  table-layout: fixed;
}

/deep/ .main table thead {
  position: sticky;
  top: 0px;
  z-index: 999;
}
/deep/ .main table tbody tr .headlock{
  position: sticky;
  right: 0; 
  z-index: 1;
  background: rgb(222, 235, 246);
}

/deep/ .main table thead tr .headlock{
  position: sticky;
  right: 0; 
  z-index: 999;
  background: rgb(222, 235, 246);
}

/deep/ .main table tbody tr .bodylock{
  position: sticky;
  right: 0; 
  z-index: 1;
  background:white
}

/deep/ .main table thead tr {
  z-index: 999;
}


/*首列合并*/
/deep/ .main table thead tr .firstheadlockspan,
/deep/ .main table thead tr .firstheadlock{
  position: sticky;
  left: 0; 
  z-index: 1000;
  background: rgb(222, 235, 246);
}
/deep/ .main table tbody tr .firstbodylock{
  position: sticky;
  left: 0; 
  z-index: 1;
  background:white;
}
/deep/ .main table thead tr .firstheadlock,
/deep/ .main table thead tr .firstheadlock2,
/deep/ .main table thead tr .firstheadlock3,
/deep/ .main table thead tr .firstheadlock4
{
  position: sticky;
  width: 70px;
  z-index: 1000;
  background: rgb(222, 235, 246);
  
}
/deep/ .main table tbody tr .firstbodylock,
/deep/ .main table tbody tr .firstbodylock2,
/deep/ .main table tbody tr .firstbodylock3,
/deep/ .main table tbody tr .firstbodylock4
{
  position: sticky;
  width: 80px;
  background:white;
  border: 1px solid #ccc;
}

/deep/ .main table thead tr .firstheadlock2,
/deep/ .main table tbody tr .firstbodylock2
{
  position: sticky;
  left: 80px;
}

/deep/ .main table thead tr .firstheadlock3,
/deep/ .main table tbody tr .firstbodylock3
{
  position: sticky;
  left: 150px;
}

/deep/ .main table thead tr .firstheadlock4,
/deep/ .main table tbody tr .firstbodylock4
{
  position: sticky;
  left: 240px;
}


/deep/ td {
  border: 1px solid #ccc;
  white-space: nowrap; /*文本不会换行，文本会在在同一行上继续，直到遇到 <br> 标签为止。*/
  padding: 0 5px;
}

/deep/ thead {
  background: rgb(222, 235, 246);
}
</style>
