<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">安山仓库管理系统</h3>
      <el-form-item prop="username">
        <el-input
            ref="username"
            v-model="loginForm.username"
            placeholder="Username"
            name="username"
            type="text"
            tabindex="1"
            autocomplete="on"
            prefix-icon="el-icon-user"
        />
      </el-form-item>
      <el-form-item prop="password">
        <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="Password"
            name="password"
            tabindex="2"
            autocomplete="on"
            prefix-icon="el-icon-lock"
            v-on:keyup.enter="checkCapslock"
            @blur="capsTooltip = false"
        />
      </el-form-item>
      <el-form-item style="width:100%;">
        <el-button
            :loading="loading"
            size="medium"
            type="primary"
            style="width:100%;"
            @click="submitForm('loginForm')"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import localStore from '../utils/storage.js'
import { Base64 } from 'js-base64';

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
      },
      passwordType: 'password',
      loginRules: {
        username: [
          {required: true, trigger: "blur", message: "用户名不能为空"}
        ],
        password: [
          {required: true, trigger: "blur", message: "密码不能为空"}
        ],
      },
      loading: false,
      redirect: undefined
    };
  },
  methods: {
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    // 登录验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let that = this;
          //密码中必须包含字母、数字，至少8个字符，最多30个字符。
          var regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]).{8,30}');
          if (!regex.test(that.loginForm.password)) {
                    this.$message.error("您的密码复杂度太低（密码中必须包含字母、数字、至少8个字符，最多30个字符），请及时改密码！");
                    this.loading = false;
                    return false;
          }else{
          this.postRequest("/sys/tb-employee/login", {
            username: that.loginForm.username,
            password: Base64.encode(that.loginForm.password),
            // password: that.loginForm.password,
          }).then(res => {
            if (res.success) {
              this.loading = false;
              localStore.set('warehouse_username', that.loginForm.username)
                localStore.set('warehouse_userInfo', res.data)
                localStore.set('warehouse_token', res.data.token)
                // this.$router.push('/FinishedProductWeighing');
                // window.top.location.replace("main.js");
              this.$router.push('/');

            } else {
              this.loading = false;
              this.$message.error(res.msg);
            }
          }).catch((ex) => {
            this.loading = false;
            console.info(ex)
          });
        }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url("../assets/image/login.jpg");
  background-size: cover;
}

.title {
  margin: 0 auto 30px auto;
  text-align: center;
  color: #707070;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }

  .el-input--mini .el-input__icon {
    line-height: 38px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}

</style>
