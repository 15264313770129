<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form
        :inline="true"
        :model="selectForm"
        class="el-form-inline"
        size="mini"
      >
        <el-form-item class="row" label="批号：">
          <el-input
            v-model="selectForm.batchCode"
            placeholder="请输入样品批号"
            size="mini"
            clearable
            type="text"
            @change="clearChange"
          ></el-input>
        </el-form-item>
        <el-form-item class="row" label="产生报告时间：">
          <el-date-picker
            type="date"
            format="YYYY-MM-DD"
            size="mini"
            v-model="selectForm.createTime"
            placeholder="请选择产生报告时间"
            @change="clearChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="dataList"
      class="main-table"
      stripe
      row-key="batchCode"
      :header-row-style="{
        background: '#DEEBF6',
        color: '#000000',
        height: '50px',
        fontWeight: 'Medium',
      }"
      :header-cell-style="{
        background: '#DEEBF6',
        color: '#000000',
        height: '50px',
        fontWeight: 'Medium',
      }"
    >
      <el-table-column type="expand">
        <template #default="props">
          <div v-if="props.row.cfemisal && props.row.cfemisal.length > 0">
            <el-table :data="props.row.cfemisal" class="inner-table" border>
              <el-table-column
                type="index"
                width="80px"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column width="150px" label="检验类型" align="center"
                >化学成分</el-table-column
              >
              <el-table-column
                prop="projectCategory"
                label="检测项目"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="检测项目名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemVal"
                label="检测值"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="inspector"
                label="质检员"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div v-if="props.row.mechancal && props.row.mechancal.length > 0">
            <el-table :data="props.row.mechancal" class="inner-table" border>
              <el-table-column
                type="index"
                width="80px"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column width="150px" label="检验类型" align="center"
                >物理性能</el-table-column
              >
              <el-table-column
                prop="projectCategory"
                label="检测项目"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="检测项目名称"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemVal"
                label="检测值"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemVal2"
                label="二次检测值"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="itemVal3"
                label="三次检测值"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="inspector"
                label="质检员"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div
            v-if="props.row.technological && props.row.technological.length > 0"
          >
            <el-table
              :data="props.row.technological"
              class="inner-table"
              border
            >
              <el-table-column
                type="index"
                width="80px"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column width="150px" label="检验类型" align="center"
                >工艺性能</el-table-column
              >
              <el-table-column
                prop="projectCategory"
                label="检测项目"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="检测项目名称"
                align="center"
              ></el-table-column>
              <el-table-column prop="itemVal" label="检测值" align="center">
                <template #default="scope">
                  {{ scope.row.itemVal ? "完好" : "/" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="inspector"
                label="质检员"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
          <div v-if="props.row.others && props.row.others.length > 0">
            <el-table :data="props.row.others" class="inner-table" border>
              <el-table-column
                type="index"
                width="80px"
                label="序号"
                align="center"
              ></el-table-column>
              <el-table-column width="150px" label="检验类型" align="center"
                >其他</el-table-column
              >
              <el-table-column
                prop="projectCategory"
                label="检测项目"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="检测项目名称"
                align="center"
              ></el-table-column>
              <el-table-column prop="itemVal" label="检测值" align="center"
                >合格</el-table-column
              >
              <el-table-column prop="itemVal2" label="二次检测值" align="center"
                >合格</el-table-column
              >
              <el-table-column prop="itemVal3" label="三次检测值" align="center"
                >合格</el-table-column
              >
              <el-table-column
                prop="inspector"
                label="质检员"
                align="center"
              ></el-table-column>
            </el-table>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        align="center"
      ></el-table-column>
      <el-table-column
        label="批号"
        prop="batchCode"
        align="center"
      ></el-table-column>
      <el-table-column
        label="物料"
        prop="cInvName"
        align="center"
      ></el-table-column>
      <el-table-column
        label="规格"
        prop="cInvStd"
        align="center"
      ></el-table-column>
      <el-table-column
        label="钢种"
        prop="steelGradeName"
        align="center"
      ></el-table-column>
      <el-table-column label="产生报告时间" prop="createTime" align="center">
        <template #default="scope">
          {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : "" }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="elp"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currPage"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      :total="total"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "QueryReport",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        batchCode: "",
        createTime: "",
      },
      dataList: [],
      // 调拨列表展开数据列
    };
  },
  mounted() {
    this.getNowTime();
    this.currentPageChanged(this.currPage, this.pageSize);
  },
  methods: {
    getNowTime() {
      let date = new Date();
      this.selectForm.createTime = date;
    },
    //查询
    readData() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //物理性能
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1;
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm["pageNum"] = currPage;
      this.selectForm["pageSize"] = pageSize;
      let requestForm = {};
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          if (key == "createTime") {
            requestForm[key] = this.selectForm[key]
              ? this.dateYMDhmsFormat(this.selectForm[key])
              : "";
          } else {
            requestForm[key] = this.selectForm[key];
          }
        }
      }
      this.postRequest("/inspect/query", requestForm).then((res) => {
        if (res.success) {
          this.dataList = res.data.records;
          var ParentList = this.dataList;
          for (var i = 0; i < ParentList.length; i++) {
            var item = ParentList[i].mechancal;
            var row0 = item[0];
            var row1 = item[1];

            if (
              !Object.prototype.hasOwnProperty.call(row0, "itemVal") ||
              !Object.prototype.hasOwnProperty.call(row1, "itemVal")
            ) {
              item[2].itemVal = "";
              item[3].itemVal = "";
            }
            if (
              !Object.prototype.hasOwnProperty.call(row0, "itemVal2") ||
              !Object.prototype.hasOwnProperty.call(row1, "itemVal2")
            ) {
              item[2].itemVal2 = "";
              item[3].itemVal2 = "";
            }
            if (
              !Object.prototype.hasOwnProperty.call(row0, "itemVal3") ||
              !Object.prototype.hasOwnProperty.call(row1, "itemVal3")
            ) {
              item[2].itemVal3 = "";
              item[3].itemVal3 = "";
            }
          }
          this.dataList = ParentList;
          this.total = res.data.total;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .inner-table {
  margin: 0;
  padding: 0;

  th,
  tr,
  td {
    margin: 0;
    padding: 0;
    background: #e1e1e1;
  }
}

.main-table {
  height: calc(100vh - 15rem);
  overflow-y: scroll;
}

.main-table::before {
  display: none;
}

.main-table-nopage {
  height: calc(100vh - 9.5rem);
}

.main-table-nopage-nosubmain {
  height: calc(100vh - 5.5rem);
}

.no-empty {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-header {
  background: #deebf6;
  color: #000000;
}

/deep/ .el-table__expanded-cell {
  padding: 0;
}
</style>

