module.exports = {
    //根据value查找lable
    getFindLable(value, list) {
        let name = ''
        list.forEach(function (item) {
            if (item.value == value) {
                name = item.label
            }
        })
        return name
    },
    //是否定尺
    fixedLengthList: [
        {value: 0, label: "定尺"},
        {value: 1, label: "非定尺"},
    ],
    //是否入库
    stockStatusList: [
        {value: 3, label: "待入库"},
        {value: 0, label: "入库"},
    ],
    //是否合格
    qualifiedList: [
        {value: 0, label: "合格"},
        {value: 2, label: "销售合格"},
    ],

    qualifiedLists: [
        {value: 0, label: "合格"},
        {value: 1, label: "不合格"},
        {value: 2, label: "销售合格"},
    ],
    //所属仓库
    affiliationNameList: [
        {value: 0, label: "成品"},
        {value: 1, label: "原料"},
        {value: 2, label: "中间仓库"},
        {value: 3, label: "工器仓库"},
    ],

    //级别
    stockLevelList: [
        {value: 0, label: "顶级"},
        {value: 1, label: "子级"},
    ],

    //所属厂区
    affiliationStockList: [
        {value: 0, label: "四川雅安安山钢铁有限公司"},
    ],

    //是否末端
    isEndList: [
        {value: 0, label: "否"},
        {value: 1, label: "是"},
    ],
    //状态
    statusList: [
        {value: 0, label: "正常"},
        {value: 1, label: "禁止"},
    ],

    //状态 0  已入库, 1 出库锁定
    stockList: [
        {value: 0, label: "已入库"},
        {value: 1, label: "出库锁定"},
    ],

    //班组
    classNameIdList: [
        {value: 1, label: "甲班"},
        {value: 2, label: "乙班"},
    ],
//技术条件
    projectList: [
        {value: 1, label: "证书编号"},
        {value: 2, label: "技术条件"},
        {value: 3, label: "交货状态"},
        ],
    //质检报告是否合格
    qualified:[
        {value: 0, label: "合格"},
        {value: 1, label: "不合格"},
    ],
    //检测项目管理
    projectTypeList:[
        {value: 0, label: "物理性能"},
        {value: 1, label: "化学成分"},
        {value: 2, label: "工艺性能"},
        {value: 3, label: "其它"},
    ],
    //入库类型
    stockTypeList:[
        {value: 1, label: "生产入库"},
        {value: 2, label: "盘库入库"},
        {value: 3, label: "退货入库"},
    ],
    //出库类型
    warehouseList:[
        {value: 1, label: "发运出库"},
        {value: 2, label: "倒库出库"},
        {value: 3, label: "平白出库"},
    ],

    //执行状态
    invoiceStatusList:[
        {value: 1, label: "待处理"},
        {value: 2, label: "拣货中"},
        {value: 3, label: "已出库"},
        {value: 4, label: "已出库锁定"},
    ]
}

