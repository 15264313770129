<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="项目名称：">
          <el-select v-model="selectForm.projectName" placeholder="请选择项目名称" size="mini"
                     clearable @change="clearChange">
            <el-option v-for="(item,index) in projectList" :key="index" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增
        </el-button>
      </div>
      <el-table :data="dataList"
                class="main-table"
                stripe
                row-key="id"
                :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column align="center" label="项目名称" prop="projectName">
          <template #default="scope">
            <p v-if="scope.row.projectName==1">证书编号</p>
            <p v-if="scope.row.projectName==2">技术条件</p>
            <p v-if="scope.row.projectName==3">交货状态</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="数据" prop="projectValue"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>

      <el-dialog
          custom-class="dialogMain"
          :title="title"
          v-model="dialogVisible"
          width="40%">
        <el-form :model="processForm" :rules="rules" label-position="top" ref="processForm">
          <el-form-item label="项目名称：" prop="projectName">
            <el-select v-model="processForm.projectName" placeholder="请选择项目名称" size="mini" clearable>
              <el-option v-for="(item,index) in projectList" :key="index" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="数据" prop="projectValue">
            <el-input v-model="processForm.projectValue" placeholder="请输入数据" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="processClick('processForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {projectList} from '../common/baseData'; //项目名称
const _projectList = projectList;
export default {
  name: "CertificateData",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      projectList:_projectList,
      selectForm: {
        projectName: '',
      },
      dataList: [],
      processForm: {
        id:'',
        projectName: '',
        projectValue: ''
      },
      rules: {
        projectName: [{required: true, message: '请选择项目名称', trigger: 'change'}],
        projectValue: [{required: true, message: '请输入数据', trigger: 'blur'}],
      },
      title: '',
      drawerAction: 'add',
      dialogVisible:false
    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
  },
  methods: {
    //查询
    readData() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['pageNum'] = currPage
      this.selectForm['pageSize'] = pageSize;
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-book-portion/listByPage", requestForm).then(res => {
        if (res.success) {
          this.dataList = res.data.records;
          this.total = res.data.total
        }
      })
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-book-portion/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.rowInfo = null;
      if (action == 'add') {
        this.title = '新增基础数据'
        this.drawerAction = 'add'
      } else {
        this.title = '编辑基础数据'
        this.drawerAction = 'edit'
      }
      this.dialogVisible = true;//展开
      if (row) {
        this.rowInfo = row;
        this.processForm['id'] = row['id'];
        this.processForm['projectName'] = row['projectName'];
        this.processForm['projectValue'] = row['projectValue'];
      } else {
        this.processForm = {
          id: '',
          projectName: '',
          projectValue: '',
        }
      }
    },
    //提交按钮被点击时
    processClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-book-portion/save ", this.processForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.dialogVisible = false
                  this.$message.success('添加成功')
                  this.$refs.processForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-book-portion/updateById", this.processForm).then(res => {
              if (res.success) {
                this.$message.success('修改成功')
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.dialogVisible = false;
                  this.$refs.processForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.dialogVisible = false;
      this.$refs.processForm.resetFields();
    },
  },
}
</script>

<style scoped>

</style>

