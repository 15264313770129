<template>
    <div style="width: 100%">
        <div class="subMain">
            <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
                <el-form-item class="row" label="库存地址：">
                    <el-select v-model="selectForm.stockAddrId" placeholder="请选择库存地址" size="mini"
                               @change="clearChange"
                               clearable>
                        <el-option v-for="(item,index) in addressList" :key="index"
                                   :label="item.stockName"
                                   :value="item.stockId"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="物料：" prop="materialCode">
                    <el-select v-model="selectForm.productId" placeholder="请选择物料" size="mini"
                               @change="clearChange" clearable>
                        <el-option v-for="(item,index) in tbProductInfos" :key="index" :label="item.cinvName+' '+item.cinvStd +' '+item.steelGradeName"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <!--<div style="text-align: right">-->
                <!--<el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增</el-button>-->
            <!--</div>-->
            <el-table
                    :data="InventoryList"
                    border
                    stripe
                    :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                    :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        :index="table_index"
                        fixed="left"
                        width="80">
                </el-table-column>
                <el-table-column
                        label="仓库名称"
                        prop="stockName"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="物料名称"
                        prop="cinvName"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="规格"
                        prop="cinvStd"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="钢种"
                        prop="steelGradeName"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="库存总件数"
                        prop="bundleCount"
                        align="center">
                </el-table-column>
                <el-table-column
                        label="库存总重量(t)"
                        prop="stockCount"
                        align="center">
                    <template #default="scope">
                        {{ scope.row.stockCount ? parseFloat((scope.row.stockCount/1000 ? scope.row.stockCount/1000 : '0')).toFixed(3) : 0 }}
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        fixed="right"
                        align="center">
                    <template v-slot="scope">
                        <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="elp"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currPage"
                           :page-sizes="[10,20,30,40]"
                           :page-size="pageSize"
                           :total="total"
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
            <!--新增修改-->
            <el-drawer custom-class="drawerMain"
                       v-model="showDrawer"
                       v-if="showDrawer"
                       :title="title"
                       direction="rtl">
                <div class="drawer_content">
                    <el-form :model="InventoryForm" :rules="rules" label-position="top" ref="InventoryForm">
                        <el-form-item label="库存总件数" prop="standardCode">
                            <el-input-number v-model="InventoryForm.bundleCount" placeholder="请输入库存总件数" size="mini"
                                             style="width: 200px" :min="0" :precision="0" clearable type="number" maxlength="20" :formatter="limitNumber" :parser="limitNumber"></el-input-number>
                        </el-form-item>
                        <el-form-item label="库存总重量(t)" prop="stockCount">
                            <el-input-number v-model="InventoryForm.stockCount" placeholder="请输入库存总重量(t)" size="mini"
                                             :min="0"    style="width: 200px" clearable type="number" maxlength="20" :formatter="limitNumbers" :parser="limitNumbers"></el-input-number>
                        </el-form-item>
                    </el-form>
                    <div class="drawer-footer-height"></div>
                    <div class="dialog-footer">
                        <el-button @click="cancelForm" size="mini">取 消</el-button>
                        <el-button type="primary" size="mini" @click="submitBtnClick('InventoryForm',drawerAction)"
                                   :loading="loading">{{ loading ? '提交中...' : '确 定' }}
                        </el-button>
                    </div>
                </div>
            </el-drawer>
        </div>
    </div>
</template>

<script>

    export default {
        name: "StandardManagement",
        data() {

            return {
                // 列表
                total: 2,
                currPage: 1,
                pageSize: 10,
                parentList: [],
                tbProductInfos: [],
                selectForm: {stockAddrId: '',productId: ''},
                InventoryList: [],
                addressList: [],
                rowInfo: null,
                // 修改
                showDrawer: false,
                InventoryForm: {
                    id: '',
                    bundleCount: '',
                    stockCount: '',

                },
                InventoryFormList: {
                    id: '',
                    bundleCount: '',
                    stockCount: '',
                },
                title: '',
                drawerAction: 'add',
                rules: {

                }
            }
        },
        mounted() {
            this.currentPageChanged(this.currPage, this.pageSize);
            this.getAddress();
            this.materialCodee();
        },
        methods: {
            //清空查询自动加载列表
            readData() {
                this.handleCurrentChange(1);

            },
            //清空查询自动加载列表
            clearChange() {
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            // 删除
            handleDelete(id) {
                if (id >= 0) {
                    this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.getRequest("/bus/tb-standard-info/delete/" + id).then(res => {
                            if (res.success) {
                                this.$message.success('删除成功');
                                this.currentPageChanged(this.currPage, this.pageSize);
                            }
                        })
                    }).catch(() => {
                    })
                }
            },

            //库存地
            getAddress() {
                this.postFormRequest("/bus/tb-stock-carry-forward/realTimeInventoryMonitoring").then(res => {
                    if (res.success) {
                        this.addressList = res.data;
                    }
                })
            },
            // methods 正则替换小数点
            limitNumber(value) {
                if (typeof value === 'string') {
                    return !isNaN(Number(value)) ? value.replace(/\./g, '') : 0
                } else if (typeof value === 'number') {
                    return !isNaN(value) ? String(value).replace(/\./g, '') : 0
                } else {
                    return 0
                }
            },
            // methods 正则替换小数点
            limitNumbers(value) {
                if (typeof value === 'string') {
                    return !isNaN(Number(value)) ? value.splice(2, valArr.length - 2) : 0
                } else if (typeof value === 'number') {
                    return !isNaN(value) ? value.splice(2, valArr.length - 2) : 0
                } else {
                    return 0
                }


            },
            //物料
            materialCodee() {
                this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
                    if (res.success) {
                        this.tbProductInfos = res.data;
                    }
                })
            },
            //展示抽屉的方式
            showDrawerFunc(action, row) {
                if (action == 'add') {
                    this.title = '新增标准'
                    this.drawerAction = 'add'
                } else {
                    this.title = '修改标准'
                    this.drawerAction = 'edit'
                }
                this.showDrawer = true;//展开抽屉
                if (row) {
                    this.InventoryForm['id'] = row['id'];
                    this.InventoryForm['stockCount'] = row['stockCount']/1000;
                    this.InventoryForm['bundleCount'] = row['bundleCount'];

                } else {
                    this.InventoryForm = {
                        id: '',
                        stockCount: '',
                        bundleCount: '',
                    }
                }
            },
            //提交按钮被点击时
            submitBtnClick(formName, action) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (action == 'add') {
                            this.postFormRequest("/bus/tb-standard-info/save", this.InventoryForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false
                                        this.$message.success('添加成功')
                                        this.$refs.InventoryForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        } else {

                            this.postFormRequest("/bus/tb-stock-real-count/updateById", this.InventoryForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$refs.InventoryForm.resetFields();
                                    }, 0);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        }
                    }
                })
            },
            //取消按钮
            cancelForm() {
                this.form = {}
                this.loading = false;
                this.showDrawer = false;
                this.$refs.InventoryForm.resetFields();
            },
            // 分页
            table_index(index) {
                return (this.currPage - 1) * this.pageSize + index + 1
            },
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            },
            handleCurrentChange: function (currPage) {
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            currentPageChanged: function (currPage, pageSize) {
                this.selectForm['current'] = currPage
                this.selectForm['size'] = pageSize
                let requestForm = {}
                for (let key in this.selectForm) {
                    if (this.selectForm[key]) {
                        requestForm[key] = this.selectForm[key]
                    }
                }
                this.postFormRequest("/bus/tb-stock-real-count/listByPage", requestForm).then(data => {
                    if (data.success) {
                        this.InventoryList = data.data.records
                        this.total = data.data.total
                    }
                })
            },
            // 分页 end
        },
        watch: {
            showDrawer: function () {
                if (this.$refs["InventoryForm"] != undefined) {
                    this.$refs["InventoryForm"].resetFields();
                }
            }
        }
    }
</script>

<style lang="scss">
</style>
