<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item label="物料：" prop="materialCode">
          <el-select v-model="selectForm.materialCode" placeholder="请选择物料" size="mini"
                     @change="clearChange" clearable>
            <el-option v-for="(item,index) in tbProductInfos" :key="index" :label="item.cinvName+' '+item.cinvStd +' '+item.steelGradeName"
                       :value="item.cinvCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="退货通知单号：">
          <el-input v-model="selectForm.returnNoteCode" placeholder="请输入退货通知单号" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="车牌号：">
          <el-input v-model="selectForm.carCdoe" placeholder="请输入车牌号" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="退货时间：" prop="returnTime">
          <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.returnTime"
                          clearable :editable="false" placeholder="请选择退货时间" @change="clearChange">
          </el-date-picker>
        </el-form-item>
          <el-form-item>
            <el-button class="btn" size="mini" @click="readData">查询</el-button>
          </el-form-item>
      </el-form>
    </div>

    <div class="main">
      <el-table
          :data="ReturnList"
          border
          stripe
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            fixed="left"
            width="80">
        </el-table-column>
        <el-table-column
            label="退货编号"
            prop="returnNoteCode"
            align="center"
            width="160">
        </el-table-column>
        <el-table-column
                label="物料名称"
                prop="cinvName"
                align="center">
        </el-table-column>
        <el-table-column
                prop="cinvStd"
                label="规格"
                align="center">
        </el-table-column>
        <el-table-column
                prop="steelGradeName"
                label="钢种名称"
                align="center"
                width="100">
        </el-table-column>

        <el-table-column
                prop="clientName"
                label="客户名称"
                align="center">
        </el-table-column>
        <el-table-column
                label="退货原因"
                prop="returnReason"
                align="center"
                width="100">
        </el-table-column>
        <el-table-column
                label="车号"
                prop="carCdoe"
                align="center"
                width="110">
        </el-table-column>
        <el-table-column
                prop="returnTime"
                label="退货日期"
                align="center"
                width="170">
          <template #default="scope">
            {{ scope.row.returnTime ? dateYMDFormat(scope.row.returnTime) : '-' }}
          </template>
        </el-table-column>
        <!--<el-table-column
            label="发货通知单子单"
            prop="orderId"
            align="center"
            width="140">
        </el-table-column>
        <el-table-column
            label="发货通知单主单"
            prop="orderCode"
            align="center"
            width="140">
        </el-table-column>-->


        <!--<el-table-column-->
            <!--prop="saleOrgCode"-->
            <!--label="销售组织编号"-->
            <!--align="center"-->
            <!--width="120">-->
        <!--</el-table-column>-->

        <!--<el-table-column
            prop="prodType"
            label="产品类别"
            align="center">
        </el-table-column>-->
        <!--<el-table-column-->
            <!--prop="cinvCode"-->
            <!--label="物料编码"-->
            <!--align="center">-->
        <!--</el-table-column>-->

        <!--<el-table-column
            prop="length"
            label="长度"
            align="center">
        </el-table-column>
        <el-table-column
            prop="diam"
            label="宽度"
            align="center">
        </el-table-column>-->
        <!--<el-table-column-->
            <!--prop="deliverWarehouseCode"-->
            <!--label="库存地编码"-->
            <!--align="center"-->
            <!--width="100">-->
        <!--</el-table-column>-->
        <el-table-column
            prop="deliverWarehouseName"
            label="库存地名称"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="empName"
            label="退货接收人"
            align="center"
            width="100">
          <template #default="scope">
            {{ scope.row.empName ? scope.row.empName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="状态"
            align="center">
          <template #default="scope">
            <p v-if="scope.row.status==0">待执行</p>
            <p v-if="scope.row.status==1">已完成</p>
            <p v-if="scope.row.status==2">禁用</p>
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="修改时间"
            align="center"
            width="170">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateByName"
            label="修改人"
            align="center">
          <template #default="scope">
            {{ scope.row.updateByName ? scope.row.updateByName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            fixed="right"
            width="200">
          <template #default="scope">
            <div v-if="scope.row.status==0">
              <el-button type="text" size="mini" @click="loadingClick(scope.row)">确认退货</el-button>
            </div>
            <div v-if="scope.row.status==1">
              <el-button type="text" size="mini" @click="revokeClick(scope.row)">撤销</el-button>
                <el-button type="text" size="mini" @click="detailsClick(scope.row)">详情</el-button>
            </div>
            <div v-if="scope.row.status==2">
              <el-button type="text" size="mini" @click="detailsClick(scope.row)">详情</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[10,20,30,40]"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!--    发运通知单明细-->
    <div class="detailsMain">
      <span class="header">退货单明细</span><br>
      <span style="font-size: 16px">退货编码单号：{{ returnNoteCode }}</span>
      <el-table
          :data="tableDetailList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          max-height="300px">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="materialName"
            label="物料名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="cinvStd"
            label="物料编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="specValue"
            label="规格型号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="stoveCode"
            label="炉号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="batchCode"
            label="批号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="bundleCode"
            label="捆号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="cncName"
            label="加工中心"
            align="center">
        </el-table-column>
       <!-- <el-table-column
            prop="stockTime"
            label="入库时间"
            align="center">
          <template #default="scope">
            {{ scope.row.stockTime ? dateYMDFormat(scope.row.stockTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="stockName"
            label="入库操作员"
            align="center">
        </el-table-column>
        <el-table-column
            prop="actWeight"
            label="入库实重"
            align="center">
        </el-table-column>-->
      </el-table>
    </div>
    <!--    确认退货-->
    <el-dialog
        custom-class="dialogContent"
        title="确认退货"
        :before-close="handleClose"
        v-model="dialogVisible"
        v-if="dialogVisible"
        width="60%">
      <div class="header">
        <span class="rowtxt">退货通知单编码：{{ ruleForm.returnNoteCode }}</span>
        <span class="rowtxt">当前锁定重量：{{ weight }}</span>
        <span>当前锁定捆数：{{ bundle }}</span>
        <div style="float: right">
          <el-button type="primary" size="mini" @click="submitBtnClick('ruleForm')">确认</el-button>
        </div>
      </div>
      <div class="line"></div>
      <div class="classmain">
        <el-table
            :data="dataList"
            ref="multipleSelectionTable"
            stripe
            border
            :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
            :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
            @selection-change="handleSelectionChange"
            max-height="400px">
          <el-table-column
              type="selection"
              align="center"
              fixed="left">
          </el-table-column>
          <el-table-column
              type="index"
              align="center"
              label="序号"
              width="80"
              fixed="left">
          </el-table-column>
          <el-table-column
              prop="bundleCode"
              label="捆号"
              align="center"
              width="120">
          </el-table-column>
          <el-table-column
              prop="materialCode"
              label="物料编码"
              align="center">
          </el-table-column>
          <el-table-column
              label="物料名称"
              prop="materialName"
              align="center">
          </el-table-column>
          <el-table-column
              prop="specValue"
              label="规格"
              align="center">
          </el-table-column>
          <el-table-column
              prop="stockName"
              label="库存地址"
              align="center">
          </el-table-column>
          <el-table-column
              prop="pileName"
              label="垛位"
              align="center">
          </el-table-column>
          <el-table-column
              prop="actWeight"
              label="入库重量"
              align="center"
              width="130">
          </el-table-column>
          <el-table-column
              prop="cncName"
              label="加工中心"
              align="center"
              width="150">
          </el-table-column>
          <el-table-column
              prop="stockTime"
              label="入库时间"
              align="center"
              width="110">
            <template #default="scope">
              {{ scope.row.stockTime ? dateYMDFormat(scope.row.stockTime) : '-' }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- &lt;!&ndash;    退货接收人&ndash;&gt;
     <el-dialog
         custom-class="dialogMain"
         title="退货接收人"
         v-model="dialogShow"
         width="30%">
       <el-form :model="receiverForm" :rules="rules" ref="receiverForm">
         <el-form-item label="接收人：" prop="returnReceiver">
           <el-select v-model="receiverForm.returnReceiver" placeholder="请选择接收人" size="mini" clearable>
             <el-option v-for="(item,index) in receiverList" :key="index" :label="item.empName"
                        :value="item.id"></el-option>
           </el-select>
         </el-form-item>
       </el-form>
       <div class="dialog-footer">
         <el-button @click="cancelForm" size="mini">取 消</el-button>
         <el-button type="primary" size="mini" @click="submitBtn('receiverForm')">确定</el-button>
       </div>
     </el-dialog>-->
  </div>
</template>

<script>

export default {
  name: "ReturnOrder",
  data() {
    return {
      total: 2,
      currPage: 1,
      tableDetailList: [],
      pageSize: 10,
      selectForm: {
        materialCode: '',
        returnNoteCode: '',
        returnTime: '',
        endTime: '',
          carCdoe: '',
      },
      ReturnList: [],
      dialogVisible: false,
        tbProductInfos: [],
      loading: false,
      ruleForm: {
        id: '',
        returnNoteCode: '',
      },
      processForm: {
        stockAddrId: '',
        pileId: '',
        bundleCode: '',
      },
      title: '',
      drawerAction: 'add',
      addressList: [],
      pileList: [],
      dataList: [],
      multipleSelection: [],
      dialogShow: false,
      receiverForm: {
        id: '',
        returnReceiver: ''
      },
      rules: {
        returnReceiver: [{
          required: true,
          message: '请选择接收人',
          trigger: 'change'
        }]
      },
      receiverList: []
    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
      this.materialCodee();
  },
  computed: {
    weight: function () {
      //解决重新拣货查询，数据不统一
      const s = new Set();
      let a = 0;
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach(info => {
          if (!s.has(info.id)) {
            s.add(info.id)
            a += info.actWeight
          }
        })
        this.multipleSelection.length = s.size;
        return a;
      }
      return 0;
    },
    bundle: function () {
      let b = 0;
      b = this.multipleSelection.length
      return b
    },
  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.postFormRequest("/tb-return-sales-memo-new/listByPage", {
        materialCode: this.selectForm.materialCode,
        returnNoteCode: this.selectForm.returnNoteCode,
        returnTime: this.selectForm.returnTime ? this.dateYMDhmsFormat(this.selectForm.returnTime) : '',
        current: currPage,
        size: pageSize,
          carCdoe: this.selectForm.carCdoe,
      }).then(res => {
        if (res.success) {
          this.ReturnList = res.data.records
          this.total = res.data.total
        }
      })
    },
      //查询编码名称和规格
      materialCodee() {
          this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
              if (res.success) {
                  this.tbProductInfos = res.data;
              }
          })
      },
    //获取查询的仓库地址和垛位
    getAddress() {
      this.postFormRequest("/bus/tb-stock-in/selectStackTheStorage").then(res => {
        if (res.success) {
          this.addressList = res.data.tbStockAddr;
          this.pileList = res.data.tbPileInfos;
        }
      })
    },
    //装车查询
    queryClick() {
      this.getRequest("/pda_api/stockOut/orderDetail",
          {
            stockAddrId: this.processForm.stockAddrId,
            pileId: this.processForm.pileId,
            bundleCode: this.processForm.bundleCode,
          }).then(res => {
        if (res.success) {
          this.tableList = res.data
        }
      })
    },
    //获取列表勾选框信息
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //装车
    loadingClick(row) {
      this.postFormRequest("/tb-return-sales-memo-new/determineTheReturnOfThe", {
        orderId: row.orderId,
        orderCode: row.orderCode
      }).then(res => {
        if (res.success) {
          this.dataList = res.data.tbStockReals
        }
      })
      if (row) {
        this.ruleForm['id'] = row['id'];
        this.ruleForm['returnNoteCode'] = row['returnNoteCode'];
      }
      this.getAddress();
      this.dialogVisible = true
    },
    //装车提交按钮
    submitBtnClick(formName) {
      this.loading = true;
      let ids = [];
      this.multipleSelection.forEach((ele => {
        ids.push(ele.id);
      }))
      this.postRequest("/tb-return-sales-memo-new/setGoals", {
        id: this.ruleForm.id,
        list: ids
      }).then(res => {
        if (res.success) {
          // 动画关闭需要一定的时间
          setTimeout(() => {
            this.loading = false;
            this.dialogVisible = false
            this.$message.success('提交成功')
          }, 400);
          this.currPage = 1;
          this.currentPageChanged(this.currPage, this.pageSize);
        } else {
          this.loading = false;
          return false;
        }
      })
    },
    //获取接收人下拉列表
    getReceivers() {
      this.postFormRequest("/bus/tb-return-sales-memo/queryingAllEmployees").then(res => {
        if (res.success) {
          this.receiverList = res.data
        }
      })
    },
    //获取发运通知单明细
    detailsClick(row) {
      this.getRequest("/tb-return-sales-memo-new/stockReals/" + row.id).then(res => {
        this.returnNoteCode = row.returnNoteCode;
        if (res.success) {
          this.tableDetailList = res.data
        }
      })
    },
    // 退货接收人
    receiverClick(row) {
      this.getReceivers()
      this.dialogShow = true;
      if (row) {
        this.receiverForm['id'] = row['id'];
        this.receiverForm['returnReceiver'] = row['returnReceiver'];
      }
    },
    //提交接收人
    submitBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postFormRequest("/bus/tb-return-sales-memo/modifyTheEmployee", this.receiverForm).then(res => {
            if (res.success) {
              // 动画关闭需要一定的时间
              setTimeout(() => {
                this.loading = false;
                this.dialogShow = false
                this.$message.success('添加成功')
                this.$refs.receiverForm.resetFields();
              }, 400);
              this.currPage = 1;
              this.currentPageChanged(this.currPage, this.pageSize);
            } else {
              this.loading = false;
              return false;
            }
          })
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.receiverForm = {}
      this.loading = false;
      this.dialogShow = false;
      this.$refs.receiverForm.resetFields();
    },
    //撤销
    revokeClick(row) {
      this.$confirm('是否撤销退货', '撤销退货提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postFormRequest("/tb-return-sales-memo-new/withdrawToDetermine", {
          id: row.id,
          orderId: row.orderId,
          orderCode: row.orderCode
        }).then(res => {
          if (res.success) {
            this.$message.success("取消成功");
            this.currentPageChanged(this.currPage, this.pageSize);
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {
      })
    },
    //关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
  },
  watch: {}
}
</script>

<style lang="scss">
.dialogContent {
  height: 70%;
  overflow: hidden;
}

.header {
  line-height: 30px;
  height: 30px
}

.rowtxt {
  margin-right: 30px;
}

.line {
  border-bottom: 1px solid black;
  padding-bottom: 10px
}

.classmain {
  padding: 20px 0;
  box-sizing: border-box;
}

/deep/ .el-dialog {
  margin-top: 25vh;
}

.dialogMain {
  height: 40%;
}

/deep/ .dialogContent .el-dialog__header {
  padding: 10px 20px 10px;
}

/deep/ .dialogContent .el-dialog__body {
  padding: 10px 20px;
}

.detailsMain {
  margin-top: 20px;
}

.detailsMain .header {
  border-left: 5px solid #4F8AFF;
  padding-left: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 25px
}

</style>
