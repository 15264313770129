import 'core-js/stable'; import 'regenerator-runtime/runtime';
import {createApp} from 'vue'
// import vuexStore from './store/vuexStore';
import App from './App.vue'
import ElementPlus from 'element-plus';
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/lib/theme-chalk/index.css';
import router from './router/router'
import '@/styles/index.scss' // global css
import '@/assets/css.css'
import {ElNotification} from 'element-plus';
import {dateFormat, dateYMDFormat, dateYMDhmsFormat,dateYMDhmFormat, dateHMFormat} from "./utils/dateFormat"
import {
    heartbeat,
    getRequest,
    postFormRequest,
    getRequestApi,
    postRequest,
    getDownload,
    postDownload,
    postImportExcel
} from "./api/api";
import echarts from 'echarts'  //引入echarts

import {getFindLable} from "./common/baseData"
const app = createApp(App);
app.use(ElementPlus, {locale, size: 'mini'});
app.use(router);
// app.use(vuexStore);
app.config.globalProperties.baseUrl = process.env["VUE_APP_BASE_URL"]//接口请求路径
app.config.globalProperties.channel = process.env["VUE_APP_BASE_CHANNEL"] //通道 0 web端，1 app 端
app.config.globalProperties.heartbeat = heartbeat
app.config.globalProperties.ElNotification = ElNotification//消息通知
app.config.globalProperties.getFindLable = getFindLable //根据value查找lable
app.config.globalProperties.dateFormat = dateFormat
app.config.globalProperties.dateYMDFormat = dateYMDFormat
app.config.globalProperties.dateYMDhmsFormat = dateYMDhmsFormat
app.config.globalProperties.dateYMDhmFormat = dateYMDhmFormat
app.config.globalProperties.dateHMFormat = dateHMFormat
app.config.globalProperties.getRequest = getRequest
app.config.globalProperties.postFormRequest = postFormRequest
app.config.globalProperties.getRequestApi = getRequestApi
app.config.globalProperties.postRequest = postRequest
app.config.globalProperties.getDownload = getDownload
app.config.globalProperties.postDownload = postDownload
app.config.globalProperties.postImportExcel = postImportExcel
app.config.globalProperties.$echarts = echarts  //挂载在全局
app.mount('#app');
