<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="岗位名称：">
          <el-input v-model="selectForm.positionName" placeholder="请输入岗位名称" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="所属部门：">
          <el-select v-model="selectForm.departmentId" @change="clearChange" placeholder="请选择所属部门" size="mini" clearable>
            <el-option v-for="(item,index) in departmentList" :key="index" :label="item.departmentName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增岗位</el-button>
      </div>
      <el-table
          :data="tbPositionList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            fixed="left"
            label="序号"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="positionName"
            label="岗位名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="positionDesc"
            label="岗位描述"
            align="center">
          <template #default="scope">
            {{scope.row.positionDesc ? scope.row.positionDesc : '-'}}
          </template>
        </el-table-column>
        <el-table-column
            prop="departmentName"
            label="所属部门"
            align="center">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
            <el-button type="text" size="small" @click="showSetRightDrawer(scope.row.id)">权限编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               :title="title"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbPositionForm" :rules="rules" label-position="top" ref="tbPositionForm">
          <el-form-item label="岗位名称" prop="positionName">
            <el-input v-model="tbPositionForm.positionName" placeholder="请填写岗位名称" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="岗位描述" prop="positionDesc">
            <el-input v-model="tbPositionForm.positionDesc" placeholder="请填写岗位描述" clearable type="text"
                      size="mini" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="departmentId">
            <el-select v-model="tbPositionForm.departmentId" placeholder="请选择所属部门" size="mini" clearable>
              <el-option v-for="(item,index) in departmentList" :key="index" :label="item.departmentName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbPositionForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>
    </el-drawer>
    <el-drawer custom-class="drawerMain"
               v-model="showPermisionDrawer"
               v-if="showPermisionDrawer"
               title="权限编辑"
               size="520px"
               direction="rtl">
      <el-tree
          :data="authorityList"
          :props="treeProps"
          show-checkbox
          default-expand-all
          node-key="id"
          ref="tree"
          auto-expand-parent
          icon-class="el-icon-arrow-right">
      </el-tree>
      <div class="drawer-footer-height"></div>
      <div class="drawer_btn">
        <el-button @click="showPermisionDrawer=false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="savePermission" :loading="loading">
          {{ loading ? '提交中...' : '确 定' }}
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "TbPositionPage",
  data() {
      const blurText = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('岗位名称不能为空'))
          } else {
              if (this.rowInfo) {
                  if (this.rowInfo.positionName == value) {
                      callback()
                  }
              }
              this.postFormRequest("/sys/tb-position/positionName", {positionName: value}).then(data => {
                  if (data.data) {
                      callback(new Error('岗位名称存在'))
                  } else {
                      callback()
                  }
              });
          }
      }
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      tbPositionList: [],
      showDrawer: false,
      title: '',
        rowInfo: null,
      MenuListId: [],
      tbPositionForm: {
        id: '',
        positionName: '',
        positionDesc: '',
        positionId:'',
      },
      selectForm: {
        positionName: '',
        departmentName: '',
      },
      departmentList:[],
      loading: false,
      drawerAction: 'add',
      rules: {
        positionName: [
          {
            required: true,
            message: '请输入岗位名称',
            trigger: 'blur'
          },
            {validator: blurText, trigger: 'blur'}
        ],
          departmentId: [
          {
            required: true,
            message: '请输入所属部门',
            trigger: 'change'
          }
        ],
      },
      showPermisionDrawer: false,
      authorityList: [],//权限管理
      currentPositionId: -1, //点击权限设置之后，保存当前岗位的id
      treeProps: {
        children: "children",
        label: "menuName"
      },
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getDepartmentTree();
  },
  methods: {
    //查询
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
      //查询部门
      getDepartmentTree(){
          this.postFormRequest("/sys/tb-department/getDepartmentTree").then(res=>{
              if (res.success) {
                  this.departmentList= res.data
              }
          })
      },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/sys/tb-position/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);

            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
        this.rowInfo = null;
      if (action == 'add') {
        this.title = '新增岗位'
        this.drawerAction = 'add'
      } else {
        this.title = '修改岗位'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
          this.rowInfo = row;
        this.tbPositionForm['id'] = row['id'];
        this.tbPositionForm['positionName'] = row['positionName'];
        this.tbPositionForm['positionDesc'] = row['positionDesc'];
        this.tbPositionForm['departmentId'] = row['departmentId'];
      } else {
        this.tbPositionForm = {
          id: '',
          positionName: '',
          positionDesc:'',
          departmentId: '',
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/sys/tb-position/save", this.tbPositionForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.tbPositionForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);

              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/sys/tb-position/updateById", this.tbPositionForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.tbPositionForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.tbPositionForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/sys/tb-position/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbPositionList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
    //树状结构展示抽屉
    showSetRightDrawer(ids) {
      this.showPermisionDrawer = true;
      this.currentPositionId = ids;

      if (this.authorityList.length > 0) {
        this.getCurrentPomissions(ids)
        return
      }
      this.postFormRequest("/tb-position-menu/menuList", {
        scenariosId: this.scenariosId
      }).then(data => {
        this.authorityList = data.data
        this.getCurrentPomissions(ids)
      })
    },
    //根据当前岗位获得指定菜单列表权限
    getCurrentPomissions(positionId) {
      this.postFormRequest("/tb-position-menu/getMenuByPid", {
        positionId: positionId,
        scenariosId: this.scenariosId
      }).then(data => {
        this.$refs.tree.setCheckedNodes(data.data);
      })
    },
    //保存权限设置结果
    savePermission() {
      this.showPermisionDrawer = false
      let checkedIds = this.$refs.tree.getCheckedKeys();
      this.postFormRequest("/tb-position-menu/editMenuAutoByPid", {
        positionId: this.currentPositionId,
        scenariosId: this.scenariosId,
        menuIds: JSON.stringify(checkedIds)
      }).then(data => {
        if (data.success) {
          this.$message.success('保存成功')
        } else {
          this.$message({
            type: 'error',
            message: data.msg
          });
        }
      })
    }
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["tbPositionForm"] != undefined) {
        this.$refs["tbPositionForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

