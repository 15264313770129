<template>
  <router-view/>
</template>

<script>
import localStore from "./utils/storage";

export default {
  name: 'App',
  data() {
    return {
      hb: null
    }
  },
  // created() {
  //   this.hb = new this.heartbeat((item) => {
  //     item = JSON.parse(item)
  //     if( this.messageHandle[item.messageTypeEnum+'_messageHandle']){
  //       let result = this.messageHandle[item.messageTypeEnum+'_messageHandle'].call(this,item);
  //       result && this.clickNotify(result);
  //     }
  //   });
  // },
  mounted() {
    let user = localStore.get('warehouse_userInfo');
    if (user != null || user != undefined) {
      this.$router.push('/')
    } else {
      this.$router.push('/login')
    }
  },
  methods: {
    //消息通知
    clickNotify(item) {
      this.$notify({
        title: item.title,
        position: 'bottom-right',
        duration: 60000,
        message: item.content,
        onClick: function (e) {
          console.info('点击', e)
        },
        onClose: function (e) {
          console.info('关闭', e)
        }
      });
    },
  }
}
</script>
