<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="库存地名称：">
          <el-input v-model="selectForm.stockName" placeholder="请输入库存地名称" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="库存地级别：">
          <el-select v-model="selectForm.stockLevel" placeholder="请选择库存地级别" size="mini" clearable @change="clearChange">
            <el-option v-for="(item,index) in selectStockLevelList" :key="index" :label="item.selectStockLevel"
                       :value="item.stockLevel"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button  class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button  type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增</el-button>
      </div>
      <el-table
          :data="InventoryList"
          border
          stripe
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            fixed="left"
            width="80">
        </el-table-column>
        <el-table-column
            label="库存地编码"
            prop="stockCode"
            align="center">
        </el-table-column>
        <el-table-column
            label="库存地名称"
            prop="stockName"
            align="center">
        </el-table-column>
        <el-table-column
            label="所属厂区"
            prop="affiliationStock"
            align="center">
          <template #default="scope">
            {{(scope.row.affiliationStock || scope.row.affiliationStock == 0) ?
                  getFindLable(scope.row.affiliationStock, affiliationStockList) : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
            label="库存上限（捆）"
            prop="upperLimit"
            align="center">
        </el-table-column>
        <el-table-column
            label="库存上限预警值（捆）"
            prop="upperLimitWarn"
            align="center">
        </el-table-column>
        <el-table-column
            label="库存下限（捆）"
            prop="lowerLimit"
            align="center">
        </el-table-column>
        <el-table-column
            label="上级库存地"
            prop="parentName"
            align="center">
          <template #default="scope">
            {{ scope.row.parentName ? scope.row.parentName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="所属仓库"
            prop="affiliationName"
            align="center">
          <template #default="scope">
            {{(scope.row.affiliationName || scope.row.affiliationName == 0) ?
                  getFindLable(scope.row.affiliationName, affiliationNameList) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="库存地级别"
            prop="stockLevel"
            align="center">
          <template #default="scope">
            {{ scope.row.stockLevel + '级' }}
          </template>
        </el-table-column>
        <el-table-column
            label="是否末端"
            prop="isEnd"
            align="center">
          <template #default="scope">
            {{ scope.row.isEnd == 0 ? '否' : '是' }}
          </template>
        </el-table-column>

        <el-table-column
                label="u8仓库对应编码"
                prop="eightEntrepot"
                align="center">
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            fixed="right">
          <template v-slot="scope">
            <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑</el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!--新增修改-->
      <el-drawer custom-class="drawerMain"
                 v-model="showDrawer"
                 v-if="showDrawer"
                 :title="title"
                 direction="rtl">
        <div class="drawer_content">
          <el-form :model="InventoryForm" :rules="rules" label-position="top" ref="InventoryForm">
            <el-form-item label="库区编码" prop="stockCode">
              <el-input v-model="InventoryForm.stockCode" placeholder="请输入库区编码" size="mini"
                        clearable type="text" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="库区名称" prop="stockName">
              <el-input v-model="InventoryForm.stockName" placeholder="请输入库区名称" size="mini"
                        clearable type="text" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item label="所属厂区" prop="affiliationStock">
              <el-select v-model="InventoryForm.affiliationStock" placeholder="请选择所属厂区" size="mini"
                         clearable>
                <el-option v-for="(item,index) in affiliationStockList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="库存上限" prop="upperLimit">
              <el-input v-model="InventoryForm.upperLimit" placeholder="请输入库存上限" size="mini"
                        clearable type="text" maxlength="9"></el-input>
            </el-form-item>

            <el-form-item label="库存上限预警值" prop="upperLimitWarn">
              <el-input v-model="InventoryForm.upperLimitWarn" placeholder="请输入库存上限预警值" size="mini"
                        clearable type="text" maxlength="9"></el-input>
            </el-form-item>

            <el-form-item label="库存下限" prop="lowerLimit">
              <el-input v-model="InventoryForm.lowerLimit" placeholder="请输入库存下限" size="mini"
                        clearable type="text" maxlength="9"></el-input>
            </el-form-item>

            <el-form-item label="上级库存地" prop="parentId">
              <el-select v-model="InventoryForm.parentId" placeholder="请选择上级库存地" size="mini" clearable>
                <el-option v-for="(item,index) in parentIdList" :key="index" :label="item.stockName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="所属仓库" prop="affiliationName">
              <el-select v-model="InventoryForm.affiliationName" placeholder="请选择所属仓库" size="mini"
                         clearable>
                <el-option v-for="(item,index) in affiliationNameList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否顶级" prop="level">
              <el-select v-model="InventoryForm.level" placeholder="请选择库区级别" size="mini" clearable>
                <el-option v-for="(item,index) in stockLevelList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="是否末端" prop="isEnd">
              <el-select v-model="InventoryForm.isEnd" placeholder="请选择是否末端" size="mini" clearable>
                <el-option v-for="(item,index) in isEndList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="u8仓库对应编码" prop="eightEntrepot">
              <el-input v-model="InventoryForm.eightEntrepot" placeholder="请输入u8库存编码" size="mini"
                        clearable type="text" ></el-input>
            </el-form-item>

          </el-form>
          <div class="drawer-footer-height"></div>
          <div class="dialog-footer">
            <el-button size="mini" @click="cancelForm">取 消</el-button>
            <el-button type="primary" size="mini" @click="InventoryClick('InventoryForm',drawerAction)">确
              定
            </el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {affiliationNameList} from '../common/baseData'; //仓库
import {stockLevelList} from '../common/baseData'; // 级别
import {affiliationStockList} from '../common/baseData'; // 厂区
import {isEndList} from '../common/baseData'; // 是否末端

import localStore from "../utils/storage";

const _affiliationNameList = affiliationNameList;
const _stockLevelList = stockLevelList;
const _isEndList = isEndList;
const _affiliationStockList = affiliationStockList;
export default {
  name: "InventoryAddress",
  data() {
    const blurText = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('编码不能为空'))
      } else {
        if (this.rowInfo) {
          if (this.rowInfo.stockCode == value) {
            callback()
          }
        }else if (!/^[A-Za-z0-9]+$/.test(value)) {
          callback(new Error('库存编码格式错误'))
        }
        this.postFormRequest("/tb-stock-addr/exists", {stockCode: value}).then(data => {
          if (data.data) {
            callback(new Error('库存编码存在'))
          } else {
            callback()
          }
        });
      }
    };
      const blurText1 = (rule, value, callback) => {
          if (value === '') {
              callback(new Error('名称不能为空'))
          } else {
              if (this.rowInfo) {
                  if (this.rowInfo.stockName == value) {
                      callback()
                  }
              }
              this.postFormRequest("/tb-stock-addr/existsName", {stockName: value}).then(data => {
                  if (data.data) {
                      callback(new Error('库存名称存在'))
                  } else {
                      callback()
                  }
              });
          }
      }

    return {
      userInfo: localStore.get('warehouse_userInfo'),

      rowInfo: null,
      // 查询
      selectForm: {
        stockName: '',
        stockLevel: '',
      },
      levelList: [],
      parentIdList: [],
      isDisabled: false, //是否可编辑
      isDisabledOne: true,//是否可编辑
      selectStockLevelList: [],//级别
      // 列表
      total: 2,
      currPage: 1,
      pageSize: 10,
      affiliationNameList: _affiliationNameList,
      stockLevelList: _stockLevelList,
      affiliationStockList: _affiliationStockList,
      isEndList: _isEndList,
      InventoryList: [],
      // 修改
      showDrawer: false,
      InventoryForm: {
        id: '',
        stockCode: '',
        stockName: '',
        affiliationStock: '',
        upperLimit: '',
        upperLimitWarn: '',
        lowerLimit: '',
        parentId: '',
        affiliationName: '',
          eightEntrepot: '',
        level: '',
        isEnd: ''
      },
      rules: {
        stockCode: [
          {validator: blurText, trigger: 'blur', required: true}
        ],
        stockName: [
            {validator: blurText1, trigger: 'blur', required: true}
        ],
        affiliationStock: [
          {
            required: true,
            message: '请选择所属厂区',
            trigger: 'blur'
          }
        ],
        upperLimit: [{
          required: true,
          message: '请输入库存上限',
          trigger: 'blur'
        }],
        upperLimitWarn: [
          {
            required: true,
            message: '请输入库存上限预警值',
            trigger: 'blur'
          }
        ],
        isEnd: [
          {
            required: true,
            message: '请选择是否末端',
            trigger: 'blur'
          }
        ],
          eightEntrepot: [
              {
                  required: true,
                  message: '请输入编码',
                  trigger: 'blur'
              }
          ],
        affiliationName: [
          {
            required: true,
            message: '请选择所属仓库',
            trigger: 'blur'
          }
        ],

        lowerLimit: [
          {
            required: true,
            message: '请输入库存下限',
            trigger: 'blur'
          }
        ],
        level: [
          {
            required: true,
            message: '请选择库区级别',
            trigger: 'blur'
          }
        ],
      },

      title: '',
      loading: false,
      drawerAction: 'add',

    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.selectStockLevels();
  },
  methods: {
    //查询所有的库存地
    selectStock() {
      this.postFormRequest("/tb-stock-addr/selectStock").then(res => {
        if (res.success) {
          this.parentIdList = res.data;
        }
      }).catch(() => {

      })
    },
    //查询所有的库存地
    selectStockLevels() {
      this.postFormRequest("/tb-stock-addr/selectStockLevel").then(res => {
        if (res.success) {
          this.selectStockLevelList = res.data;
        }
      }).catch(() => {

      })
    },
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-stock-addr/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.rowInfo = null;
      this.selectStock();
      this.selectStockLevels();
      if (action == 'add') {
        this.title = '新增库存地'
        this.drawerAction = 'add'
      } else {
        this.title = '编辑库存地'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.rowInfo = row;
        this.InventoryForm['id'] = row['id'];
        this.InventoryForm['stockCode'] = row['stockCode'];
        this.InventoryForm['stockName'] = row['stockName'];
        this.InventoryForm['affiliationStock'] = row['affiliationStock'];
        this.InventoryForm['upperLimit'] = row['upperLimit'];
        this.InventoryForm['upperLimitWarn'] = row['upperLimitWarn'];
        this.InventoryForm['lowerLimit'] = row['lowerLimit'];
        this.InventoryForm['parentId'] = row['parentId'];
        this.InventoryForm['affiliationName'] = row['affiliationName'];
        this.InventoryForm['level'] = row['level'];
        this.InventoryForm['isEnd'] = row['isEnd'];
        this.InventoryForm['eightEntrepot'] = row['eightEntrepot'];
      } else {
        this.InventoryForm = {
          stockCode: '',
          stockName: '',
          affiliationStock: '',
          upperLimit: '',
          upperLimitWarn: '',
          lowerLimit: '',
          parentId: '',
          affiliationName: '',
            eightEntrepot: '',
          level: '',
          isEnd: ''
        }
      }
    },
    //提交按钮被点击时
    InventoryClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-stock-addr/save", this.InventoryForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-stock-addr/updateById", this.InventoryForm).then(res => {
              if (res.success) {
                this.$message.success('修改成功')
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.InventoryForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);

    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-stock-addr/listByPage", requestForm
      ).then(res => {
        if (res.success) {
          this.InventoryList = res.data.records
          this.total = res.data.total
        }
      })
    },
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["InventoryForm"] != undefined) {
        this.$refs["InventoryForm"].resetFields();
      }
    }
  },
}
</script>

<style lang="scss">
</style>
