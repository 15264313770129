<template>
  <el-scrollbar class="main_aside">
    <el-menu :router="true"
             :collapse="isCollapse"
             :uniqueOpened="true"
             v-if="dataList.length>0"
             :default-active="activePath"
             class="el-menu-vertical-demo"
             @open="collapseOpen"
             @close="collapseClose"
             background-color="#304156"
             text-color="#fff"
             active-text-color="#ffd04b">
      <el-submenu v-for="(item,i) in dataList" :key="i" :index="item.id+''">
        <template #title>
          <i :class="item.menuIcon ? item.menuIcon : 'el-icon-menu'"></i>
          <span>{{ item.menuName }}</span>
        </template>
        <el-menu-item :ref="'menuItem_'+childrenItem.menuURL.substring(1)"
                      v-for="(childrenItem) in dataList[i].children" :key="childrenItem.id"
                      :index="childrenItem.menuURL" @click="clickMenuItem(childrenItem)">{{ childrenItem.menuName }}
        </el-menu-item>
      </el-submenu>
    </el-menu>
  </el-scrollbar>
</template>

<script>
// import localStore from '../../utils/storage.js'

import localStore from "@/utils/storage";

export default {
  name: "Sidebar",
  props: {
    isCollapse: {
      type: Boolean, //可指定接收类型，如:Array.
      default: false     //可设置默认值
    },
  },
  data() {
    return {
      collapseBtnClick: false,//点击展开收起
      activeDate: true,
      menuIcon: false,
      dataList: [],//菜单树状结构
      menuPage: "",
      menuPageIndex: 0,
      tabList: [],
      activePath: '',
      user: localStore.get('warehouse_userInfo'),
      userInfo: {},
    }
  },
  mounted() {
    this.user = localStore.get('warehouse_userInfo');
    if (this.user) {
      this.userInfo.positionId = this.user.positionId
    }
    const callback = () => {
      this.activePath = this.dataList[0].children[0].menuURL;
      this.$router.push({path: this.activePath});
      this.triggerMenuChange(this.dataList[0].children[0]);
    };
    this.getLeftMenu(callback);//调用左侧菜单
  },
  methods: {
    //设置默认选中项
    menuItemClick(menuURL) {
      this.activePath = menuURL;
      this.$forceUpdate();
    },
    //向子组件传递菜单菜单选中的数据
    triggerMenuChange(item) {
      this.$emit("menuItemChange", item);
    },
    //点击菜单事件
    clickMenuItem(item) {
      this.activePath = item.menuURL;
      this.triggerMenuChange(item);
    },
    // 打开
    collapseOpen() {
      this.menuIcon = false
    },
    // 关闭
    collapseClose() {
      this.menuIcon = true
    },
    //获取左侧菜单
    getLeftMenu(callback) {
      this.postFormRequest("/tb-position-menu/getMenuAuthInfoByPid", {
        positionId: this.userInfo.positionId,
      }).then(data => {
        this.dataList = data.data
        callback && callback();
      })
      if (!this.dataList) {
        this.$router.push('/Login');
        return false;
      }
      return true;
    },

  }
}
</script>
<style scoped>
.el-menu {
  border-right: 0;
}
</style>
