<template>
    <div style="width: 100%">
        <div class="subMain">
            <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">

                <el-form-item class="row" label="检测项目:">
                    <el-input v-model="selectForm.projectCategory" placeholder="请输入检测项目" size="mini"
                              clearable type="text" @change="clearChange"></el-input>
                </el-form-item>

                <el-form-item class="row" label="项目名称:">
                    <el-input v-model="selectForm.projectName" placeholder="请输入项目名称" size="mini"
                              clearable type="text" @change="clearChange"></el-input>
                </el-form-item>

                <el-form-item class="row" label="检测类型:">
                    <el-select v-model="selectForm.projectType" placeholder="请选择库存地级别" size="mini" clearable @change="clearChange">
                        <el-option v-for="(item,index) in projectTypeList" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <div style="text-align: right">
                <el-button  type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增信息</el-button>
            </div>
            <el-table
                    :data="ElementManagementList"
                    stripe
                    border
                    :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                    :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
                <el-table-column
                        type="index"
                        align="center"
                        fixed="left"
                        label="序号"
                        :index="table_index"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="projectCategory"
                        label="检测项类目"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="projectName"
                        label="	检测项名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="projectUnit"
                        label="计量单位"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="projectType"
                        label="检测类型"
                        fixed="right"
                        align="center">
                    <template #default="scope">
                        {{(scope.row.projectType)!=null ?
                        getFindLable(scope.row.projectType, projectTypeList) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        fixed="right"
                        label="操作">
                    <template v-slot="scope">
                        <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="elp"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currPage"
                           :page-sizes="[10,20,30,40]"
                           :page-size="pageSize"
                           :total="total"
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <el-dialog custom-class="drawerMain"
                   v-model="showDrawer"
                   v-if="showDrawer"
                   :title="title"
                   width="40%"
                   direction="rtl">
            <div class="drawer_content">
                <el-form :model="tbMaterialInfoForm" :rules="rules" label-position="top" ref="tbMaterialInfoForm">
                    <el-form-item label="检测项类目" prop="projectCategory">
                        <el-input v-model="tbMaterialInfoForm.projectCategory" placeholder="请输入支数" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="检测项名称" prop="projectName">
                        <el-input v-model="tbMaterialInfoForm.projectName" placeholder="请输入理重" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="计量单位" prop="projectUnit">
                        <el-input v-model="tbMaterialInfoForm.projectUnit" placeholder="请输入理重" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>

                    <el-form-item label="检测类型" prop="projectType">
                        <el-select v-model="tbMaterialInfoForm.projectType" placeholder="请选择垛位" size="mini"
                                   clearable>
                            <el-option v-for="(item,index) in projectTypeList" :key="index" :label="item.label"
                                       :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer-height"></div>
                <div class="dialog-footer">
                    <el-button @click="cancelForm" size="mini">取 消</el-button>
                    <el-button type="primary" size="mini" @click="submitBtnClick('tbMaterialInfoForm',drawerAction)"
                               :loading="loading">{{ loading ? '提交中...' : '确 定' }}
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {projectTypeList} from '../common/baseData';//检测项目管理
    const _projectTypeList =projectTypeList;
    export default {
        name: "TbMaterialInfoPage",
        data() {
            return {
                total: 2,
                currPage: 1,
                pageSize: 10,
                ElementManagementList: [],
                showDialog: false,
                title:'',
                rowInfo: null,
                projectTypeList: _projectTypeList,
                showDrawer : false,//展开抽屉
                tbMaterialInfoForm: {
                    id: '',
                    projectCategory: '',
                    projectName: '',
                    projectType: '',
                    projectUnit: '',
                },
                drawerAction:'add',
                typeList: [],
                selectForm: {
                    projectCategory: '',
                    projectType: '',
                    projectName: '',
                },
                loading: false,
                rules: {
                    projectCategory: [
                        {
                            required: true,
                            message: '请填写检测项目',
                            trigger: 'blur'
                        }
                    ],
                    projectName: [
                        {
                            required: true,
                            message: '请填写项目名称',
                            trigger: 'blur'
                        }
                    ],
                }

            }
        },

        mounted() {
            this.currentPageChanged(this.currPage, this.pageSize);
        },
        methods: {
            //查询
            readData() {
                this.handleCurrentChange(1);
            },
            //清空查询自动加载列表
            clearChange() {
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            //展示抽屉的方式
            showDrawerFunc(action, row) {
                if (action == 'add') {
                    this.title = '新增测试项目'
                    this.drawerAction = 'add'
                } else {
                    this.title = '编辑测试项目'
                    this.drawerAction = 'edit'
                }
                this.showDrawer = true;//展开抽屉
                if (row) {
                    this.rowInfo = row;
                    this.tbMaterialInfoForm['id'] = row['id'];
                    this.tbMaterialInfoForm['projectCategory'] = row['projectCategory'];
                    this.tbMaterialInfoForm['projectName'] = row['projectName'];
                    this.tbMaterialInfoForm['projectType'] = row['projectType'];
                    this.tbMaterialInfoForm['projectUnit'] = row['projectUnit'];
                } else {
                    this.tbMaterialInfoForm = {
                        id: '',
                        projectCategory: '',
                        projectName: '',
                        projectType: '',
                        projectUnit: '',
                    }
                }
            },
            //提交按钮被点击时
            submitBtnClick(formName,action) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (action == 'add') {
                            this.postRequest("/inspection-item/save", this.tbMaterialInfoForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false
                                        this.$message.success('添加成功')
                                        this.$refs.tbMaterialInfoForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        } else {
                            this.postRequest("/inspection-item/updateById", this.tbMaterialInfoForm).then(res => {
                                if (res.success) {
                                    this.$message.success('修改成功')
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$refs.tbMaterialInfoForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        }
                    }
                })
            },
            //取消按钮
            cancelForm() {
                this.form = {}
                this.loading = false;
                this.showDrawer = false;
                this.$refs.tbMaterialInfoForm.resetFields();
            },
            // 分页
            table_index(index) {
                return (this.currPage - 1) * this.pageSize + index + 1
            },
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            },
            handleCurrentChange: function (currPage) {
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            currentPageChanged: function (currPage, pageSize) {
                let param={}
                if(this.selectForm.projectName!=null&&this.selectForm.projectName!=''){
                    param['projectName']=this.selectForm.projectName
                }

                if(this.selectForm.projectType!=null&&this.selectForm.projectType!=''){
                    param['projectType']=this.selectForm.projectType
                }

                if(this.selectForm.projectCategory!=null&&this.selectForm.projectCategory!=''){
                    param['projectCategory']=this.selectForm.projectCategory
                }

                this.postRequest("/inspection-item/listByPage?current="+currPage+"&size="+pageSize,param).then(res => {
                    if (res.success) {
                        this.ElementManagementList = res.data.records
                        this.total = res.data.total
                    }

                })
            },
            // 分页 end
        },
        watch: {
            showDrawer: function () {
                if (this.$refs["tbMaterialInfoForm"] != undefined) {
                    this.$refs["tbMaterialInfoForm"].resetFields();
                }
            }
        }
    }
</script>

<style scoped>
    /*抽屉主体样式*/
    .drawer_content {
        margin: 10px;
        /*height: 100vh;*/
        height: calc(100% - 200px);
    }

    .elp {
        text-align: right;
    }


</style>

