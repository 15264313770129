<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="库存地址：">
          <el-select v-model="selectForm.stockAddrId" placeholder="请选择库存地址" size="mini" @change="clearChange"
                     clearable>
            <el-option v-for="(item,index) in addressList" :key="index" :label="item.stockName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="物料名称规格：">
          <el-select v-model="selectForm.productId" placeholder="物料名称规格" size="mini" @change="clearChange"
                     clearable>
            <el-option v-for="(item,index) in tbProductInfos" :key="index"
                       :label="item.cinvName+' '+item.cinvStd"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
            <el-form-item class="row" label="时间：" prop="starTime">
              <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.starTime"
                              clearable :editable="false" placeholder="请选择开始时间" @change="clearChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="~" prop="endTime">
              <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.endTime"
                              clearable :editable="false" placeholder="请选择结束时间" @change="clearChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
            </el-form-item>
      </el-form>
    </div>

    <div class="main">
      <div style="float: right">
        <el-button @click="showClick()" type="primary" size="small" class="addButton">库存结转</el-button>
      </div>
      <el-table
          :data="tbInventoryList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            width="80"
            fixed="left">
        </el-table-column>
        <el-table-column
            prop="stockName"
            label="库存地址"
            align="center">
        </el-table-column>
        <el-table-column
            prop="materialName"
            label="物料名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="cinvStd"
            label="物料编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="specValue"
            label="规格"
            align="center"
            width="130">
        </el-table-column>
        <!--<el-table-column
            prop="steelGradeName"
            label="钢种"
            align="center">
        </el-table-column>-->

        <!--<el-table-column
            prop="branchCount"
            label="每捆支数"
            align="center">
        </el-table-column>-->
        <el-table-column
            prop="ascriptionDate"
            label="结转日期"
            align="center"
            width="100">
          <template #default="scope">
            {{ scope.row.ascriptionDate ? dateYMDFormat(scope.row.ascriptionDate) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="openingInventoryWeight"
            label="期初库存总重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.openingInventoryWeight ? parseFloat((scope.row.openingInventoryWeight/1000 ? scope.row.openingInventoryWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="openingInventoryBundle"
            label="期初库存总捆"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="sameDayStockInWeight"
            label="当日入库总重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.sameDayStockInWeight ? parseFloat((scope.row.sameDayStockInWeight/1000 ? scope.row.sameDayStockInWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
                prop="sameDayStockInBundle"
                label="当日入库总捆"
                align="center"
                width="120">
        </el-table-column>



        <el-table-column
            prop="sameDayStockOutWeight"
            label="当日出库总重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.sameDayStockOutWeight ? parseFloat((scope.row.sameDayStockOutWeight/1000 ? scope.row.sameDayStockOutWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
                prop="sameDayStockOutBundle"
                label="当日出库总捆"
                align="center"
                width="120">
        </el-table-column>
        <el-table-column
                prop="sameDayStockInWeightMonth"
                label="月入库总重(t)"
                align="center"
                width="120">
          <template #default="scope">
            {{ scope.row.sameDayStockInWeightMonth ? parseFloat((scope.row.sameDayStockInWeightMonth/1000 ? scope.row.sameDayStockInWeightMonth/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="sameDayStockOutWeightMonth"
            label="月出库总重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.sameDayStockOutWeightMonth ? parseFloat((scope.row.sameDayStockOutWeightMonth/1000 ? scope.row.sameDayStockOutWeightMonth/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
                prop="sameDayStockInWeightMonth"
                label="月入库总重(t)"
                align="center"
                width="120">
          <template #default="scope">
            {{ scope.row.sameDayStockInWeightMonth ? parseFloat((scope.row.sameDayStockInWeightMonth/1000 ? scope.row.sameDayStockInWeightMonth/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>

        <el-table-column
            prop="endingInventoryWeight"
            label="期末库存总重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.endingInventoryWeight ? parseFloat((scope.row.endingInventoryWeight/1000 ? scope.row.endingInventoryWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="endingInventoryBundle"
            label="期末库存总捆"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="executeByName"
            label="结转操作员"
            align="center"
            width="100">
          <template #default="scope">
            {{ scope.row.executeByName ? scope.row.executeByName : '-' }}
          </template>
        </el-table-column>
        <!--<el-table-column
            align="center"
            width="120"
            label="操作"
            fixed="right">
          <template v-slot="scope">
            <el-button @click="showClick(scope.row)" type="text" size="small">库存结转</el-button>
          </template>
        </el-table-column>-->
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               title="新增结转"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="processForm" :rules="rules" label-position="top" ref="processForm">
          <!--          <div v-if="drawerAction == 'add'">-->
          <el-form-item label="库存地址：" prop="stockAddrId">
            <el-select v-model="processForm.stockAddrId" placeholder="请选择库存地址" size="mini" clearable>
              <el-option v-for="(item,index) in adsList" :key="index" :label="item.stockName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="产品：" prop="productId">
            <el-select v-model="processForm.productId" placeholder="请选择产品组合信息" size="mini" clearable>
              <el-option v-for="(item,index) in productList" :key="index"
                         :label="item.productName+' + '+item.categoryName+' + '+item.specValue+' + '+item.steelGradeName+' + '+item.steelGradeName"
                         :value="item.productId"></el-option>
            </el-select>
          </el-form-item>
          <!--          </div>-->
          <!--          <div v-if="drawerAction=='edit'">-->
          <!--              <el-descriptions :column="1">-->
          <!--                <el-descriptions-item label="库存地址：">{{ processForm.stockName }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="品名：">{{ processForm.productName }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="品类：">{{ processForm.categoryName }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="规格：">{{ processForm.specValue }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="钢种：">{{ processForm.steelGradeName }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="物料：">{{ processForm.materialName }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="每捆支数：">{{ processForm.branchCount }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="结转日期：">{{ processForm.ascriptionDate }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="期初库存总重：">{{ processForm.openingInventoryWeight }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="期初库存总捆：">{{ processForm.openingInventoryBundle }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="当日入库总重：">{{ processForm.sameDayStockInWeight }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="当日入库总捆：">{{ processForm.sameDayStockInBundle }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="当日出库总重：">{{ processForm.sameDayStockOutWeight }}</el-descriptions-item>-->
          <!--                <el-descriptions-item label="当日出库总捆：">{{ processForm.sameDayStockOutBundle }}</el-descriptions-item>-->
          <!--              </el-descriptions>-->
          <!--              <el-form-item label="期末库存总重" prop="endingInventoryWeight">-->
          <!--                <el-input v-model="processForm.endingInventoryWeight" placeholder="请填写期末库存总重" clearable type="text"-->
          <!--                          size="mini"></el-input>-->
          <!--              </el-form-item>-->
          <!--              <el-form-item label="期末库存总捆" prop="endingInventoryBundle">-->
          <!--                <el-input v-model="processForm.endingInventoryBundle" placeholder="请填写期末库存总捆" clearable type="text"-->
          <!--                          size="mini"></el-input>-->
          <!--              </el-form-item>-->
          <!--            </div>-->
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('processForm')"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: "InventoryCarryForward",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        stockAddrId: '',
        productName: '',
        productId: '',
        starTime: '',
        endTime: ''
      },
      tbProductInfos: [],
      addressList: [],
      tbInventoryList: [],
      showDrawer: false,
      processForm: {
        id: '',
        stockAddrId: '',
        productId: '',
      },
      drawerAction: 'add',
      title: '',
      loading: false,
      rules: {
        stockAddrId: [
          {
            required: true,
            message: '请选择库存地址',
            trigger: 'change'
          }
        ],
        productId: [
          {
            required: true,
            message: '请选择产品组合信息',
            trigger: 'change'
          }
        ],
      },
      adsList: [],
      productList: [],
    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getAddress();
    this.materialCode();
  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //查询库存地
    getAddress() {
      this.postFormRequest("/bus/tb-stock-in/selectStackTheStorageSpace").then(res => {
        if (res.success) {
          this.addressList = res.data.tbStockAddr;
        }
      })
    },
//查询编码名称和规格
    materialCode() {
      this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
        if (res.success) {
          this.tbProductInfos = res.data;
        }
      })
    },
    //查询库存地和产品
    getProductList() {
      this.postFormRequest("/bus/tb-stock-carry-forward/obtainProductInformation").then(res => {
        if (res.success) {
          this.adsList = res.data.tbStockAddr;
          this.productList = res.data.productInfoVos;
        }
      })
    },
    //展示抽屉的方式
    showDialog() {
      this.getProductList();
      this.showDrawer = true;//展开抽屉
      // if (row) {
      // this.processForm['id'] = row['id'];
      // this.processForm['stockName'] = row['stockName'];
      // this.processForm['productName'] = row['productName'];
      // this.processForm['categoryName'] = row['categoryName'];
      // this.processForm['specValue'] = row['specValue'];
      // this.processForm['steelGradeName'] = row['steelGradeName'];
      // this.processForm['materialName'] = row['materialName'];
      // this.processForm['branchCount'] = row['branchCount'];
      // this.processForm['ascriptionDate'] = row['ascriptionDate'];
      // this.processForm['openingInventoryWeight'] = row['openingInventoryWeight'];
      // this.processForm['openingInventoryBundle'] = row['openingInventoryBundle'];
      // this.processForm['sameDayStockInWeight'] = row['sameDayStockInWeight'];
      // this.processForm['sameDayStockInBundle'] = row['sameDayStockInBundle'];
      // this.processForm['sameDayStockOutWeight'] = row['sameDayStockOutWeight'];
      // this.processForm['sameDayStockOutBundle'] = row['sameDayStockOutBundle'];
      // this.processForm['endingInventoryWeight'] = row['endingInventoryWeight'];
      // this.processForm['endingInventoryBundle'] = row['endingInventoryBundle'];
      // }else {
      this.processForm = {
        stockAddrId: '',
        productId: '',
        ascriptionDate: '',
        // }
      }
    },
    //新增结转提交按钮被点击时
    submitBtnClick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.postFormRequest("/bus/tb-stock-carry-forward/carryForwardtheData", {
            stockAddrId: this.processForm.stockAddrId,
            productId: this.processForm.productId,
          }).then(res => {
            if (res.success) {
              // 动画关闭需要一定的时间
              setTimeout(() => {
                this.loading = false;
                this.showDrawer = false
                this.$message.success('添加成功')
                this.$refs.processForm.resetFields();
              }, 400);
              this.currPage = 1;
              this.currentPageChanged(this.currPage, this.pageSize);
            } else {
              this.loading = false;
              return false;
            }
          })
        }
      })
    },
    //库存结转
    showClick() {
      this.$confirm('是否确认结转', '库存结转提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postFormRequest("/bus/tb-stock-carry-forward/carryForwardtheData", {
          /* id: row.id,
           stockAddrId: row.stockAddrId,
           productId: row.productId*/
        }).then(res => {
          if (res.success) {
            this.$message.success("结转成功");
            this.currentPageChanged(this.currPage, this.pageSize);
          }
        })
      }).catch(() => {
      })
    },
    //取消按钮
    cancelForm() {
      this.processForm = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.processForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.postFormRequest("/bus/tb-stock-carry-forward/listByPage", {
        stockAddrId: this.selectForm.stockAddrId,
        productName: this.selectForm.productName,
        productId: this.selectForm.productId,
        starTime: this.selectForm.starTime ? this.dateYMDFormat(this.selectForm.starTime) : '',
        endTime: this.selectForm.endTime ? this.dateYMDFormat(this.selectForm.endTime) : '',
        current: currPage,
        size: pageSize,
      }).then(res => {
        if (res.success) {
          this.tbInventoryList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["processForm"] != undefined) {
        this.$refs["processForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
.elp {
  text-align: right;
}

/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}

</style>

