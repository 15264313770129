<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="物料类型编码/名称：">
          <el-input v-model="selectForm.typeId" placeholder="请输入物料类型编码/名称" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增信息</el-button>
      </div>
      <el-table
          :data="tbMaterialTypeList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="erpCode"
            label="erp编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="typeCode"
            label="物料类型编码"
            align="center">
        </el-table-column>
        <el-table-column
            prop="typeName"
            label="物料类型名称"
            align="center">
        </el-table-column>
        <el-table-column
            prop="parentTypeCode"
            label="上级分类编码"
            align="center">
          <template #default="scope">
            {{ scope.row.parentTypeCode ? scope.row.parentTypeCode : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="parentAtypeName"
            label="上级分类名称"
            align="center">
          <template #default="scope">
            {{ scope.row.parentAtypeName ? scope.row.parentAtypeName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="120"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               :title="title"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbMaterialTypeForm" :rules="rules" label-position="top" ref="tbMaterialTypeForm">
          <el-form-item label="erp编码" prop="erpCode">
            <el-input v-model="tbMaterialTypeForm.erpCode" placeholder="请填写erp编码" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="物料类型编码" prop="typeCode">
            <el-input v-model="tbMaterialTypeForm.typeCode" placeholder="请填写物料类型编码" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="物料类型名称" prop="typeName">
            <el-input v-model="tbMaterialTypeForm.typeName" placeholder="请填写物料类型名称" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="上级分类编码" prop="parentId">
            <el-select v-model="tbMaterialTypeForm.parentId" placeholder="请选择物料类型" size="mini" clearable
                       @change="searchChange">
              <el-option v-for="(item,index) in typeList" :key="index" :label="item.typeCode"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级分类名称" prop="parentAtypeName">
            <el-input v-model="tbMaterialTypeForm.parentAtypeName" placeholder="请填写上级分类名称" clearable type="text"
                      size="mini" maxlength="20" disabled></el-input>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbMaterialTypeForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "TbMaterialTypePage",
  data() {
    const blurText = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('物料类型编码不能为空'))
      } else {
        if (this.rowInfo) {
          if (this.rowInfo.typeCode == value) {
            callback()
          }
        } else if (!/^[A-Za-z0-9]+$/.test(value)) {
          callback(new Error('物料类型编码格式错误'))
        }
        this.postFormRequest("/sys/tb-material-type/exists", {typeCode: value}).then(data => {
          if (data.data) {
            callback(new Error('物料类型编码存在'))
          } else {
            callback()
          }
        });
      }
    }
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      tbMaterialTypeList: [],
      showDrawer: false,
      rowInfo:null,
      title: '',
      tbMaterialTypeForm: {
        id: '',
        erpCode: '',
        typeCode: '',
        typeName: '',
        parentId: '',
        parentAtypeName: '',
      },
      selectForm: {
        typeId: '',
      },
      typeList:[],
      loading: false,
      drawerAction: 'add',
      rules: {
        erpCode: [
          {
            required: true,
            message: '请输入erp关联Code',
            trigger: 'blur'
          }
        ],
        typeCode: [
          {
            required: true,
            message: '请输入物料类型编码',
            trigger: 'blur'
          },
          {validator: blurText, trigger: 'blur'}
        ],
        typeName: [
          {
            required: true,
            message: '请输入物料类型名称',
            trigger: 'blur'
          }
        ],
      }
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getSpecType()
  },
  methods: {
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //查询所有物料类型
    getSpecType() {
      this.postFormRequest("/sys/tb-material-type/selectTbMaterialType").then(res => {
        if (res.success) {
          this.typeList = res.data;
        }
      }).catch(() => {
      })
    },
    //获取物料名字
    searchChange(value) {
      this.tbMaterialTypeForm.parentAtypeName = [];
      this.typeList.map(e => {//遍历数据
        if (e.id === value) {
          this.tbMaterialTypeForm.parentAtypeName = e.typeName
        }
      })
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/sys/tb-material-type/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      if (action == 'add') {
        this.title = '新增信息'
        this.drawerAction = 'add'
      } else {
        this.title = '修改信息'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.rowInfo = row;
        this.tbMaterialTypeForm['id'] = row['id'];
        this.tbMaterialTypeForm['erpCode'] = row['erpCode'];
        this.tbMaterialTypeForm['typeCode'] = row['typeCode'];
        this.tbMaterialTypeForm['typeName'] = row['typeName'];
        this.tbMaterialTypeForm['parentId'] = row['parentId'];
        this.tbMaterialTypeForm['parentAtypeName'] = row['parentAtypeName'];
      } else {
        this.tbMaterialTypeForm = {
          id: '',
          erpCode: '',
          typeCode: '',
          typeName: '',
          parentId: '',
          parentAtypeName: '',
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/sys/tb-material-type/save", this.tbMaterialTypeForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.tbMaterialTypeForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/sys/tb-material-type/updateById", this.tbMaterialTypeForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.tbMaterialTypeForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.tbMaterialTypeForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/sys/tb-material-type/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbMaterialTypeList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["tbMaterialTypeForm"] != undefined) {
        this.$refs["tbMaterialTypeForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

