<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
            <el-form-item class="row" label="现库存地址：">
              <el-select v-model="selectForm.newStockId" placeholder="请选择现库存地址" size="mini" @change="clearChange"
                         clearable>
                <el-option v-for="(item,index) in addressList" :key="index" :label="item.stockName"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="row" label="物料名称规格：">
              <el-select v-model="selectForm.productId" placeholder="物料名称规格" size="mini" @change="clearChange"
                         clearable>
                <el-option v-for="(item,index) in tbProductInfos" :key="index" :label="item.cinvName+' '+item.cinvStd"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="row" label="操作时间：" prop="startTime">
              <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.startTime"
                              clearable :editable="false" placeholder="请选择开始时间" @change="clearChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="~" prop="endTime">
              <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.endTime"
                              clearable :editable="false" placeholder="请选择结束时间" @change="clearChange">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
            </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-table
          :data="tbSteelGradeList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
                prop="materialName"
                label="物料名称"
                align="center">
        </el-table-column>
        <el-table-column
                prop="cinvStd"
                label="物料编码"
                align="center">
        </el-table-column>
        <el-table-column
                prop="specValue"
                label="规格"
                align="center"
                width="120">
        </el-table-column>
        <el-table-column
            prop="batchCode"
            label="批号"
            align="center"
            width="130">
        </el-table-column>
        <el-table-column
            prop="bundleCode"
            label="捆号"
            align="center"
            width="150">
        </el-table-column>
        <el-table-column
            prop="cncName"
            label="加工中心"
            align="center"
            width="150">
        </el-table-column>
        <el-table-column
            prop="oldStockName"
            label="原库存地址"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="oldPileName"
            label="原垛位"
            align="center">
        </el-table-column>
        <el-table-column
            prop="newStockName"
            label="现库存地址"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="newPileName"
            label="现垛位"
            align="center">
        </el-table-column>
        <el-table-column
            prop="creatrEmpName"
            label="操作员"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.creatrEmpName ? scope.row.creatrEmpName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="操作时间"
            align="center"
            width="110">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReverseRecord",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        newStockId: '',
        categoryId: '',
        productName: '',
        productId: '',
        startTime: '',
        endTime: ''
      },
        tbProductInfos: [],
      tbSteelGradeList: [],
      addressList: [],
      categoryList: []
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getSelectList();
    this. materialCode();
  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
      //查询编码名称和规格
      materialCode() {
          this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
              if (res.success) {
                  this.tbProductInfos = res.data;
              }
          })
      },
    //获取下拉列表
    getSelectList() {
      this.postFormRequest("/bus/tb-reverse-warehouse/inventoryCategory")
          .then(res => {
            if (res.success) {
              this.addressList = res.data.tbStockAddr;
              this.categoryList = res.data.tbCategoryInfos;
            }
          })
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.postFormRequest("/bus/tb-reverse-warehouse/listByPage", {
        current: currPage,
        size: pageSize,
        newStockId: this.selectForm.newStockId,
          productId: this.selectForm.productId,
        /*categoryId: this.selectForm.categoryId,
        productName: this.selectForm.productName,*/
        startTime: this.selectForm.startTime ? this.dateYMDFormat(this.selectForm.startTime) : '',
        endTime: this.selectForm.endTime ? this.dateYMDFormat(this.selectForm.endTime) : '',

      }).then(res => {
        if (res.success) {
          this.tbSteelGradeList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
}
</script>

<style scoped>
.elp {
  text-align: right;
}


</style>

