<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="所属部门:">
          <el-select v-model="selectForm.departmentId" placeholder="请选择所属部门" size="mini" clearable
          @change="clearChange">
            <el-option v-for="(item,index) in departmentList" :key="index" :label="item.departmentName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="员工姓名:">
          <el-input v-model="selectForm.empName" placeholder="请填写员工姓名" clearable type="text"
                    size="mini" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="操作内容:">
          <el-input v-model="selectForm.operation" placeholder="请填写操作内容" clearable type="text"
                    size="mini" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item label="操作时间：" prop="createTime">
          <el-date-picker type="date"  size="mini" v-model="selectForm.createTime"
                          clearable :editable="false" placeholder="请选择生成开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-table
          :data="tbSystemList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            fixed="left"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="empName"
            label="员工姓名"
            align="center">
        </el-table-column>
        <el-table-column
            prop="departmentName"
            label="员工所属部门"
            align="center">
        </el-table-column>
        <el-table-column
            prop="operation"
            label="操作内容"
            align="center">
        </el-table-column>
        <el-table-column
            prop="reqUrl"
            label="请求URL"
            align="center"
            width="220">
        </el-table-column>
        <el-table-column
            prop="reqParam"
            label="请求参数"
            align="center"
            :show-overflow-tooltip='true'>
          <template #default="scope">
            {{scope.row.reqParam}}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="操作时间"
            align="center">
          <template #default="scope">
            {{scope.row.createTime ? dateYMDhmsFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="ipAddr"
            label="请求ip地址"
            align="center">
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "systemLog",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {
        departmentName: '',
        name: '',
        content: '',
          createTime: '',
      },
      tbSystemList: [],
      departmentList:[]
    }
  },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getdepartment();
  },
  methods: {
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    //获取部门
    getdepartment() {
      this.postFormRequest("/sys/tb-department/getDepartmentTree").then(res => {
        if (res.success) {
          this.departmentList = res.data;
        }
      })
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
            if (key=='createTime') {
                requestForm[key] = this.selectForm[key] ? this.dateYMDhmsFormat(this.selectForm[key]) : ''
            }else {
                requestForm[key] = this.selectForm[key]
            }

        }
      }
      this.postFormRequest("/bus/tb-sys-log/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbSystemList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
}
</script>

<style scoped>
.elp {
  text-align: right;
}
/* table中的文字超过两行隐藏多余文本 */
.el-tooltip__popper{
  max-width:20%;
}
.el-tooltip__popper,.el-tooltip__popper.is-dark{
  background:rgb(48, 65, 86) !important;
  color: #fff !important;
  line-height: 24px;
}

</style>

