<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="样品批号：">
          <el-input v-model="selectForm.batchCode" placeholder="请输入样品批号" size="mini" type="text"></el-input>
        </el-form-item>
        <el-form-item class="row" label="样品编码：">
          <el-input v-model="selectForm.sampleCode" placeholder="请输入样品编码" size="mini" type="text"></el-input>
        </el-form-item>
        <el-form-item class="row" label="样品类型：">
          <el-input v-model="selectForm.sampleType" placeholder="请输入样品类型" size="mini" type="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" size="mini" @click="readData">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="wuliSave">保存</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" size="mini" @click="downloadExcel">下载模板</el-button>
        </el-form-item>
        <el-form-item>
          <el-upload
              class="upload-demo"
              ref="upload"
              :action="'/inspect/upload'"
              :http-request="handleRequest"
              :multiple="false"
              :auto-upload="true"
              :show-file-list="false"
              accept=".xls,.xlsx"
              :limit="1">
            <el-button class="btn" trigger size="mini">导入数据</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-form :model="tableList" :rules="rules" ref="tableList">
        <el-table
            class="dynamic-table-main"
            :data="tableList.details"
            stripe
            border
            max-height="550px"
            :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
            :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
          <el-table-column
              type="index"
              label="序号"
              align="center"
              width="120">
            <template #default="scope">
              <label class="lable">{{ scope.$index + 1 }}</label>
              <el-image class="icon" :src="require('../assets/image/icon-plus.png')"
                        @click="AddListRow(scope.$index)"></el-image>
              <el-image class="icon"
                        v-if="tableList.details && tableList.details.length>1"
                        :src="require('../assets/image/icon-delete.png')"
                        @click="handleDelete(scope.row.id,scope.$index)"></el-image>
            </template>
          </el-table-column>
          <el-table-column
              prop="projectCategory"
              align="center"
              label="检测项目">
            <template v-slot="scope">
              <el-form-item :prop="'details[' + scope.$index + '].projectCategory'"
                            :rules='rules.projectCategory'>
                <el-select v-model="scope.row.projectCategory" @click="selectClick(scope.$index)" @change="changeSearch"
                           clearable>
                  <el-option v-for="(item,index) in objectList" :key="index" :value="item.id"
                             :label="item.projectCategory"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="projectName"
              label="检测项目名称">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].projectName'">
                <el-input v-model.trim="scope.row.projectName" disabled
                          placeholder="请输入检测项目名称" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="projectUnit"
              label="检测项计量单位">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].projectUnit'">
                <el-input v-model.trim="scope.row.projectUnit" disabled
                          placeholder="请输入检测项计量单位" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="itemVal"
              label="检验值">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].itemVal'"
                            :rules='rules.itemVal'>
                <el-input v-model.trim="scope.row.itemVal"
                          placeholder="请输入检验值" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="itemVal2"
              label="二次检验值">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].itemVal2'">
                <el-input v-model.trim="scope.row.itemVal2"
                          placeholder="请输入二次检验值" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="itemVal3"
              label="三次检验值">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].itemVal3'">
                <el-input v-model.trim="scope.row.itemVal3"
                          placeholder="请输入三次检验值" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="modifyVal"
              label="修约值">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].modifyVal'"
                            :rules='rules.modifyVal'>
                <el-input v-model.trim="scope.row.modifyVal"
                          placeholder="请输入修约值" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column
              prop="qualified"
              label="是否合格">
            <template #default="scope">
              <el-form-item :prop="'details[' + scope.$index + '].qualified'"
                            :rules='rules.qualified'>
                <el-select v-model="scope.row.qualified" placeholder="请选择是否合格">
                  <el-option v-for="(item,index) in qualified" :key="index" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>

<script>
import {qualified} from '../common/baseData'; //项目名称
const _qualified = qualified;
export default {
  name: "QualityInspectionReport",
  data() {
    return {
      selectForm: {
        sampleCode: '',
        batchCode: '',
        sampleType: '',
      },
      tableList: {
        details: []
      },
      tableDate: {
        projectCategory: '',
        projectName: '',
        projectUnit: '',
        itemVal: '',
        itemVal2: '',
        itemVal3: '',
        modifyVal: '',
        qualified: ''
      },
      currIndex: null,
      //检测项目
      objectList: [],
      rules: {
        projectCategory: [{required: true, message: '请输入检测项目编码', trigger: 'blur'}],
        itemVal: [{required: true, message: '请输入检验值', trigger: 'blur'}],
        modifyVal: [{required: true, message: '请输入修约值', trigger: 'blur'}],
        qualified: [{required: true, message: '请选择是否合格', trigger: 'change'}]
      },
      qualified: _qualified
    }
  },
  mounted() {
    this.getTableList();
    this.getObject();
  },
  methods: {
    //查询
    readData() {
      if (!this.selectForm.sampleCode && !this.selectForm.batchCode) {
        this.$message.info('请先输入样品编码和批号');
        return
      }
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postRequest("/inspect/detail", requestForm).then(res => {
        if (res.success) {
          if (!res.data || res.data.length == 0) {
            return;
          }
          if (res.data.data.details && res.data.data.details.length > 0) {
            this.tableList.details = res.data.data.details;
          }
          this.selectForm.sampleCode = res.data.data.sampleCode
          this.selectForm.sampleType = res.data.data.sampleType
        }
      })
    },
    //下载模板
    downloadExcel() {
      this.getDownload("/inspect/dowoload").then(res => {
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', '质检报告模板.xlsx')
        a.click()
      })
    },
    //数据导入
    handleRequest(data) {
      let param = new FormData(); //创建form对象
      param.append('file', data.file);//通过append向form对象添加数据
      this.postImportExcel("/inspect/upload", param).then(res => {
        if (res.success) {
          this.$message.success('导入成功')
          this.$refs.upload.clearFiles();
        } else {
          this.$refs.upload.clearFiles();
        }
      })
    },
    //查询检测项
    getObject() {
      this.getRequest("/inspection-item/list").then(res => {
        if (res.success) {
          this.objectList = res.data;
        }
      })
    },
    //编码带出名称
    selectClick(index) {
      this.currIndex = index;
    },
    changeSearch(value) {
      this.tableList.details[this.currIndex].projectName = [];
      this.tableList.details[this.currIndex].projectUnit = [];
      this.objectList.map(e => {//遍历数据
        if (e.id === value) {
          this.tableList.details[this.currIndex].projectName = e.projectName
          this.tableList.details[this.currIndex].projectUnit = e.projectUnit
          this.tableList.details[this.currIndex].projectId = e.id
        }
      })
    },
    // 分页
    getTableList() {
      this.tableList = {details: []};
      this.tableList.details.push({
        projectCategory: '',
        projectName: '',
        projectUnit: '',
        itemVal: '',
        itemVal2: '',
        itemVal3: '',
        modifyVal: '',
        qualified: ''
      });
    },
    //新加一行
    AddListRow(index) {
      this.tableDate = {
        projectCategory: '',
        projectName: '',
        projectUnit: '',
        itemVal: '',
        itemVal2: '',
        itemVal3: '',
        modifyVal: '',
        qualified: ''
      }
      this.tableList.details.splice(index + 1, 0, this.tableDate)
    },
    //删除当行
    handleDelete(id, index) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
              this.getRequest("/inspect/deleted/?id=" + id).then(res => {
                if (res.success) {
                  this.tableList.details.splice(index, 1);
                  this.$message.success("删除成功");
                }
              })
            }
        ).catch(() => {
        })
      } else {
        this.tableList.details.splice(index, 1);
      }
    },
//保存
    wuliSave() {
      if (!this.selectForm.sampleCode || !this.selectForm.batchCode || !this.selectForm.sampleType) {
        this.$message.info('请先输入样品编码、批号和类型');
        return
      }
      let details = [];
      this.tableList.details.forEach(item => {
        details.push({
          testType: 0,
          projectId: item.projectId,
          itemVal: item.itemVal,
          itemVal2: item.itemVal2,
          itemVal3: item.itemVal3,
          modifyVal: item.modifyVal,
          qualified: item.qualified
        })
      })
      let param = {
        sampleCode: this.selectForm.sampleCode,
        sampleType: this.selectForm.sampleType,
        batchCode: this.selectForm.batchCode,
        details: details,
      };
      this.postRequest("/inspect/add", param).then(res => {
        if (res.success) {
          this.$message.success('保存成功')
          this.tableList = {details: []};
          this.tableList.details.push({
            projectCategory: '',
            projectName: '',
            projectUnit: '',
            itemVal: '',
            itemVal2: '',
            itemVal3: '',
            modifyVal: '',
            qualified: ''
          });
          this.selectForm = {};
        } else {
          this.$message.success('保存失败')
          return false;
        }
      })
    }
    ,
  },
}
</script>

<style lang="scss" scoped>
.main {
  margin-bottom: 10px;
}

///deep/ .el-table__body-wrapper {
//  height: 200px;
//  overflow-y: scroll;
//}

::v-deep .dynamic-table-main {
  .row-table-header {
    padding: 0.625rem;
    box-sizing: border-box;
    background: rgb(238, 241, 246);
    color: rgb(96, 98, 102);
    font-weight: bold;
  }

  .row-table-row {
    padding: 0.625rem;
    box-sizing: border-box;
  }

  .row-table-row:nth-child(2n) {
    background: #eef1f6;
    color: #606266;
  }

  /*.table-row*/
  .cell {
    overflow: visible;
    display: flex;
    justify-content: center;

    .el-form-item {
      margin-bottom: 8px;
    }

    .lable {
      width: 20px;
      display: inline-block;
      text-align: center;
    }

    .icon {
      color: #66CC83;
      font-size: 18px;
      width: 1.25rem;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .no-empty {
    .icon {
      color: #66CC83;
      font-size: 18px;
      width: 1.25rem;
      height: 1.25rem;
      cursor: pointer;
    }

    .txt {
      font-size: 16px;
      padding-left: 5px;
    }
  }
}
</style>

