<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="样品批号:">
          <el-input v-model="selectForm.batchCode" placeholder="请输入样品批号" size="mini" type="text"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button :disabled="saveBtnDisable" class="btn" type="primary" size="mini" @click="wuliSave">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <el-form :model="tableList" ref="tableList">
        <el-table
            class="dynamic-table-main"
            :data="tableList.details"
            border
            :span-method="objectSpanMethod"
            max-height="550px"
            :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
            :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
          <el-table-column
              prop="batchCode"
              fixed="left"
              align="center"
              label="批号"
              width="120px">
            <template v-slot="scope">
              <el-form-item :prop="'details[' + scope.$index + '].batchCode'">
                <el-input v-model.trim="scope.row.batchCode"
                          placeholder="请输入批号" type="text"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="化学成分" align="center">
            <el-table-column
                align="center"
                prop="itemVal"
                label="C"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['c'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="SI"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['si'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="Mn"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['mn'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="P"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['p'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="S"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['s'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="V"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['v'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="Ceq"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['ceq'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="力学成分" align="center">
            <el-table-column
                align="center"
                prop="itemVal"
                label="Rel"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['rel'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="Rm"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['rm'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="Rºm/RºeL"
                width="120px">
              <template #default="scope">
                {{
                  (scope.row['rel'].itemVal && scope.row['rm'].itemVal) ? (scope.row['r°m／r°el'].itemVal = (scope.row['rm'].itemVal / scope.row['rel'].itemVal).toFixed(2)) : ''
                }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="RºeL/ReL"
                width="120px">
              <template #default="scope">
                {{
                  (scope.row['rel'].itemVal) ? scope.row['r°el／rel'].itemVal = (scope.row['rel'].itemVal / 400).toFixed(2) : ''
                }}
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="A"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['a'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="Agt"
                width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['agt'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column label="工艺性能" align="center">
            <el-table-column
                align="center"
                prop="itemVal"
                label="正向弯曲 正弯180º D=（）d"
                width="90px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['crat1'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="itemVal"
                label="反向弯曲 正弯90° 反弯20° D=（）d"
                width="80px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['crat2'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
              align="center"
              prop="qualified"
              label="重量偏差"
              width="120px">
              <template #default="scope">
                <el-form-item>
                  <el-input v-model.trim="scope.row['weightoffset'].itemVal"
                            placeholder="请输入检测值" type="text"></el-input>
                </el-form-item>
              </template>
          </el-table-column>
          <el-table-column
              align="center"
              prop="qualified"
              label="金相组织"
              width="120px">合格
          </el-table-column>
          <el-table-column
              align="center"
              prop="qualified"
              label="表面质量"
              width="120px">合格
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>

<script>
import {qualified} from '../common/baseData'; //项目名称
const _qualified = qualified;
export default {
  name: "InputReport",
  data() {
    return {
      selectForm: {
        batchCode: ''
      },
      saveBtnDisable: false,// 控制保存按钮是否可用
      tableList: {
        details: []
      },
      tableDate: {
        batchCode: '',
        itemVal: '',
        qualified: ''
      },
      qualified: _qualified
    }
  },
  mounted() {
    this.getTableList();
  },
  methods: {
    //合并表格
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (rowIndex == 0) {
        if (columnIndex < 8 || columnIndex == 14 || columnIndex == 15|| columnIndex == 16) {
          return {
            rowspan: 3,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
      } else {
        if (columnIndex < 8 || columnIndex == 14 || columnIndex == 15|| columnIndex == 16) {
          return {
            rowspan: 0,
            colspan: 0,
          }
        } else {
          return {
            rowspan: 1,
            colspan: 1,
          }
        }
      }
    },
    //保存
    wuliSave() {
      if (!this.tableList.details[0].batchCode) {
        this.$message.warning("批号必须填写！");
        return;
      }
      let details = [];
      let set = new Set(["rel", "rm", "r°m／r°el", "r°el／rel", "a", "agt", "weightoffset", "org", "surfacequanlity"]);
      let detail = this.tableList.details[0];
      Object.keys(detail).forEach(key => {
        let val = detail[key];
        if (typeof val == "object") {
          let obj = {
            projectId: val.id,
            itemVal: val.itemVal
          };
          if (set.has(key)) {
            obj["itemVal2"] = this.tableList.details[1][key].itemVal;
            obj["itemVal3"] = this.tableList.details[2][key].itemVal;
          }
          details.push(obj)
        }
      })
      let param = {
        batchCode: detail.batchCode,
        details: details,
      };
      this.saveBtnDisable = true;
        this.postRequest("/inspect/add", param).then(res => {
          if (res.success) {
            this.$message.success('保存成功');
            this.getTableList();
            this.$forceUpdate();
          } else {
            this.$message.success('保存失败');
            return false;
          }
          this.saveBtnDisable = false;
        })

    },
    // 分页
    getTableList() {
      this.tableList = {details: []};
      let jsonStr = '{"batchCode":"","c": {"id": 1,"itemVal":""},"si": {"id": 2,"itemVal":""},' +
          '"s": {"id": 5,"itemVal":""},"p": {"id": 4,"itemVal":""},' +
          '"mn": {"id": 3,"itemVal":""},"ceq": {"id": 6,"itemVal":""},' +
          '"rel": {"id": 7,"itemVal":""},"rm": {"id": 8,"itemVal":""},' +
          '"r°m／r°el": {"id": 9,"itemVal":""},' +
          '"r°el／rel": {"id": 10,"itemVal":""},' +
          '"a": {"id": 11,"itemVal":""},' +
          '"agt": {"id": 12,"itemVal":""},' +
          '"crat1": {"id": 13,"itemVal":""},' + //工艺性能1
          '"crat2": {"id": 14,"itemVal":""},' + //工艺性能2
          '"weightoffset": {"id": 15,"itemVal":""},' + //重量偏差
          '"org": {"id": 16,"itemVal":""},' + //金相组织
          '"surfacequanlity": {"id": 17,"itemVal":""},' + //表面质量
          '"v": {"id": 18,"itemVal":""}' + //钒
          '}';
      this.tableList.details.push(JSON.parse(jsonStr));
      this.tableList.details.push(JSON.parse(jsonStr));
      this.tableList.details.push(JSON.parse(jsonStr));
    },
    //查询
    readData() {
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postRequest("/inspect/detail", requestForm).then(res => {
        if (res.success) {
          if (!res.data || res.data.length == 0) {
            return;
          }
          let jsonStr = '{"batchCode":"' + (res.data.data.batchCode ? res.data.data.batchCode : '') + '","c": {"id": 1,"itemVal":""},"si": {"id": 2,"itemVal":""},"s": {"id": 5,"itemVal":""},"p": {"id": 4,"itemVal":""},' +
              '"mn": {"id": 3,"itemVal":""},"ceq": {"id": 6,"itemVal":""},' +
              '"rel": {"id": 7,"itemVal":""},"rm": {"id": 8,"itemVal":""},' +
              '"r°m／r°el": {"id": 9,"itemVal":""},' +
              '"r°el／rel": {"id": 10,"itemVal":""},' +
              '"a": {"id": 11,"itemVal":""},' +
              '"agt": {"id": 12,"itemVal":""},' +
              '"crat1": {"id": 13,"itemVal":""},' + //工艺性能1
              '"crat2": {"id": 14,"itemVal":""},' + //工艺性能2
              '"weightoffset": {"id": 15,"itemVal":""},' + //重量偏差
              '"org": {"id": 16,"itemVal":""},' + //金相组织
              '"surfacequanlity": {"id": 17,"itemVal":""},' + //表面质量
              '"v": {"id": 18,"itemVal":""}' + //钒
              '}';
          let details = [];
          details.push(JSON.parse(jsonStr));
          details.push(JSON.parse(jsonStr));
          details.push(JSON.parse(jsonStr));
          let set = new Set(["rel", "rm", "r°m／r°el", "r°el／rel", "a", "agt", "crat1", "crat2", "weightoffset", "org", "surfacequanlity"]);
          if (res.data.data.details && res.data.data.details.length > 0) {
            res.data.data.details.forEach(item => {
              let projectCategory = item.projectCategory.toLowerCase();
              if (details[0][projectCategory]) {
                if (set.has(projectCategory)) {
                  details[1][projectCategory].itemVal = item.itemVal2;
                  details[2][projectCategory].itemVal = item.itemVal3;
                }
                details[0][projectCategory].id = item.projectId;
                details[0][projectCategory].itemVal = item.itemVal;
              } else {
                let keys = Object.keys(details[0]);
                keys.forEach(key => {
                  if (typeof details[0][key] == "object") {
                    if (details[0][key].id == item.projectId) {
                      details[0][key].itemVal = item.itemVal;
                      details[1][key].itemVal = item.itemVal2;
                      details[2][key].itemVal = item.itemVal3;
                    }
                  }
                })
              }
            })
          }
          this.tableList.details = details;
        }
      })
    },
  }
}
</script>

<style scoped>
/deep/ .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
  margin-bottom: 0;
}
</style>
