<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="员工姓名/工号：">
          <el-input v-model="selectForm.empName" placeholder="请输入员工姓名/工号" size="mini"
                    clearable type="text" @change="clearChange"></el-input>
        </el-form-item>
        <el-form-item class="row" label="所属部门：">
          <el-select v-model="selectForm.departmentId" @change="clearChange" placeholder="请选择所属部门" size="mini" clearable>
            <el-option v-for="(item,index) in departmentList" :key="index" :label="item.departmentName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="所属岗位：">
          <el-select v-model="selectForm.positionId" @change="clearChange" placeholder="请选择所属岗位" size="mini" clearable>
            <el-option v-for="(item,index) in positionList" :key="index" :label="item.positionName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增员工</el-button>
      </div>
      <el-table
          :data="tbEmployeeList"
          stripe
          border
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            fixed="left"
            label="序号"
            :index="table_index"
            width="80">
        </el-table-column>
        <el-table-column
            prop="empName"
            label="员工姓名"
            align="center">
        </el-table-column>
        <el-table-column
            prop="empNo"
            label="员工工号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="empTel"
            label="员工电话"
            align="center">
        </el-table-column>
        <el-table-column
            prop="departmentName"
            label="所属部门"
            align="center">
          <template #default="scope">
            {{ scope.row.departmentName ? scope.row.departmentName : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="positionName"
            label="所在岗位"
            align="center">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="员工登录账号"
            align="center">
        </el-table-column>
        <el-table-column
            prop="status"
            label="员工状态"
            align="center">
          <template #default="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="0"
                       :inactive-value="1"
                       @change=changeState($event,scope.row)>
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="180"
            fixed="right"
            label="操作">
          <template v-slot="scope">
            <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
            <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
            <el-button @click="resetPassword(scope.row.id)" type="text" size="small">重置密码</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>

    <el-drawer custom-class="drawerMain"
               v-model="showDrawer"
               v-if="showDrawer"
               :title="title"
               size="520px"
               direction="rtl">
      <div class="drawer_content">
        <el-form :model="tbEmployeeForm" :rules="rules" label-position="top" ref="tbEmployeeForm">
          <el-form-item label="员工姓名" prop="empName">
            <el-input v-model="tbEmployeeForm.empName" placeholder="请填写员工姓名" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="员工编号" prop="empNo">
            <el-input v-model="tbEmployeeForm.empNo" placeholder="请填写员工编号" maxlength="20" clearable type="text" size="mini"></el-input>
          </el-form-item>
          <el-form-item label="员工电话" prop="empTel">
            <el-input v-model="tbEmployeeForm.empTel" maxlength="11" placeholder="请填写员工电话" clearable type="text"
                      size="mini"></el-input>
          </el-form-item>
          <el-form-item label="员工登录账号" prop="userName">
            <el-input v-model="tbEmployeeForm.userName" placeholder="请填写员工登录账号" clearable type="text"
                      size="mini" maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="departmentId">
            <el-select v-model="tbEmployeeForm.departmentId" placeholder="请选择所属部门" size="mini" clearable>
              <el-option v-for="(item,index) in departmentList" :key="index" :label="item.departmentName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所在岗位" prop="positionId">
            <el-select v-model="tbEmployeeForm.positionId" placeholder="请选择所在岗位" size="mini" clearable>
              <el-option v-for="(item,index) in positionList" :key="index" :label="item.positionName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="员工状态" prop="status">
            <el-switch v-model="tbEmployeeForm.status"
                       :active-value=0
                       :inactive-value=1>
            </el-switch>
          </el-form-item>
        </el-form>
        <div class="drawer-footer-height"></div>
        <div class="dialog-footer">
          <el-button @click="cancelForm" size="mini">取 消</el-button>
          <el-button type="primary" size="mini" @click="submitBtnClick('tbEmployeeForm',drawerAction)"
                     :loading="loading">{{ loading ? '提交中...' : '确 定' }}
          </el-button>
        </div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
    import localStore from "../utils/storage"

    export default {
        name: "TbEmployeePage",
        data() {
            const checkPhone = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('手机号不能为空'))
                } else if (!/^1[3|4|5|7|8|9][0-9]{9}$/.test(value)) {
                    callback(new Error('手机号格式错误'))
                }


                if (this.rowInfo) {
                    if (this.rowInfo.empTel == value) {
                        callback()
                    }
                }
                this.postFormRequest("/sys/tb-employee/empTel", {empTel: value}).then(data => {
                    if (data.data) {
                        callback(new Error('手机号存在'))
                        // this.tblEmployeeForm.no='';
                    } else {
                        callback()
                    }
                })

            };
            const blurText = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('工号不能为空'))
                } else {
                    if (this.rowInfo) {
                        if (this.rowInfo.empNo == value) {
                            callback()
                        }
                    } else if (!/^[A-Za-z0-9]+$/.test(value)) {
                        callback(new Error('工号格式错误'))
                    }
                    this.postFormRequest("/sys/tb-employee/exists", {empNo: value}).then(data => {
                        if (data.data) {
                            callback(new Error('工号存在'))
                        } else {
                            callback()
                        }
                    })
                }
            };
            const checkUser = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('登录账号不能为空'))
                } else if (!/^[A-Za-z0-9]+$/.test(value)) {
                    callback(new Error('账号格式错误'))
                } else {
                    callback()
                }
            }
            return {
                userInfo: localStore.get('warehouse_userInfo'),
                total: 2,
                currPage: 1,
                pageSize: 10,
                tbEmployeeList: [],
                showDrawer: false,
                title: '',
                rowInfo: null,
                tbEmployeeForm: {
                    id: '',
                    empName: '',
                    empNo: '',
                    empTel: '',
                    userName: '',
                    departmentId: '',
                    positionId: '',
                    status: 0,
                },
                selectForm: {
                    empName: '',
                    empNo: '',
                    departmentId: '',
                    positionId: '',
                },
                departmentList: [],
                positionList: [],
                loading: false,
                drawerAction: 'add',
                rules: {
                    empName: [
                        {
                            required: true,
                            message: '请输入员工姓名',
                            trigger: 'blur'
                        }
                    ],
                    empNo: [
                        {
                            required: true,
                            message: '请输入员工编号',
                            trigger: 'blur'
                        },
                        {validator: blurText, trigger: 'blur'}
                    ],
                    empTel: [
                        {
                            required: true,
                            message: '请输入员工电话',
                            trigger: 'blur'
                        },
                        {validator: checkPhone, trigger: 'blur'}
                    ],
                    userName: [
                        {
                            required: true,
                            message: '请输入员工登录账号',
                            trigger: 'blur'
                        },
                        {validator: checkUser, trigger: 'blur'}
                    ],
                    departmentId: [
                        {
                            required: true,
                            message: '请输入所属部门',
                            trigger: 'change'
                        }
                    ],
                    positionId: [
                        {
                            required: true,
                            message: '请输入所在岗位',
                            trigger: 'change'
                        }
                    ],
                }
            }
        },

  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.getDepartmentTree();
    this.getPositionListTree();
  },
  methods: {
    //查询
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
      //查询部门
      getDepartmentTree(){
          this.postFormRequest("/sys/tb-department/getDepartmentTree").then(res=>{
              if (res.success) {
                  this.departmentList= res.data
              }
          })
      },
      //查询岗位
      getPositionListTree(){
          this.postFormRequest("/sys/tb-position/getPositionListTree").then(res=>{
              if (res.success) {
                  this.positionList= res.data
              }
          })
      },
      exists(){
          this.postFormRequest("/sys/tb-position/exists").then(res=>{
              if (res.success) {
                  this.positionList= res.data
              }
          })
      },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/sys/tb-employee/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success('删除成功');
              this.currentPageChanged(this.currPage, this.pageSize);
              this.getDepartmentTree();
            }
          })
        }).catch(() => {
        })
      }
    },
    //重置密码
    resetPassword(id) {
      this.$confirm('确认要重置此员工密码吗？', '重置密码确认', {
        conFirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postFormRequest("/sys/tb-employee/resetPassword",{
            id:id
        }).then(data => {
          if (data.success) {
            this.$message.info('重置成功')
          } else {
            this.$message.info('重置失败')
          }
        }).catch(() => {
          this.$message.info('重置失败')
        })
      }).catch(() => {
      });
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
        this.rowInfo = null;
      if (action == 'add') {
        this.title = '新增员工'
        this.drawerAction = 'add'
      } else {
        this.title = '修改员工'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
          this.rowInfo = row;
        this.tbEmployeeForm['id'] = row['id'];
        this.tbEmployeeForm['empName'] = row['empName'];
        this.tbEmployeeForm['empNo'] = row['empNo'];
        this.tbEmployeeForm['empTel'] = row['empTel'];
        this.tbEmployeeForm['userName'] = row['userName'];
        this.tbEmployeeForm['departmentId'] = row['departmentId'];
        this.tbEmployeeForm['positionId'] = row['positionId'];
        this.tbEmployeeForm['status'] = row['status'];
      } else {
        this.tbEmployeeForm = {
          id: '',
          empName: '',
          empNo: '',
          empTel: '',
          userName: '',
          departmentId: '',
          positionId: '',
          status: 0,
        }
      }
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/sys/tb-employee/save", this.tbEmployeeForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.tbEmployeeForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
                this.getDepartmentTree();
              } else {
                this.loading = false;
                return false;
              }
            })
          } else {
            this.postFormRequest("/sys/tb-employee/updateById", this.tbEmployeeForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.tbEmployeeForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
                this.getDepartmentTree();
              } else {
                this.loading = false;
                return false;
              }
            })
          }
        }
      })
    },
    changeState(data, row) {
      this.postFormRequest("/sys/tb-employee/updateById",
          {
            id: row.id,
            status: row.status
          }).then(data => {
        if (data.success) {
          this.currentPageChanged(this.currPage, this.pageSize);
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.tbEmployeeForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize;
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.getRequest("/sys/tb-employee/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbEmployeeList = res.data.records
          this.total = res.data.total
        }

      })
    },
    // 分页 end
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["tbEmployeeForm"] != undefined) {
        this.$refs["tbEmployeeForm"].resetFields();
      }
    }
  }
}
</script>

<style scoped>
/*抽屉主体样式*/
.drawer_content {
  margin: 10px;
  /*height: 100vh;*/
  height: calc(100% - 200px);
}

.elp {
  text-align: right;
}


</style>

