<template>
    <div style="width: 100%">
        <div class="subMain">
            <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
                        <el-form-item class="row" label="库存地址：">
                            <el-select v-model="selectForm.stockAddrId" placeholder="请选择库存地址" size="mini" clearable
                                       @change="clearChange">
                                <el-option v-for="(item,index) in addressList" :key="index" :label="item.stockName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item class="row" label="物料名称规格：">
                            <el-select v-model="selectForm.productId" placeholder="物料名称规格" size="mini"
                                       @change="clearChange"
                                       clearable>
                                <el-option v-for="(item,index) in tbProductInfos" :key="index"
                                           :label="item.cinvName+' '+item.cinvStd+' '+item.steelGradeName"
                                           :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>


               <!-- <el-form-item class="row" label="生成时间：" prop="startTime">
                    <el-date-picker type="date"  size="mini" v-model="selectForm.startTime"
                                    clearable :editable="false" placeholder="请选择开始时间">
                    </el-date-picker>
                </el-form-item>-->

                        <el-form-item class="row" label="归属日期：" prop="startTime">
                            <el-date-picker type="date"  size="mini" v-model="selectForm.vestingDate"
                                            clearable :editable="false" placeholder="请选择归属日期">
                            </el-date-picker>
                        </el-form-item>
                        <!--<el-form-item label="~" prop="endTime">-->
                            <!--<el-date-picker type="datetime"  size="mini" v-model="selectForm.endTime"-->
                                            <!--clearable :editable="false" placeholder="请选择结束时间">-->
                            <!--</el-date-picker>-->
                        <!--</el-form-item>-->
                <el-form-item class="row" label="班次：     ">
                    <el-select v-model="selectForm.classNameId" placeholder="班次" size="mini"
                               @change="clearChange"
                               clearable>
                        <el-option v-for="(item,index) in classNameIdList" :key="index"
                                   :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                        <el-form-item>
                            <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
                        </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <el-table
                    :data="tbSteelGradeList"
                    stripe
                    border
                    :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                    :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        fixed="left"
                        :index="table_index"
                        width="80">
                </el-table-column>
                <el-table-column
                        prop="batchCode"
                        label="批号"
                        align="center"
                        width="130">

                </el-table-column>
                <el-table-column
                        prop="bundleCode"
                        label="捆号"
                        align="center"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="actWeight"
                        label="实际重量(t)"
                        align="center"
                        width="120">
                    <template #default="scope">
                        {{ scope.row.actWeight ? parseFloat((scope.row.actWeight/1000 ? scope.row.actWeight/1000 : 0)).toFixed(3) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        prop="materialName"
                        label="物料名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="cinvStd"
                        label="物料编码"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="specValue"
                        label="规格"
                        align="center"
                        width="130">
                </el-table-column>
                <el-table-column
                        prop="classNameId"
                        label="班组"
                        align="center"
                >
                    <template #default="scope">
                        {{ scope.row.classNameId ? getFindLable(scope.row.classNameId, classNameIdList) : '-' }}
                    </template>
                </el-table-column>

                <el-table-column
                        prop="cncName"
                        label="加工中心"
                        align="center"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="stockName"
                        label="库存地址"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="stockStatus"
                        label="库存状态"
                        align="center">
                    <template #default="scope">
                        <!--<p v-if="scope.row.stockStatus == 0">待称重</p>-->
                        <p v-if="scope.row.stockStatus == 3">待入库</p>
                        <!--<p v-if="scope.row.stockStatus == 2">已入库</p>-->
                    </template>
                </el-table-column>
                <el-table-column
                        prop="fixedLength"
                        label="是否定尺"
                        align="center">
                    <template #default="scope">
                        <p v-if="scope.row.fixedLength==0">是</p>
                        <p v-if="scope.row.fixedLength==1">否</p>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="qualified"
                        label="是否合格"
                        align="center">
                    <template #default="scope">
                        <p v-if="scope.row.qualified==0">合格</p>
                        <p v-if="scope.row.qualified==1">不合格</p>
                        <p v-if="scope.row.qualified==2">销售合格</p>
                    </template>
                </el-table-column>
                <!--<el-table-column-->
                        <!--prop="stockTime"-->
                        <!--label="生成时间"-->
                        <!--align="center"-->
                        <!--width="170">-->
                    <!--<template #default="scope">-->
                        <!--{{ scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : '-' }}-->
                    <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column
                        prop="vestingDate"
                        label="归属时间"
                        align="center"
                        width="130">
                    <template #default="scope">
                        {{ scope.row.vestingDate ? dateYMDFormat(scope.row.vestingDate) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        width="120"
                        fixed="right"
                        label="操作">
                    <template v-slot="scope">
                        <el-button @click="showDialog(scope.row)" type="text" size="small">改判入库</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="elp"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currPage"
                           :page-sizes="[10,20,30,40]"
                           :page-size="pageSize"
                           :total="total"
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <el-dialog
                custom-class="dialogMain"
                title="改判入库"
                v-model="dialogVisible"
                width="40%">
            <el-form :model="processForm" :rules="rules" label-position="top" ref="processForm">
                <el-form-item label="长度：" prop="length">
                    <el-input v-model="processForm.length" placeholder="请输入长度" clearable type="text"
                              size="mini"></el-input>
                </el-form-item>
                <el-form-item label="是否定尺" prop="fixedLength">
                    <el-select v-model="processForm.fixedLength" placeholder="请选择是否定尺" size="mini" clearable>
                        <el-option v-for="(item,index) in fixedLengthList" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否合格" prop="qualified">
                    <el-select v-model="processForm.qualified" placeholder="请选择是否合格" size="mini" clearable>
                        <el-option v-for="(item,index) in qualifiedLists" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否入库" prop="stockStatus">
                    <el-select v-model="processForm.stockStatus" placeholder="请选择是否合格" size="mini" clearable>
                        <el-option v-for="(item,index) in stockStatusList" :key="index" :label="item.label"
                                   :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="dialog-footer">
                <el-button type="primary" size="mini" @click="submitBtnClick('processForm',drawerAction)">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {fixedLengthList} from '../common/baseData'; //是否定尺
    import {qualifiedLists} from '../common/baseData'; //是否合格
    import {stockStatusList} from '../common/baseData'; //是否入库
    import {classNameIdList} from '../common/baseData'; //班组
    const _fixedLengthList = fixedLengthList;
    const _qualifiedLists = qualifiedLists;
    const _stockStatusList = stockStatusList;
    const _classNameIdList = classNameIdList;
    export default {
        name: "FinishedProductInquiry",
        data() {
            return {
                total: 2,
                currPage: 1,
                pageSize: 10,
                tbProductInfos: [],
                selectForm: {
                    address: '',
                    category: '',
                    productId: '',
                    classNameId: '',
                    startTime: '',
                    endTime: '',
                    vestingDate: ''
                },
                addressList: [],
                categoryList: [],
                tbSteelGradeList: [],
                dialogVisible: false,
                qualifiedLists: _qualifiedLists,
                fixedLengthList: _fixedLengthList,
                stockStatusList: _stockStatusList,
                classNameIdList: _classNameIdList,
                processForm: {
                    id: '',
                    length: '',
                    fixedLength: '',
                    qualified: '',
                    stockStatus: '',
                },
                loading: false,
                drawerAction: 'add',
                rules: {
                    length: [
                        {
                            required: true,
                            message: '请输入长度',
                            trigger: 'blur'
                        }
                    ],
                    fixedLength: [
                        {
                            required: true,
                            message: '请选择是否定尺',
                            trigger: 'change'
                        }
                    ],
                    qualified: [
                        {
                            required: true,
                            message: '请选择是否合格',
                            trigger: 'change'
                        }
                    ],
                    stockStatus: [
                        {
                            required: true,
                            message: '请选择是否合格',
                            trigger: 'change'
                        }
                    ],
                },
            }
        },

        mounted() {
            this.getNowTime();
            this.currentPageChanged(this.currPage, this.pageSize);
            this.getSelectList();
            this.materialCode();
            //默认时间

        },
        methods: {
            getNowTime(){
                let date = new Date()
                let dates = new Date(date.getTime() - 24 * 60 * 60 * 1000)
                let y = date.getFullYear()
                let m = date.getMonth() + 1
                let startTime = date.getDate() -1
                let endTime = date.getDate()
                let s = "07:20:00"
                let start = y + '-' + m + '-' + startTime + ' '+s
                let end = y + '-' + m + '-' + endTime + ' '+s


                // this.selectForm.startTime= start
                // this.selectForm.endTime= end

                this.selectForm.vestingDate= dates
            },
            //清空查询自动加载列表
            readData() {
                this.handleCurrentChange(1);
            },
            //清空查询自动加载列表
            clearChange() {
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            //查询编码名称和规格
            materialCode() {
                this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
                    if (res.success) {
                        this.tbProductInfos = res.data;
                    }
                })
            },
            //获取下拉列表
            getSelectList() {
                this.postFormRequest("/bus/tb-reverse-warehouse/inventoryCategory")
                    .then(res => {
                        if (res.success) {
                            this.addressList = res.data.tbStockAddr;
                            this.categoryList = res.data.tbCategoryInfos;
                        }
                    })
            },
            //展示抽屉的方式
            showDialog(row) {
                this.dialogVisible = true;//展开抽屉
                if (row) {
                    this.processForm['id'] = row['id'];
                    this.processForm['length'] = row['length'];
                    this.processForm['fixedLength'] = row['fixedLength'];
                    this.processForm['qualified'] = row['qualified'];
                    this.processForm['stockStatus'] = row['stockStatus'];
                    this.processForm['bundleCode'] = this.tbSteelGradeList.bundleCode;
                }
            },
            //提交按钮被点击时
            submitBtnClick(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (this.processForm.stockStatus==0) {
                            if ( this.processForm.qualified==1) {
                                this.$message.warning("请选择合格或者销售合格状态")
                                return false
                            }
                            this.postFormRequest("/bus/tb-stock-real/changeTheInventoryStatus", this.processForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.dialogVisible = false
                                        this.$message.success('添加成功')
                                        this.$refs.processForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        }else {
                            this.$message.warning("要改判请选择状态为入库")
                            return false
                        }


                    }
                })
            },
            //取消按钮
            cancelForm() {
                this.form = {}
                this.loading = false;
                this.dialogVisible = false;
                this.$refs.processForm.resetFields();
            },
            // 分页
            table_index(index) {
                return (this.currPage - 1) * this.pageSize + index + 1
            },
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            },
            handleCurrentChange: function (currPage) {
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            currentPageChanged: function (currPage, pageSize) {
                this.postFormRequest("/bus/tb-stock-real/listByPageProduct", {
                    current: currPage,
                    size: pageSize,
                    stockAddrId: this.selectForm.stockAddrId,
                    classNameId: this.selectForm.classNameId,
                    vestingDate: this.selectForm.vestingDate ? this.dateYMDFormat(this.selectForm.vestingDate) : '',
                    categoryId: this.selectForm.categoryId,
                    productId: this.selectForm.productId,
                    startTime: this.selectForm.startTime ? this.dateYMDhmsFormat(this.selectForm.startTime) : '',
                    endTime: this.selectForm.endTime ? this.dateYMDhmsFormat(this.selectForm.endTime) : '',
                }).then(res => {
                    if (res.success) {
                        this.tbSteelGradeList = res.data.records
                        this.total = res.data.total
                        this.$message.success('查询成功')
                    }

                })
            },
            // 分页 end
        },
        watch: {
            dialogVisible: function () {
                if (this.$refs["processForm"] != undefined) {
                    this.$refs["processForm"].resetFields();
                }
            }
        }
    }
</script>

<style scoped>
    .elp {
        text-align: right;
    }


</style>

