<template>
  <div class="homePage">
    <Sidebar class="main_aside" ref="sidebar" :is-collapse="isCollapse" :class="isCollapse?'close':''"
             @menuItemChange="menuItemChange"></Sidebar>
    <div class="main_container" :class="isCollapse?'hideSidebar':''">
      <div class="fixed-header">
        <button class="btn_collapse" @click="getCollapse">
          <i :class="!isCollapse?'el-icon-d-arrow-left':'el-icon-d-arrow-right'"></i>
        </button>
        <Header></Header>
      </div>
      <div class="tab_list">
                <span class="tabs" :class="tabSelectedIndx == index?'tabs_select':''" v-for="(item,index) in tabList"
                      :key="index" @click="tabClick(index,false)">
                        {{ item.menuName }}
                        <i class="el-icon-circle-close icon" v-if="index != 0" @click.stop="tabClick(index,true)"></i>
                </span>
        <span class="closeAll" v-if="tabList.length>=5" @click="clickCloseAll">清空</span>
      </div>
      <el-container class="main_content">
        <router-view></router-view>
      </el-container>
    </div>
  </div>
</template>

<script>
import Header from "../components/header/header";
import Sidebar from "../components/sidebar/sidebar";

export default {
  name: "HomePage",
  components: {
    Header,
    Sidebar
  },
  data() {
    return {
      isCollapse: false,
      tabList: [],
      tabSelectedIndx: 0,
    }
  },
  mounted() {
    this.$router.push(this.$route.path);
    // let hb = this.$parent.$parent.hb;
    // hb.sendLoginInfo();
  },
  methods: {
    //展开收起菜单
    getCollapse() {
      this.isCollapse = !this.isCollapse
    },
    //当tab页被点击时
    tabClick(index, isClose) {
      if (!isClose) {
        this.tabSelectedIndx = index
        this.$refs.sidebar.menuItemClick(this.tabList[index].menuURL);
        let oldPath = this.tabList[index].menuURL
        if (oldPath.split('?').length > 1) {
          let menuURL = oldPath.split('?')[0]
          let query = oldPath.split('?')[1].split('=')
          let param = {}
          param[query[0]] = query[1]
          this.$router.push({path: menuURL, query: param})
        } else {
          this.$router.push(oldPath)
        }
      } else {
        // 删除标签
        this.tabList.splice(index, 1)
        if (this.tabSelectedIndx == index) {
          this.tabSelectedIndx = index - 1
          this.$refs.sidebar.menuItemClick(this.tabList[index - 1].menuURL);
          let oldPath = this.tabList[index - 1].menuURL
          if (oldPath.split('?').length > 1) {
            let menuURL = oldPath.split('?')[0]
            let query = oldPath.split('?')[1].split('=')
            let param = {}
            param[query[0]] = query[1]
            this.$router.push({path: menuURL, query: param})
          } else {
            this.$router.push(oldPath)
          }
        } else {
          if (this.tabSelectedIndx > index) {
            this.tabSelectedIndx = this.tabSelectedIndx - 1
          }
        }
      }
    },
    // 全部关闭
    clickCloseAll() {
      this.tabSelectedIndx = 0;
      this.tabList.splice(1, this.tabList.length);
      this.$router.push(this.tabList[0].menuURL)
      this.$refs.sidebar.menuItemClick(this.tabList[0].menuURL);
      this.$forceUpdate();
    },
    //处理选中菜单
    menuItemChange(item) {
      let menuId = item.id
      let isExist = false;
      this.tabList.forEach((item, index) => {
        if (item.id == menuId) {
          this.tabSelectedIndx = index;
          isExist = true;
          return;
        }
      })
      if (!isExist) {
        this.tabList.push(item);
        this.tabSelectedIndx = this.tabList.length - 1;
      }
    }
  },
  watch: {
    tabList: {
      handle(newVal, oldVal) {
        console.info(newVal, oldVal)
      },
      immediate: true
    },
    tabSelectedIndx: function (val) {
      console.info('val', val)
    }
  },
}
</script>

<style lang="scss" scoped>
$sideBarWidth: 210px;
.homePage {
  width: 100%;
  display: flex;
  overflow: hidden;
}

.main_aside {
  background: #304156;
  height: 100vh;
  transition: width 0.28s;
  width: $sideBarWidth !important;;
  font-size: 0px;
  overflow-x: hidden !important;
}

.main_container {
  width: calc(100% - #{$sideBarWidth}) !important;
  box-sizing: border-box;
  height: 100vh;
}

.fixed-header {
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}

.close {
  width: 55px !important;
}

.hideSidebar {
  width: calc(100% - 55px) !important;
}

.btn_collapse {
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  padding: 0 15px;
  float: left;
  box-sizing: border-box;
  cursor: pointer;
  border-right: 1px solid #eeeeee;
}

/*右侧动态内容*/
.main_content {
  background: #F6F6F6;
  height: calc(100vh - 85px);
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.tab_list {
  width: 100%;
  height: 35px;
  display: flex;
  border-bottom: 1px solid #E6E6E1;
  position: relative;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 60px;

  .tabs {
    height: 35px;
    padding: 0 25px 0 15px;
    line-height: 35px;
    text-align: center;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .tabs:hover,
  .tabs_select {
    background: #F6F6F6;
    color: #4F8AFF;
    cursor: pointer;
    height: 34px;
  }

  .icon {
    padding: 5px;
    box-sizing: border-box;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .closeAll {
    font-size: 14px;
    color: #4F8AFF;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    background: #ffffff;
    border-left: 1px solid #E6E6E1;
    position: absolute;
    right: 0;
    z-index: 1;
    cursor: pointer;
  }
}

</style>
