<template>
  <div>
    <div class="main_left">
      <div class="left_content">
        <el-form :inline="true" :model="processForm" class="el-form-inline" size="mini" labelPosition="right"
                 label-width="93px" ref="processForm" :rules="rules">
          <el-form-item label="执行标准：" prop="standardId">
            <el-select v-model="processForm.standardId" @change="changeSearch" placeholder="请选择执行标准" size="mini"
                       clearable>
              <el-option v-for="(item,index) in executiveList" :key="index" :label="item.standardCode"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="许可证号：" prop="licenceCode">
            <el-input v-model="processForm.licenceCode" placeholder="请输入许可证号" size="mini"
                      clearable type="text" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="产品名称：" prop="productNameId">
            <el-select v-model="processForm.productNameId" placeholder="请选择产品名称" size="mini" clearable>
              <el-option v-for="(item,index) in productList" :key="index" :label="item.productName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物料名称：" prop="materialId">
            <el-select v-model="processForm.materialId" placeholder="请选择物料名称" size="mini" clearable>
              <el-option v-for="(item,index) in materialList" :key="index" :label="item.materialName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="炉号：" prop="stoveCode">
            <el-input v-model="processForm.stoveCode" placeholder="请输入炉号" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item label="批号：" prop="batchCode">
            <el-input v-model="processForm.batchCode" placeholder="请输入批号" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item label="捆数：" prop="bundleNumber">
            <el-input v-model="processForm.bundleNumber" placeholder="请输入捆数" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item label="当前捆号：" prop="bundleCode">
            <el-input v-model="processForm.bundleCode" placeholder="请输入当前捆号" size="mini"
                      clearable type="text" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="支数：" prop="branchCount">
            <el-input v-model="processForm.branchCount" placeholder="请输入支数" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item label="钢种：" prop="steelGradeId">
            <el-select v-model="processForm.steelGradeId" placeholder="请选择钢种" size="mini" clearable>
              <el-option v-for="(item,index) in steelGradeList" :key="index" :label="item.steelGradeName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规格：" prop="specId">
            <el-select v-model="processForm.specId" @change="searchChange" placeholder="请选择规格" size="mini" clearable>
              <el-option v-for="(item,index) in specificationsList" :key="index" :label="item.specValue"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="长度：" prop="length">
            <el-input v-model="processForm.length" placeholder="请输入长度" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item label="归属日期：" prop="vestingDate">
            <el-date-picker format="YYYY-MM-DD" v-model="processForm.vestingDate" type="date" placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="班次：" prop="frequencyId">
            <el-select v-model="processForm.frequencyId" placeholder="请选择班次" size="mini" clearable>
              <el-option v-for="(item,index) in frequencyList" :key="index" :label="item.frequencyName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班组：" prop="classNameId">
            <el-select v-model="processForm.classNameId" placeholder="请选择班组" size="mini" clearable>
              <el-option v-for="(item,index) in teamList" :key="index" :label="item.classesName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否定尺：" prop="fixedLength">
            <el-select v-model="processForm.fixedLength" size="mini" clearable>
              <el-option v-for="(item,index) in fixedLengthList" :key="index" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <div style="margin-right: 240px">
            <el-form-item label="是否合格：" prop="qualified">
              <el-select v-model="processForm.qualified" size="mini" clearable>
                <el-option v-for="(item,index) in qualifiedList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="float: right">
            <el-form-item prop="autoType">
              <el-checkbox v-model="processForm.autoType" @change="autoChangeHandle">是否自动</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button class="btn" type="primary" size="mini" @click="SignPrinting('processForm')">标牌打印</el-button>
            </el-form-item>
          </div>
          <el-table
              :data="weightedList"
              border
              :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
              :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
            <el-table-column
                align="center"
                label="称重重量"
                prop="weight">
            </el-table-column>
            <el-table-column
                align="center"
                label="称重时间"
                prop="time">
              <template v-slot="scope">
                {{ scope.row.time ? dateYMDhmsFormat(scope.row.time) : '-' }}
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>
    </div>
    <div style="width: 62%;float: left">
      <div class="subMain">
        <div class="rowtxt"><span>当前加工中心：</span>{{ repositoryName.transformerName }}</div>
        <div class="rowtxt"><span>当前默认仓库：</span>{{ repositoryName.repoHouseName }}</div>
        <el-button class="btn" type="primary" size="mini" @click="ResetClick">重置</el-button>
      </div>
      <div class="subMain">
        <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
          <el-row>
            <el-col span="8">
              <el-form-item class="row" label="班次：">
                <el-select v-model="selectForm.frequency" placeholder="请选择班次" size="mini" clearable @change="readData">
                  <el-option v-for="(item,index) in frequencyList" :key="index" :label="item.frequencyName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item class="row" label="班组：">
                <el-select v-model="selectForm.className" placeholder="请选择班组" size="mini" clearable @change="readData">
                  <el-option v-for="(item,index) in teamList" :key="index" :label="item.classesName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item class="row" label="批号：">
                <el-input v-model="selectForm.batchCode" placeholder="请输入批号" size="mini" @change="readData"
                          clearable type="text"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="8">
              <el-form-item class="row" label="入库时间：">
                <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.startTime"
                                clearable :editable="false" placeholder="请选择开始时间" @change="readData">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="~">
                <el-date-picker type="date" format="YYYY-MM-DD" size="mini" v-model="selectForm.endTime"
                                clearable :editable="false" placeholder="请选择结束时间" @change="readData">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col span="8">
              <el-form-item>
                <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="main">
        <el-table
            :data="tbWarehousingList"
            border
            :row-class-name="status_change"
            :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
            :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
          <el-table-column
              type="index"
              align="center"
              fixed="left"
              label="序号"
              width="80">
          </el-table-column>
          <el-table-column
              prop="stockTime"
              label="入库日期"
              align="center"
              width="110">
            <template #default="scope">
              {{ scope.row.stockTime ? dateYMDFormat(scope.row.stockTime) : '-' }}
            </template>
          </el-table-column>
          <el-table-column
              prop="frequencyName"
              label="入库班次"
              align="center">
          </el-table-column>
          <el-table-column
              prop="classesName"
              label="入库班组"
              align="center">
          </el-table-column>
          <el-table-column
              prop="batchCode"
              label="批号"
              align="center"
              width="100">
          </el-table-column>
          <el-table-column
              prop="bundleCode"
              label="捆号"
              align="center"
              width="120">
          </el-table-column>
          <el-table-column
              prop="steelGradeName"
              label="钢种"
              align="center"
              width="100">
          </el-table-column>
          <el-table-column
              prop="specValue"
              label="规格"
              align="center"
              width="100">
          </el-table-column>
          <el-table-column
              prop="length"
              label="长度"
              align="center">
          </el-table-column>
          <el-table-column
              prop="branchCount"
              label="捆支数"
              align="center">
          </el-table-column>
          <el-table-column
              prop="actWeight"
              label="实际重量"
              align="center"
              width="140">
          </el-table-column>
          <el-table-column
              prop="materialCode"
              label="物料编码"
              align="center">
          </el-table-column>
          <el-table-column
              prop="materialName"
              label="物料名称"
              align="center">
          </el-table-column>
          <el-table-column
              prop="qualified"
              label="是否合格"
              align="center"
              width="120">
            <template #default="scope">
              <p v-if="scope.row.qualified == 0">合格</p>
              <p v-if="scope.row.qualified == 1">不合格</p>
              <p v-if="scope.row.qualified == 2">销售合格</p>
            </template>
          </el-table-column>
          <el-table-column
              prop="printCount"
              label="打印次数"
              align="center">
          </el-table-column>
          <el-table-column
              align="center"
              width="120"
              fixed="right"
              label="操作">
            <template v-slot="scope">
              <el-button @click="detailsClick(scope.row.id)" type="text" size="small">详情</el-button>
              <el-button type="text" size="small" @click="ReprintClick(scope.row.id)">打印</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--重置-->
      <el-dialog
          custom-class="dialogMain"
          title="重置"
          v-model="dialogShow"
          width="30%">
        <el-form :model="resetForm" :rules="resetRules" label-position="top" ref="resetForm">
          <el-form-item label="加工中心：" prop="center">
            <el-select v-model="resetForm.center" placeholder="请选择加工中心" size="mini" clearable>
              <el-option v-for="(item,index) in centerList" :key="index" :label="item.cncName"
                         :value="item.id+'-'+item.cncName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="默认仓库" prop="warehouse">
            <el-select v-model="resetForm.warehouse" placeholder="请选择默认仓库" size="mini" clearable>
              <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.stockName"
                         :value="item.id+'-'+item.stockName"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="dialog-footer">
          <el-button type="primary" size="mini" @click="resetOk('resetForm')">确定</el-button>
        </div>
      </el-dialog>
      <!--详情-->
      <el-dialog
          custom-class="dialogMain"
          title="详情"
          v-model="dialogVisible"
          width="40%">
        <el-descriptions class="margin-top" :column="3" size="medium">
          <el-descriptions-item label="执行标准：">{{ detailsForm.standardCode }}</el-descriptions-item>
          <el-descriptions-item label="许可证号：">{{ detailsForm.licenceCode }}</el-descriptions-item>
          <el-descriptions-item label="产品名称：">{{ detailsForm.productName }}</el-descriptions-item>
          <el-descriptions-item label="物料名称：">{{ detailsForm.materialName }}</el-descriptions-item>
          <el-descriptions-item label="物料编码：">{{ detailsForm.materialCode }}</el-descriptions-item>
          <el-descriptions-item label="炉号：">{{ detailsForm.stoveCode }}</el-descriptions-item>
          <el-descriptions-item label="批号：">{{ detailsForm.batchCode }}</el-descriptions-item>
          <el-descriptions-item label="当前捆号：">{{ detailsForm.bundleCode }}</el-descriptions-item>
          <el-descriptions-item label="支数：">{{ detailsForm.branchCount }}</el-descriptions-item>
          <el-descriptions-item label="钢种：">{{ detailsForm.steelGradeName }}</el-descriptions-item>
          <el-descriptions-item label="规格：">{{ detailsForm.specValue }}</el-descriptions-item>
          <el-descriptions-item label="长度：">{{ detailsForm.length }}</el-descriptions-item>
          <el-descriptions-item label="实际重量：">{{ detailsForm.actWeight }}</el-descriptions-item>
          <el-descriptions-item label="打印次数：">{{ detailsForm.printCount }}</el-descriptions-item>
          <el-descriptions-item label="归属日期：">{{ detailsForm.vestingDate }}</el-descriptions-item>
          <el-descriptions-item label="班次：">{{ detailsForm.frequencyName }}</el-descriptions-item>
          <el-descriptions-item label="班组：">{{ detailsForm.classesName }}</el-descriptions-item>
          <el-descriptions-item label="是否定尺：">{{ detailsForm.fixedLength == 0 ? '定尺' : '非定尺' }}</el-descriptions-item>
          <el-descriptions-item label="是否合格：">
            {{
              detailsForm.qualified = (detailsForm.qualified == 0 ? '合格' : (detailsForm.qualified == 1 ? '不合格' : '销售合格'))
            }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="dialog-footer">
          <el-button type="primary" size="mini" @click="dialogVisible=false">确定</el-button>
        </div>
      </el-dialog>
      <!--打印-->
      <el-dialog
          custom-class="dialogContent"
          title="打印"
          v-model="dialogMainShow"
          width="40%">
        <el-form :inline="true" :model="PrintForm" class="el-form-inline" size="mini" labelPosition="right"
                 label-width="93px" ref="PrintForm" :rules="PrintRules">
          <el-form-item class="row" label="执行标准：" prop="standardId">
            <el-select v-model="PrintForm.standardId" placeholder="请选择执行标准" disabled size="mini">
              <el-option v-for="(item,index) in executiveList" :key="index" :label="item.standardCode"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="许可证号：" prop="licenceCode">
            <el-input v-model="PrintForm.licenceCode" placeholder="请输入许可证号" size="mini"
                      clearable type="text"></el-input>
          </el-form-item>
          <el-form-item class="row" label="产品名称：" prop="productNameId">
            <el-select v-model="PrintForm.productNameId" placeholder="请选择产品名称" size="mini" disabled>
              <el-option v-for="(item,index) in productList" :key="index" :label="item.productName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="物料名称：" prop="materialId">
            <el-select v-model="PrintForm.materialId" placeholder="请选择物料名称" size="mini" disabled>
              <el-option v-for="(item,index) in materialList" :key="index" :label="item.materialName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="炉号：" prop="stoveCode">
            <el-input v-model="PrintForm.stoveCode" placeholder="请输入炉号" size="mini"
                      clearable type="text" disabled></el-input>
          </el-form-item>
          <el-form-item class="row" label="批号：" prop="batchCode">
            <el-input v-model="PrintForm.batchCode" placeholder="请输入批号" size="mini"
                      clearable type="text" disabled></el-input>
          </el-form-item>
          <el-form-item class="row" label="当前捆号：" prop="bundleCode">
            <el-input v-model="PrintForm.bundleCode" placeholder="请输入当前捆号" size="mini"
                      clearable type="text" disabled></el-input>
          </el-form-item>
          <el-form-item class="row" label="支数：" prop="branchCount">
            <el-input v-model="PrintForm.branchCount" placeholder="请输入支数" size="mini"
                      clearable type="text" disabled></el-input>
          </el-form-item>
          <el-form-item class="row" label="钢种：" prop="steelGradeId">
            <el-select v-model="PrintForm.steelGradeId" placeholder="请选择钢种" size="mini" disabled>
              <el-option v-for="(item,index) in steelGradeList" :key="index" :label="item.steelGradeName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="规格：" prop="specId">
            <el-select v-model="PrintForm.specId" placeholder="请选择规格" size="mini" disabled>
              <el-option v-for="(item,index) in specificationsList" :key="index" :label="item.specValue"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="长度：" prop="length">
            <el-input v-model="PrintForm.length" placeholder="请输入长度" size="mini"
                      clearable type="text" disabled></el-input>
          </el-form-item>
          <el-form-item class="row" label="归属日期：" prop="vestingDate">
            <el-date-picker format="YYYY-MM-DD" v-model="PrintForm.vestingDate" type="date" placeholder="选择日期" disabled>
            </el-date-picker>
          </el-form-item>
          <el-form-item class="row" label="班次：" prop="frequencyId">
            <el-select v-model="PrintForm.frequencyId" placeholder="请选择班次" size="mini" disabled>
              <el-option v-for="(item,index) in frequencyList" :key="index" :label="item.frequencyName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="班组：" prop="classNameId">
            <el-select v-model="PrintForm.classNameId" placeholder="请选择班组" size="mini" disabled>
              <el-option v-for="(item,index) in teamList" :key="index" :label="item.classesName"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="是否定尺：" prop="fixedLength">
            <el-select v-model="PrintForm.fixedLength" size="mini" disabled>
              <el-option v-for="(item,index) in fixedLengthList" :key="index" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="row" label="是否合格：" prop="qualified">
            <el-select v-model="PrintForm.qualified" size="mini" disabled>
              <el-option v-for="(item,index) in qualifiedList" :key="index" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-table
              :data="tableList"
              border
              :header-row-style="{background:'#DEEBF6',color:'#000000',height:'30px',fontWeight:'Medium'}"
              :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'30px',fontWeight:'Medium'}">
            <el-table-column
                align="center"
                label="称重重量"
                prop="weight">
            </el-table-column>
            <el-table-column
                align="center"
                label="称重时间"
                prop="time">
              <template v-slot="scope">
                {{ scope.row.time ? dateYMDhmsFormat(scope.row.time) : '-' }}
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div class="dialog-footer">
          <el-button type="primary" size="mini" @click="ReprintDialog('PrintForm')">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {fixedLengthList} from '../common/baseData'; //是否定尺
import {qualifiedList} from '../common/baseData'; //是否合格
import localStore from "@/utils/storage";
import moment from 'moment'

const _fixedLengthList = fixedLengthList;
const _qualifiedList = qualifiedList;
export default {
  name: "FinishedProductWeighing",
  data() {
    return {
      weightedList: [],
      // 左侧
      processForm: {
        licenceCode: '',
        standardId: '',
        productNameId: '',
        materialId: '',
        categoryId: '',
        stoveCode: '',
        batchCode: '',
        bundleNumber: '',
        branchCount: '',
        steelGradeId: '',
        monitor: '',
        specId: '',
        length: '',
        vestingDate: '',
        frequencyId: '',
        classNameId: '',
        fixedLength: '',
        qualified: '',
        autoType: 1,
        createBy: '',
        cncId: '',
        stockId: '',
      },
      rules: {
        licenceCode: [{required: true, message: '请选择许可证号', trigger: 'change'}],
        standardId: [{required: true, message: '请选择执行标准', trigger: 'change'}],
        productNameId: [{required: true, message: '请选择产品名称', trigger: 'change'}],
        materialId: [{required: true, message: '请选择物料名称', trigger: 'change'}],
        stoveCode: [{required: true, message: '请输入炉号', trigger: 'change'}],
        batchCode: [{required: true, message: '请输入批号', trigger: 'blur'}],
        bundleNumber: [{required: true, message: '请输入捆数', trigger: 'blur'}],
        branchCount: [{required: true, message: '请输入支数', trigger: 'blur'}],
        steelGradeId: [{required: true, message: '请选择钢种', trigger: 'change'}],
        specId: [{required: true, message: '请选择规格', trigger: 'change'}],
        length: [{required: true, message: '请输入长度', trigger: 'blur'}],
        vestingDate: [{required: true, message: '请选择归属日期', trigger: 'change'}],
        frequencyId: [{required: true, message: '请选择班次', trigger: 'change'}],
        classNameId: [{required: true, message: '请选择班组', trigger: 'change'}],
        fixedLength: [{required: true, message: '请选择是否定尺', trigger: 'change'}],
        qualified: [{required: true, message: '请选择是否合格', trigger: 'change'}],
      },
      licenseKeyList: [],
      productList: [],
      executiveList: [],
      materialList: [],
      steelGradeList: [],
      specificationsList: [],
      frequencyList: [],
      teamList: [],
      fixedLengthList: _fixedLengthList,
      qualifiedList: _qualifiedList,
      centerList: [],
      warehouseList: [],
      repositoryName: {
        transformerName: '',
        repoHouseName: ''
      },
      //重置按钮
      dialogShow: false,
      resetForm: {
        center: '',
        warehouse: ''
      },
      resetRules: {
        center: [{required: true, message: '请选择加工中心', trigger: 'change'}],
        warehouse: [{required: true, message: '请选择当前默认仓库', trigger: 'change'}],
      },
      //查询
      selectForm: {
        frequency: '',
        className: '',
        batchCode: '',
        startTime: '',
        endTime: ''
      },
      //表格
      tbWarehousingList: [],
      //详情按钮
      dialogVisible: false,
      detailsForm: {},
      //打印按钮
      dialogMainShow: false,
      PrintForm: {
        id: '',
        licenceCode: '',
        standardId: '',
        productNameId: '',
        materialId: '',
        stoveCode: '',
        batchCode: '',
        categoryId: '',
        branchCount: '',
        steelGradeId: '',
        specId: '',
        length: '',
        vestingDate: '',
        frequencyId: '',
        classNameId: '',
        fixedLength: '',
        qualified: '',
        createBy: '',
        cncId: '',
        stockId: '',
      },
      PrintRules: {
        licenceCode: [{required: true, message: '请选择许可证号', trigger: 'change'}],
        standardId: [{required: true, message: '请选择执行标准', trigger: 'change'}],
        productNameId: [{required: true, message: '请选择产品名称', trigger: 'change'}],
        materialId: [{required: true, message: '请选择物料名称', trigger: 'change'}],
        stoveCode: [{required: true, message: '请输入炉号', trigger: 'change'}],
        batchCode: [{required: true, message: '请输入批号', trigger: 'blur'}],
        branchCount: [{required: true, message: '请输入支数', trigger: 'blur'}],
        steelGradeId: [{required: true, message: '请选择钢种', trigger: 'change'}],
        specId: [{required: true, message: '请选择规格', trigger: 'change'}],
        length: [{required: true, message: '请输入长度', trigger: 'blur'}],
        vestingDate: [{required: true, message: '请选择归属日期', trigger: 'change'}],
        frequencyId: [{required: true, message: '请选择班次', trigger: 'change'}],
        classNameId: [{required: true, message: '请选择班组', trigger: 'change'}],
        fixedLength: [{required: true, message: '请选择是否定尺', trigger: 'change'}],
        qualified: [{required: true, message: '请选择是否合格', trigger: 'change'}],
      },
      tableList: [],
      createBy: localStore.get('warehouse_userInfo').id,
      weigthForm: {},
      //websocket
      hb: null,
    }
  },
  created() {
    this.hb = this.heartbeat((msg) => {
      msg = JSON.parse(msg);
      let massageInfo = JSON.parse(msg.content)
      if (msg.type == 0) {
        this.processForm.bundleCode = massageInfo.batchCode;
        this.processForm.bundleNumber = massageInfo.numbers;
        this.getTableList();
      }
      if (msg.type == 1) {
        this.weightedList.push({weight: massageInfo.weight, time: massageInfo.localDateTime})
      }
      if (msg.type == 2) {
        this.getTableList();
      }
    });
  },
  unmounted() {
    // 销毁监听
    if (this.hb) {
      this.hb.close();
    }
  },
  mounted() {
    this.selectStock(() => {
      this.getCenter(() => {
        this.getTableList();
      });
    });
  },
  computed: {
    getBatchCode() {
      return this.processForm.batchCode;
    }
  },
  watch: {
    getBatchCode: {
      handler(newval) {
        console.log(newval)
        this.weightedList = [];
        this.tbWarehousingList = [];
      },
      immediate: true
    }
  },
  methods: {
    //重置弹框
    ResetClick() {
      this.dialogShow = true;
    },
    //重置确定按钮处理
    resetOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = null;
          if (this.resetForm.center) {
            arr = this.resetForm.center.split("-");
            this.repositoryName.transformerName = arr[1];
            this.processForm.cncId = arr[0];
          }
          if (this.resetForm.warehouse) {
            arr = this.resetForm.warehouse.split("-");
            this.repositoryName.repoHouseName = arr[1];
            this.processForm.stockId = arr[0];
          }
          this.dialogShow = false;
        }
      })
    },
    //查询
    readData() {
      this.postFormRequest("/bus/tb-stock-in/listByPage",
          {
            frequencyId: this.selectForm.frequency,
            classNameId: this.selectForm.className,
            batchCode: this.selectForm.batchCode,
            startTime: this.selectForm.startTime ? this.dateYMDFormat(this.selectForm.startTime) : '',
            endTime: this.selectForm.endTime ? this.dateYMDFormat(this.selectForm.endTime) : '',
          }).then(res => {
        if (res.success) {
          this.tbWarehousingList = res.data
        }
      })
    },
    //列表
    getTableList() {
      let vestingDate = moment(this.processForm.vestingDate).format("YYYY-MM-DD")
      this.postFormRequest("/bus/tb-stock-in/listByPage", {
        cncId: this.processForm.cncId,
        stockId: this.processForm.stockId,
        vestingDate: vestingDate,
        batchCode: this.processForm.batchCode
      }).then(data => {
        if (data.success) {
          this.tbWarehousingList = data.data
        }
      })
    },
    //列表状态
    status_change({row}) {
      if (row.stockStatus == 2) {
        return 'success-row'
      }
      return '';
    },
    //获取缓存信息
    getCenter(callback) {
      this.postFormRequest("/bus/tb-stock-in/cacheId").then(res => {
        if (res.success && res.data) {
          this.processForm = res.data;
          this.processForm.autoType = this.processForm.autoType != 1;
          let center = this.centerList.filter(item => {
            return item.id == this.processForm.cncId;
          });
          let warehouse = this.warehouseList.filter(item => {
            return item.id == this.processForm.stockId;
          })
          this.resetForm.center = center.length > 0 ? this.processForm.cncId + '-' + center[0].cncName : '';
          this.resetForm.warehouse = warehouse.length > 0 ? this.processForm.stockId + '-' + warehouse[0].stockName : '';
          this.repositoryName.transformerName = center.length > 0 ? center[0].cncName : '';
          this.repositoryName.repoHouseName = warehouse.length > 0 ? warehouse[0].stockName : '';
          callback();
        }
      }).catch(() => {
      })
    },
    //是否自动
    autoChangeHandle(val) {
      console.log(val)
      val = !val;
    },
    //查询所有基础信息
    selectStock(callback) {
      this.postFormRequest("/bus/tb-stock-in/queryAllOptions").then(res => {
        if (res.success) {
          this.executiveList = res.data.tbStandardInfo;
          this.productList = res.data.tbProductNames;
          this.materialList = res.data.tbMaterialTypes;
          this.steelGradeList = res.data.tbSteelGrades;
          this.specificationsList = res.data.tbSpecInfos;
          this.frequencyList = res.data.tbFrequencies;
          this.teamList = res.data.tbClasses;
          this.centerList = res.data.tbCncInfos;
          this.warehouseList = res.data.tbStockAddr;
          callback();
        }
      }).catch(() => {
      })
    },
    //许可证号带出执行标准
    changeSearch(value) {
      this.processForm.licenceCode = [];
      this.executiveList.map(e => {//遍历数据
        if (e.id === value) {
          this.processForm.licenceCode = e.licenceCode
        }
      })
    },
    //根据规格查找品类ID
    searchChange(value) {
      this.processForm.categoryId = [];
      this.specificationsList.map(e => {//遍历数据
        if (e.id === value) {
          this.processForm.categoryId = e.categoryId
        }
      })
    },
    //标牌打印
    SignPrinting(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.processForm.cncId == '' && this.processForm.stockId == '') {
            this.$message.info('请选择加工中心和仓库');
            return
          }
          let vestingDate = moment(this.processForm.vestingDate).format("YYYY-MM-DD")
          let param = {
            autoType: this.processForm.autoType == true ? 0 : 1,
            stoveCode: this.processForm.stoveCode,
            batchCode: this.processForm.batchCode,
            branchCount: this.processForm.branchCount,
            bundleNumber: this.processForm.bundleNumber,
            categoryId: this.processForm.categoryId,
            classNameId: this.processForm.classNameId,
            createBy: this.createBy,
            fixedLength: this.processForm.fixedLength,
            frequencyId: this.processForm.frequencyId,
            length: this.processForm.length,
            materialId: this.processForm.materialId,
            productNameId: this.processForm.productNameId,
            qualified: this.processForm.qualified,
            specId: this.processForm.specId,
            standardId: this.processForm.standardId,
            steelGradeId: this.processForm.steelGradeId,
            vestingDate: vestingDate,
            cncId: this.processForm.cncId,
            stockId: this.processForm.stockId,
          };
          this.postFormRequest("/bus/tb-stock-in/theSignToPrint", param).then(res => {
            if (res.success) {
              this.weigthForm = res.data
              this.getWeigh();
            } else {
              this.$message.error(res.msg)
              return false;
            }
          })
        }
      })
    },
    //调用称重
    getWeigh() {
      this.postRequest("/bus/tb-stock-in/weighTheSignToPrint", {
        list: this.weigthForm.list,
        productId: this.weigthForm.productId,
        createBy: this.createBy,
      }).then(res => {
        if (res.success) {
          /*setTimeout(() => {
            this.getTableList()
          }, 1000);*/
        }
      })
    },
    //获取详情
    detailsClick(id) {
      this.postFormRequest("/bus/tb-stock-in/particulars", {id: id}
      ).then(res => {
        if (res.success) {
          this.detailsForm = res.data
          this.dialogVisible = true;
        }
      })
    },
    //重新打印
    ReprintClick(id) {
      this.dialogMainShow = true;//展开抽屉
      this.postFormRequest("/bus/tb-stock-in/particulars", {id: id}).then(data => {
        if (data.success) {
          let row = data.data
          this.PrintForm = {
            id: row.id,
            licenceCode: row.licenceCode,
            standardId: row.standardId,
            productNameId: row.productNameId,
            materialId: row.materialId,
            stoveCode: row.stoveCode,
            batchCode: row.batchCode,
            bundleCode: row.bundleCode,
            branchCount: row.branchCount,
            steelGradeId: row.steelGradeId,
            specId: row.specId,
            length: row.length,
            vestingDate: row.vestingDate,
            frequencyId: row.frequencyId,
            classNameId: row.classNameId,
            fixedLength: row.fixedLength,
            qualified: row.qualified,
            categoryId: row.categoryId,
            createBy: row.createBy,
            cncId: row.cncId,
            stockId: row.stockId,
          }
          this.tableList = [];
          this.tableList.push({weight: row.actWeight, time: row.actWeightTime})
        }
      })
    },
    //重新打印提交
    ReprintDialog(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let vestingDate = moment(this.PrintForm.vestingDate).format("YYYY-MM-DD")
          this.postFormRequest("/bus/tb-stock-in/againTheSignToPrint", {
            id: this.PrintForm.id,
            stoveCode: this.PrintForm.stoveCode,
            batchCode: this.PrintForm.batchCode,
            branchCount: this.PrintForm.branchCount,
            categoryId: this.PrintForm.categoryId,
            classNameId: this.PrintForm.classNameId,
            createBy: this.createBy,
            fixedLength: this.PrintForm.fixedLength,
            frequencyId: this.PrintForm.frequencyId,
            length: this.PrintForm.length,
            materialId: this.PrintForm.materialId,
            productNameId: this.PrintForm.productNameId,
            qualified: this.PrintForm.qualified,
            specId: this.PrintForm.specId,
            standardId: this.PrintForm.standardId,
            steelGradeId: this.PrintForm.steelGradeId,
            vestingDate: vestingDate,
            cncId: this.PrintForm.cncId,
            stockId: this.PrintForm.stockId,
            weight: this.tableList[0].weight,
            time: this.tableList[0].time,
          }).then(res => {
            if (res.success) {
              this.dialogMainShow = false;
              this.$refs.PrintForm.resetFields();
              this.$message.success('重新打印成功');
              this.getTableList();
            } else {
              this.$message.error(res.msg)
              return false;
            }
          })
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.loading = false;
      this.dialogVisible = false;
    },
  },
}
</script>

<style scoped>
.main_left {
  width: 37%;
  float: left;
  margin-right: 5px;
  text-align: center;
}

.left_content {
  background: white;
  border: 1px solid #ccc;
  border-radius: 6px
}

.main_left /deep/ .el-input--mini {
  width: 140px;
}

.main_left .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
  margin-top: 10px;
  margin-bottom: 5px;
}

.rowtxt {
  display: flex;
  padding-right: 30px;
  float: left;
  line-height: 28px;
}

/deep/ .dialogContent {
  height: 70%;
  overflow: hidden;
}

/deep/ .dialogContent .el-dialog__body {
  height: calc(100% - 110px);
  overflow: hidden;
  overflow-y: auto;
}

/deep/ .dialogContent .el-input--mini .el-input__inner {
  width: 200px;
}

/deep/ .dialogContent .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 200px;
}

/deep/ .el-table .success-row {
  background: #f0f9eb;
}
</style>

