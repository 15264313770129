import {createRouter, createWebHashHistory} from 'vue-router'
// --公共--
import Login from "../pages/login";//登录
import HomePage from "../pages/HomePage";//框架

// --实时任务--
import FinishedProductWeighing from "../pages/FinishedProductWeighing";//成品称重打牌

// --基础数据管理--
import InventoryAddress from "../pages/InventoryAddress";//库存地址管理
import StackingManagement from "../pages/StackingManagement";//垛位管理
import TbCategoryInfoPage from "../pages/TbCategoryInfoPage";//品类管理
import ProductNameManagement from "../pages/ProductNameManagement";//品名管理
import TbSpecInfoMessagePage from "../pages/TbSpecInfoMessagePage";//规格信息管理
import TbSpecInfoPage from "../pages/TbSpecInfoPage";//规格管理
import TbCncInfoPage from "../pages/TbCncInfoPage";//加工中心管理
import TbLabelInfoPage from "../pages/TbLabelInfoPage";//标牌信息管理
import TbSteelGradePage from "../pages/TbSteelGradePage";//钢种管理
import StandardManagement from "../pages/StandardManagement";//标准管理

//--组织架构管理--
import TbDepartmentPage from "../pages/TbDepartmentPage";//部门管理
import TbPositionPage from "../pages/TbPositionPage";//岗位管理
import TbEmployeePage from "../pages/TbEmployeePage";//员工管理
import TbMenuPage from "../pages/TbMenuPage";//菜单管理

//--物料管理--
import TbMaterialTypePage from "../pages/TbMaterialTypePage";//物料类型管理
import TbMaterialInfoPage from "../pages/TbMaterialInfoPage";//物料信息管理

//--库存管理--
import InventoryMonitoring from "../pages/InventoryMonitoring";//实时库存监控
import StockQuery from "../pages/StockQuery";//库存信息查询
import FinishedProductInquiry from "../pages/FinishedProductInquiry";//待入库成品查询
import ReverseRecord from "../pages/ReverseRecord";//倒库倒垛记录
import InventoryCarryForward from "../pages/InventoryCarryForward";//库存结转
import DeliveryNote from "../pages/DeliveryNote";//发货通知单管理
import DeliveryNoteToExamine from "../pages/DeliveryNoteToExamine";//发货通知单审核
import ReturnOrder from "../pages/ReturnOrder";//退货通知单管理
import TheTotalInventory from "../pages/TheTotalInventory";//实时库存管理
//--报表管理--
import ReportManagement from "../pages/ReportManagement"
//--系统日志--
import systemLog from "../pages/systemLog";//系统日志
//--产品质量管理--
import QueryReport from "../pages/QueryReport";//查询质检报告
import QualityInspectionReport from "../pages/QualityInspectionReport";//录入质检报告
import PrintCertificate from "../pages/PrintCertificate";//打印质量证明书
import CertificateData from "../pages/CertificateData";//证明书基础数据
import CertificationOfProduct from "../subpage/CertificationOfProduct";//证明书
import CertificationOfProduct2 from "../page/CertificationOfProduct2";//证明书
import ElementManagement from "../pages/ElementManagement";//检测项目管理
import InputReport from "../pages/InputReport";//录入质检报告

const constantRoutes = [
    {
        path: '/',
        component: HomePage,
        meta: {title: "安山仓库管理系统"},
        children: [
            {path: '/FinishedProductWeighing', name: 'FinishedProductWeighing', component: FinishedProductWeighing, meta: {title: "成品称重打牌"}},
            {path: '/InventoryAddress', name: 'InventoryAddress', component: InventoryAddress, meta: {title: "库存地址管理"}},
            {path: '/StackingManagement', name: 'StackingManagement', component: StackingManagement, meta: {title: "垛位管理"}},
            {path: '/TbCategoryInfoPage', name: 'TbCategoryInfoPage', component: TbCategoryInfoPage, meta: {title: "品类管理"}},
            {path: '/ProductNameManagement', name: 'ProductNameManagement', component: ProductNameManagement, meta: {title: "品名管理"}},
            {path: '/TbSpecInfoMessagePage', name: 'TbSpecInfoMessagePage', component: TbSpecInfoMessagePage, meta: {title: "规格信息管理"}},
            {path: '/TbSpecInfoPage', name: 'TbSpecInfoPage', component: TbSpecInfoPage, meta: {title: "规格管理"}},
            {path: '/TbCncInfoPage',name: 'TbCncInfoPage',component: TbCncInfoPage,meta: {title: "加工中心管理"}},
            {path: '/TbLabelInfoPage',name: 'TbLabelInfoPage',component: TbLabelInfoPage,meta: {title: "标牌信息管理"}},
            {path: '/TbSteelGradePage',name: 'TbSteelGradePage',component: TbSteelGradePage,meta: {title: "钢种管理"}},
            {path: '/StandardManagement',name: 'StandardManagement',component: StandardManagement,meta: {title: "标准管理"}},
            {path: '/TbDepartmentPage', name: 'TbDepartmentPage', component: TbDepartmentPage, meta: {title: "部门管理"}},
            {path: '/TbPositionPage',name: 'TbPositionPage',component: TbPositionPage,meta: {title: "岗位管理"}},
            {path: '/TbEmployeePage',name: 'TbEmployeePage',component: TbEmployeePage,meta: {title: "员工管理"}},
            {path: '/TbMenuPage',name: 'TbMenuPage',component: TbMenuPage,meta: {title: "菜单管理"}},
            {path: '/TbMaterialTypePage',name: 'TbMaterialTypePage',component: TbMaterialTypePage,meta: {title: "物料类型管理"}},
            {path: '/TbMaterialInfoPage', name: 'TbMaterialInfoPage', component: TbMaterialInfoPage, meta: {title: "物料信息管理"}},
            {path: '/InventoryMonitoring', name: 'InventoryMonitoring', component: InventoryMonitoring, meta: {title: "实时库存监控"}},
            {path: '/StockQuery', name: 'StockQuery', component: StockQuery, meta: {title: "库存信息查询"}},
            {path: '/FinishedProductInquiry', name: 'FinishedProductInquiry', component: FinishedProductInquiry, meta: {title: "待入库成品查询"}},
            {path: '/ReverseRecord', name: 'ReverseRecord', component: ReverseRecord, meta: {title: "倒库倒垛记录"}},
            {path: '/InventoryCarryForward', name: 'InventoryCarryForward', component: InventoryCarryForward, meta: {title: "库存结转"}},
            {path: '/DeliveryNote', name: 'DeliveryNote', component: DeliveryNote, meta: {title: "发货通知单管理"}},
            {path: '/DeliveryNoteToExamine', name: 'DeliveryNoteToExamine', component: DeliveryNoteToExamine, meta: {title: "发货通知单审核"}},
            {path: '/ReturnOrder', name: 'ReturnOrder', component: ReturnOrder, meta: {title: "退货通知单管理"}},
            {path: '/ReportManagement', name: 'ReportManagement', component: ReportManagement, meta: {title: "报表管理"}},
            {path: '/systemLog', name: 'systemLog', component: systemLog, meta: {title: "系统日志"}},
            {path: '/QueryReport', name: 'QueryReport', component: QueryReport, meta: {title: "查询质检报告"}},
            {path: '/QualityInspectionReport', name: 'QualityInspectionReport', component: QualityInspectionReport, meta: {title: "录入质检报告"}},
            {path: '/PrintCertificate', name: 'PrintCertificate', component: PrintCertificate, meta: {title: "打印质量证明书"}},
            {path: '/CertificateData', name: 'CertificateData', component: CertificateData, meta: {title: "证明书基础数据"}},
            {path: '/CertificationOfProduct', name: 'CertificationOfProduct', component: CertificationOfProduct, meta: {title: "证明书"}},
            {path: '/CertificationOfProduct2', name: 'CertificationOfProduct2', component: CertificationOfProduct2, meta: {title: "证明书"}},
            {path: '/ElementManagement', name: 'ElementManagement', component: ElementManagement, meta: {title: "检测项目管理"}},
            {path: '/InputReport', name: 'InputReport', component: InputReport, meta: {title: "录入质检报告"}},
            {path: '/TheTotalInventory', name: 'TheTotalInventory', component: TheTotalInventory, meta: {title: "实时库存管理"}},
        ]
    },
    {path: '/Login', name: 'Login', component: Login, meta: {title: "安山仓库管理系统-登录"}},
    {path: '/HomePage', name: 'HomePage', component: HomePage, meta: {title: "安山仓库管理系统"}},
]
const router = new createRouter({
    history: createWebHashHistory(), // history
    routes: constantRoutes
})

//beforeEach是router的钩子函数，在进入路由前执行
router.beforeEach((to, from, next) => {
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    next()  //执行进入路由，如果不写就不会进入目标页
})
export default router
