<template>
  <div style="width: 100%">
    <div class="subMain">
      <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
        <el-form-item class="row" label="品类名称：">
          <el-select v-model="selectForm.categoryId" placeholder="请选择品类名称" size="mini"
                     clearable @change="clearChange">
            <el-option v-for="(item,index) in codeList" :key="index" :label="item.categoryName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="row" label="规格名称：">
          <el-select v-model="selectForm.specTypeName" placeholder="请选择规格名称" size="mini"
                     clearable @change="clearChange">
            <el-option v-for="(item,index) in specList" :key="index" :label="item.specTypeName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="btn" size="mini" @click="readData">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="main">
      <div style="text-align: right">
        <el-button type="primary" class="addButton" @click="showDrawerFunc('add')" size="mini">新增
        </el-button>
      </div>
      <el-table
          :data="InventoryList"
          border
          stripe
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            align="center"
            label="序号"
            :index="table_index"
            fixed="left"
            width="80">
        </el-table-column>
        <el-table-column
            label="品类编码"
            prop="categoryCode"
            align="center">
        </el-table-column>
        <el-table-column
            label="品类名称"
            prop="categoryName"
            align="center">
        </el-table-column>
        <el-table-column
            label="规格类型编码"
            prop="specTypeCode"
            align="center">
        </el-table-column>
        <el-table-column
            label="规格类型名称"
            prop="specTypeName"
            align="center">
        </el-table-column>
        <el-table-column
            label="规格值"
            prop="specValue"
            align="center">
        </el-table-column>
        <el-table-column
            label="规格状态"
            prop="status"
            align="center">
          <template #default="scope">
            {{ scope.row.status == 0 ? '正常' : '禁用' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            align="center">
          <template #default="scope">
            {{ scope.row.createTime ? dateYMDFormat(scope.row.createTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="更新时间"
            align="center">
          <template #default="scope">
            {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            align="center"
            fixed="right">
          <template v-slot="scope">
            <el-button type="text" size="mini" @click="showDrawerFunc('edit',scope.row)">编辑
            </el-button>
            <el-button type="text" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-sizes="[10,20,30,40]"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
      <!--新增修改-->
      <el-drawer custom-class="drawerMain"
                 v-model="showDrawer"
                 v-if="showDrawer"
                 :title="title"
                 direction="rtl">
        <div class="drawer_content">
          <el-form :model="InventoryForm" :rules="rules" label-position="top" ref="InventoryForm">
            <el-form-item label="品类编码" prop="categoryId">
              <el-select v-model="InventoryForm.categoryId" placeholder="请选择品类编码" size="mini"
                         clearable @change="searchChange">
                <el-option v-for="(item,index) in codeList" :key="index" :label="item.categoryCode"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="品类名称" prop="categoryName">
              <el-input v-model="InventoryForm.categoryName" placeholder="请输入品类名称" size="mini"
                        clearable type="text" maxlength="20" disabled></el-input>
            </el-form-item>
            <el-form-item label="规格类型编码" prop="specTypeId">
              <el-select v-model="InventoryForm.specTypeId" placeholder="请选择规格类型编码" size="mini"
                         clearable @change="changeSearch">
                <el-option v-for="(item,index) in specList" :key="index" :label="item.specTypeCode"
                           :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="规格类型名称" prop="specTypeName">
              <el-input v-model="InventoryForm.specTypeName" placeholder="请输入规格类型名称" size="mini"
                        clearable type="text" maxlength="20" disabled></el-input>
            </el-form-item>
            <el-form-item label="规格值" prop="specValue">
              <el-input v-model="InventoryForm.specValue" placeholder="请输入规格值" size="mini"
                        clearable type="text" maxlength="7"></el-input>
            </el-form-item>
            <el-form-item label="规格状态" prop="status">
              <el-select v-model="InventoryForm.status" placeholder="请选择规格状态" size="mini"
                         clearable>
                <el-option v-for="(item,index) in statusList" :key="index" :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="drawer-footer-height"></div>
          <div class="dialog-footer">
            <el-button size="mini" @click="cancelForm">取 消</el-button>
            <el-button type="primary" size="mini" @click="InventoryClick('InventoryForm',drawerAction)">确 定</el-button>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {statusList} from '../common/baseData';

const _statusList = statusList;
export default {
  name: "TbSpecInfoPage",
  data() {
    return {
      total: 2,
      currPage: 1,
      pageSize: 10,
      selectForm: {categoryId: '', specTypeName: ''},
      codeList: [],
      nameList: [],
      specList: [],
      statusList: _statusList,
      InventoryList: [],
      // 修改
      showDrawer: false,
      InventoryForm: {
        id: '',
        categoryId: '',
        categoryName: '',
        specTypeId: '',
        specTypeName: '',
        specValue: '',
        status: '',
      },
      rules: {
        categoryId: [
          {
            required: true,
            message: '请选择品类编码',
            trigger: 'change'
          }
        ],
        categoryName: [
          {
            required: true,
            message: '请输入品类名称',
            trigger: 'blur'
          }
        ],
        specTypeId: [
          {
            required: true,
            message: '请输入规格类型编码',
            trigger: 'blur'
          }
        ],
        specTypeName: [
          {
            required: true,
            message: '请输入规格类型名称',
            trigger: 'blur'
          }
        ],
        specValue: [
          {
            required: true,
            message: '请输入规格值',
            trigger: 'blur'
          }
        ],
        status: [
          {
            required: true,
            message: '请选择规格状态',
            trigger: 'change'
          }
        ],
      },
      title: '',
      drawerAction: 'add',
    }
  },
  mounted() {
    this.currentPageChanged(this.currPage, this.pageSize);
    this.selectCode();
    this.selectSpec();
  },
  methods: {
    //查询所有品类
    selectCode() {
      this.postFormRequest("/tb-category-info/selectCategory").then(res => {
        if (res.success) {
          this.codeList = res.data;
        }
      }).catch(() => {
      })
    },
    //获取品名的下拉框
    searchChange(value) {
      this.InventoryForm.categoryName = [];
      this.codeList.map(e => {//遍历数据
        if (e.id === value) {
          this.InventoryForm.categoryName = e.categoryName
        }
      })
    },
    //查询所有规格类型
    selectSpec() {
      this.postFormRequest("/tb-spec-type/selectSpec").then(res => {
        if (res.success) {
          this.specList = res.data;
        }
      }).catch(() => {

      })
    },
    changeSearch(value) {
      this.InventoryForm.specTypeName = [];
      this.specList.map(e => {//遍历数据
        if (e.id === value) {
          this.InventoryForm.specTypeName = e.specTypeName
        }
      })
    },
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    // 删除
    handleDelete(id) {
      if (id >= 0) {
        this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.getRequest("/tb-spec-info/delete/" + id).then(res => {
            if (res.success) {
              this.$message.success("删除成功");
              this.currentPageChanged(this.currPage, this.pageSize);
            }
          })
        }).catch(() => {
        })
      }
    },
    //展示抽屉的方式
    showDrawerFunc(action, row) {
      this.selectCode();
      this.selectSpec();
      if (action == 'add') {
        this.title = '新增规格'
        this.drawerAction = 'add'
      } else {
        this.title = '编辑规格'
        this.drawerAction = 'edit'
      }
      this.showDrawer = true;//展开抽屉
      if (row) {
        this.InventoryForm['id'] = row['id'];
        this.InventoryForm['categoryId'] = row['categoryId'];
        this.InventoryForm['categoryName'] = row['categoryName'];
        this.InventoryForm['specTypeId'] = row['specTypeCode'];
        this.InventoryForm['specValue'] = row['specValue'];
        this.InventoryForm['specTypeName'] = row['specTypeName'];
        this.InventoryForm['status'] = row['status'];
      } else {
        this.InventoryForm = {
          id: '',
          categoryId: '',
          categoryName: '',
          specTypeId: '',
          specTypeName: '',
          status: '',
        }
      }
    },
    //提交按钮被点击时
    InventoryClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.postFormRequest("/tb-spec-info/save", this.InventoryForm).then(res => {
              if (res.success) {
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false
                  this.$message.success('添加成功')
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          } else {
            this.postFormRequest("/tb-spec-info/updateById", this.InventoryForm).then(res => {
              if (res.success) {
                this.$message.success('修改成功')
                // 动画关闭需要一定的时间
                setTimeout(() => {
                  this.loading = false;
                  this.showDrawer = false;
                  this.$refs.InventoryForm.resetFields();
                }, 400);
                this.currPage = 1;
                this.currentPageChanged(this.currPage, this.pageSize);
              } else {
                this.loading = false;
                this.$message.error(res.msg)
                return false;
              }
            })
          }
        }
      })
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.showDrawer = false;
      this.$refs.InventoryForm.resetFields();
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);

    },
    currentPageChanged: function (currPage, pageSize) {
      this.selectForm['current'] = currPage
      this.selectForm['size'] = pageSize
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          requestForm[key] = this.selectForm[key]
        }
      }
      this.postFormRequest("/tb-spec-info/listByPage", requestForm
      ).then(res => {
        if (res.success) {
          this.InventoryList = res.data.records
          this.total = res.data.total
        }
      })
    },
  },
  watch: {
    showDrawer: function () {
      if (this.$refs["InventoryForm"] != undefined) {
        this.$refs["InventoryForm"].resetFields();
      }
    }
  },
}
</script>

<style lang="scss">
</style>

