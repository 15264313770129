<template>
  <div style="width: 100%">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="入库明细" name="first">
        <div class="subMain">
          <el-form :inline="true" :model="queryForm" class="el-form-inline" size="mini">
            <el-row>
              <el-col span="8">
                <el-form-item class="row" label="物料名称：">
                  <el-select v-model="queryForm.productId" placeholder="物料名称" size="mini"
                             @change="queryChange"
                             clearable>
                    <el-option v-for="(item,index) in tbProductInfos" :key="index"
                               :label="item.cinvName+' '+item.cinvStd+' '+item.steelGradeName"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="row" label="生产批号：">
                  <el-input v-model="queryForm.batchCode" placeholder="请输入生产批号：" clearable type="text"
                            size="mini" @change="queryChange"></el-input>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="班组：">
                  <el-select v-model="queryForm.classNameId" placeholder="请选择班组" size="mini"
                             @change="queryChange"
                             clearable>
                    <el-option v-for="(item,index) in classNameIdList" :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="row" label="库存地址：">
                  <el-select v-model="queryForm.stockAddrId" placeholder="请选择库存地址" size="mini"
                             @change="queryChange"
                             clearable>
                    <el-option v-for="(item,index) in addressList" :key="index"
                               :label="item.stockName"
                               :value="item.stockId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="入库类型：">
                  <el-select v-model="queryForm.stockInType" placeholder="请选择入库类型" size="mini"
                             @change="queryChange"
                             clearable>
                    <el-option v-for="(item,index) in stockTypeList" :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="row" label="归属日期：" prop="vestingDate">
                  <el-date-picker type="daterange" size="mini" v-model="queryForm.vestingDate"
                                  clearable :editable="false" placeholder="请选择归属日期"
                                  >
<!--                    @change="queryChange"-->
                  </el-date-picker>
                </el-form-item>
                <!--<el-form-item class="row" label="入库时间：" prop="startTime">-->
                  <!--<el-date-picker type="datetime" size="mini" v-model="queryForm.startTime"-->
                                  <!--clearable :editable="false" placeholder="请选择入库时间"-->
                                  <!--@change="queryChange">-->
                  <!--</el-date-picker>-->
                <!--</el-form-item>-->
                <!--<el-form-item label="~" prop="endTime">-->
                  <!--<el-date-picker type="datetime" size="mini" v-model="queryForm.endTime"-->
                                  <!--clearable :editable="false" placeholder="请选择入库时间"-->
                                  <!--@change="queryChange">-->
                  <!--</el-date-picker>-->
                <!--</el-form-item>-->
              </el-col>
              <el-col span="8">
                <el-form-item>
                  <el-button class="btn" type="primary" size="mini" @click="queryData">查询</el-button>
                  <el-button class="btn" type="primary" size="mini" @click="downloadData"
                  >导出</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="main">
          <div style="text-align: right">
            <p style="text-align: left;padding: 10px 0  0 10px;font-size: 16px">总件数:{{ numbers1 }}
              总重:{{ parseFloat((sumActWeight1 ? sumActWeight1 : 0)).toFixed(3) }}(t)
              平均值:{{ parseFloat((avgActWeight1 ? avgActWeight1 : 0)).toFixed(3) }}(t)</p>
          </div>
          <el-table
              :data="queryList"
              class="main-table"
              stripe
              border
              row-key="batchCode"
              :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
              :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
            <el-table-column type="expand">
              <template #default="props">
                <div v-if="props.row.child && props.row.child.length>0">
                  <el-table :data="props.row.child" class="inner-table" border>
                    <el-table-column type="index" width="80px" label="序号" align="center"></el-table-column>
                    <el-table-column prop="bundleCode" label="捆号" align="center"></el-table-column>
                    <el-table-column prop="classesName" label="班次" align="center"></el-table-column>

                    <el-table-column prop="stockName" label="仓库名称" align="center"></el-table-column>
                    <el-table-column
                            prop="theoWeight"
                            label="入库理重(t)"
                            align="center"
                    >
                      <template #default="scope">
                        {{ scope.row.theoWeight ? parseFloat((scope.row.theoWeight/1000 ? scope.row.theoWeight/1000 : 0)).toFixed(3) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="actWeight" label="入库实重(t)" align="center">
                      <template #default="scope">
                        {{ scope.row.actWeight ? parseFloat((scope.row.actWeight/1000 ? scope.row.actWeight/1000 : 0)).toFixed(3) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="stockInType"
                        label="入库类型"
                        align="center">
                      <template #default="scope">
                        {{ scope.row.stockInType ? getFindLable(scope.row.stockInType, stockTypeList) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="stockTime"
                        label="入库时间"
                        align="center"
                        width="170">
                      <template #default="scope">
                        {{ scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                            prop="vestingDate"
                            label="归属日期"
                            align="center">
                      <template #default="scope">
                        {{ scope.row.vestingDate ? dateYMDFormat(scope.row.vestingDate) : '-' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                type="index"
                align="center"
                label="序号"
                :index="table_index"
                width="80">
            </el-table-column>
            <el-table-column
                prop="batchCode"
                label="批号"
                align="center">
            </el-table-column>
            <el-table-column
                prop="invName"
                label="物料名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="steelGradeName"
                label="钢种名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="invStd"
                label="规格"
                align="center">
            </el-table-column>
            <el-table-column
                prop="numbers"
                label="总捆数"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sumActWeight"
                label="批次总重(t)"
                align="center">
              <template #default="scope">
                {{ scope.row.sumActWeight ? parseFloat((scope.row.sumActWeight/1000 ? scope.row.sumActWeight/1000 : 0)).toFixed(3) : '-' }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="elp"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currPage"
                         :page-sizes="[10,20,30,40]"
                         :page-size="pageSize"
                         :total="total"
                         layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="实时库存" name="second">
        <div class="subMain">
          <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
            <el-row>
              <el-col span="8">
                <el-form-item class="row" label="库存地址：">
                  <el-select v-model="selectForm.stockAddrId" placeholder="请选择库存地址" size="mini"
                             @change="changeSearch"
                             clearable>
                    <el-option v-for="(item,index) in addressList" :key="index"
                               :label="item.stockName"
                               :value="item.stockId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="垛位：">
                  <el-select v-model="selectForm.pileId" placeholder="请选择垛位" size="mini"
                             @change="clearChange"
                             clearable>
                    <el-option v-for="(item,index) in stackingList" :key="index" :label="item.pileName"
                               :value="item.pileId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="物料名称规格：">
                  <el-select v-model="selectForm.productId" placeholder="物料名称规格" size="mini"
                             @change="clearChange"
                             clearable>
                    <el-option v-for="(item,index) in tbProductInfos" :key="index"
                               :label="item.cinvName+' '+ (item.cinvStd ? item.cinvStd : '')+' '+item.steelGradeName"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="生产批号：">
                  <el-input v-model="selectForm.batchCode" placeholder="请输入生产批号：" clearable
                            type="text"
                            size="mini" @change="clearChange"></el-input>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="合格类型：">
                  <el-select v-model="selectForm.qualified" placeholder="合格类型" size="mini"
                             @change="clearChange"
                             clearable>
                    <el-option v-for="(item,index) in qualifiedList" :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="班次：">
                  <el-select v-model="selectForm.classNameId" placeholder="班次" size="mini"
                             @change="clearChange"
                             clearable>
                    <el-option v-for="(item,index) in classNameIdList" :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="库存状态：">
                  <el-select v-model="selectForm.stockStatus" placeholder="请选择库存状态" size="mini"
                             @change="clearChange"
                             clearable>
                    <el-option v-for="(item,index) in stockList" :key="index" :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="8">
                <el-form-item class="row" label="归属日期：" prop="vestingDate">
                  <el-date-picker type="daterange" size="mini" v-model="selectForm.vestingDate"
                                  clearable :editable="false" placeholder="归属日期"
                                  >
<!--                    @change="clearChange"-->
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <!--<el-col span="8">
                <el-form-item class="row" label="入库时间：" prop="startStockTime">
                  <el-date-picker type="datetime" size="mini" v-model="selectForm.startStockTime"
                                  clearable :editable="false" placeholder="请选择入库时间"
                                  @change="clearChange">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item label="~" prop="endStockTime">
                  <el-date-picker type="datetime" size="mini" v-model="selectForm.endStockTime"
                                  clearable :editable="false" placeholder="请选择入库时间"
                                  @change="clearChange">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              -->
              <el-col span="8">
                <el-form-item>
                  <el-button class="btn" type="primary" size="mini" @click="readData">查询</el-button>
                  <el-button class="btn" type="primary" size="mini" @click="downloadRtStockData"
                  >导出</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="main">
          <div style="text-align: right">
            <el-button class="addButton" @click="showDialog('add')" size="mini">垛位设置</el-button>
            <p style="text-align: left;padding: 10px 0  0 10px;font-size: 16px">总件数:{{ total2 }}
              总重:{{ parseFloat((sumActWeight2 ? sumActWeight2 : 0)).toFixed(3) }}(t)
              平均值:{{ parseFloat((avgActWeight2 ? avgActWeight2 : 0)).toFixed(3) }}(t)</p>
          </div>
          <el-table
              :data="tbStockList"
              stripe
              ref="tableList"
              border
              :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
              :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
              @select-all="handleSelectionAll"
              @select="handleSelectionChange">
            <el-table-column
                type="selection"
                align="center"
                fixed="left">
            </el-table-column>
            <el-table-column
                type="index"
                align="center"
                label="序号"
                :index="table_index2"
                width="80"
                fixed="left">
            </el-table-column>
            <el-table-column
                prop="batchCode"
                label="批号"
                align="center"
                width="130"
            >
            </el-table-column>
            <el-table-column
                prop="bundleCode"
                label="捆号"
                align="center"
                width="150"
            >
            </el-table-column>
            <el-table-column
                    prop="theoWeight"
                    label="入库理重(t)"
                    align="center"
                    width="100"
            >
              <template #default="scope">
                {{ scope.row.theoWeight ? parseFloat((scope.row.theoWeight/1000 ? scope.row.theoWeight/1000 : 0)).toFixed(3) : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                prop="actWeight"
                label="入库实重(t)"
                align="center"
                width="100"
            >
              <template #default="scope">
                {{ scope.row.actWeight ? parseFloat((scope.row.actWeight/1000 ? scope.row.actWeight/1000 : 0)).toFixed(3) : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                prop="materialName"
                label="物料名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="cinvStd"
                label="物料编码"
                align="center">
            </el-table-column>
            <el-table-column
                prop="specValue"
                label="规格"
                align="center"
                width="130"
            >
            </el-table-column>

            <el-table-column
                prop="classesName"
                label="班次"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="qualified"
                label="合格类型"
                align="center"
            >
              <template #default="scope">
                <p v-if="scope.row.qualified==0">合格</p>
                <p v-if="scope.row.qualified==1">不合格</p>
                <p v-if="scope.row.qualified==2">销售合格</p>
              </template>
            </el-table-column>
            <el-table-column
                prop="cncName"
                label="加工中心"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="stockName"
                label="库存地址"
                align="center">
            </el-table-column>
            <el-table-column
                prop="pileName"
                label="垛位"
                align="center">
              <template #default="scope">
                {{ scope.row.pileName ? scope.row.pileName : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                prop="stockStatus"
                label="库存状态"
                align="center">
              <template #default="scope">
                <p v-if="scope.row.stockStatus==0">已入库</p>
                <p v-if="scope.row.stockStatus==1">出库锁定</p>
                <p v-if="scope.row.stockStatus==2">已出库</p>
                <p v-if="scope.row.stockStatus==3">待入库</p>
              </template>
            </el-table-column>
            <el-table-column
                prop="stockInType"
                label="入库类型"
                align="center">
              <template #default="scope">
                {{ scope.row.stockInType ? getFindLable(scope.row.stockInType, stockTypeList) : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="vestingDate"
                    label="归属日期"
                    align="center">
              <template #default="scope">
                {{ scope.row.vestingDate ? dateYMDFormat(scope.row.vestingDate) : '-' }}
              </template>
            </el-table-column>
            <el-table-column
                prop="stockTime"
                label="入库时间"
                align="center"
                width="170">
              <template #default="scope">
                {{ scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : '-' }}
              </template>
            </el-table-column>
           <!-- <el-table-column
                prop="inExecuteEmpName"
                label="入库操作员"
                align="center"
                width="100"
            >
            </el-table-column>-->
            <el-table-column
                align="center"
                width="170"
                label="操作"
                fixed="right">
              <template v-slot="scope">
                <p v-if="scope.row.stockStatus==0">
                  <el-button @click="showDialog('edit',scope.row)" type="text" size="small">垛位设置
                  </el-button>
                  <el-button @click="changeClick(scope.row.id)" type="text" size="small">库存改判
                  </el-button>
                </p>
                <p v-else>-</p>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="elp"
                         @size-change="handleSizeChange2"
                         @current-change="handleCurrentChange2"
                         :current-page="currPage2"
                         :page-sizes="[10,20,30,40]"
                         :page-size="pageSize2"
                         :total="total2"
                         layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="出库明细" name="third">
        <div class="subMain">
          <el-form :inline="true" :model="warehouseForm" class="el-form-inline" size="mini">
            <el-row>
              <el-col span="8">
                <el-form-item class="row" label="物料名称：">
                  <el-select v-model="warehouseForm.productId" placeholder="物料名称" size="mini"
                             @change="warehouseChange"
                             clearable>
                    <el-option v-for="(item,index) in tbProductInfos" :key="index"
                               :label="item.cinvName+' '+item.cinvStd+' '+item.steelGradeName"
                               :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="row" label="生产批号：">
                  <el-input v-model="warehouseForm.batchCode" placeholder="请输入生产批号：" clearable
                            type="text"
                            size="mini" @change="warehouseChange"></el-input>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="库存地址：">
                  <el-select v-model="warehouseForm.stockAddrId" placeholder="请选择库存地址" size="mini"
                             @change="warehouseChange"
                             clearable>
                    <el-option v-for="(item,index) in addressList" :key="index"
                               :label="item.stockName"
                               :value="item.stockId"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item class="row" label="出库类型：">
                  <el-select v-model="warehouseForm.stockInType" placeholder="请选择出库类型" size="mini"
                             @change="warehouseChange"
                             clearable>
                    <el-option v-for="(item,index) in warehouseList" :key="index"
                               :label="item.label"
                               :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="row" label="出库时间：" prop="startTime">
                  <el-date-picker type="datetime" size="mini" v-model="warehouseForm.startTime"
                                  clearable :editable="false" placeholder="请选择出库时间"
                                  @change="warehouseChange">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="~" prop="endTime">
                  <el-date-picker type="datetime" size="mini" v-model="warehouseForm.endTime"
                                  clearable :editable="false" placeholder="请选择出库时间"
                                  @change="warehouseChange">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col span="8">
                <el-form-item>
                  <el-button class="btn" type="primary" size="mini" @click="warehouseData">查询
                  </el-button>
                  <el-button class="btn" type="primary" size="mini" @click="downloadStockOutData"
                  >导出</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="main">
          <div style="text-align: right">
            <p style="text-align: left;padding: 10px 0  0 10px;font-size: 16px">总件数:{{ numbers3 }}
              总重:{{ parseFloat(sumActWeight3 ? sumActWeight3 : 0).toFixed(3) }}(t)
              平均值:{{ parseFloat((avgActWeight3 ? avgActWeight3 : 0)).toFixed(3) }}(t)</p>
          </div>
          <el-table
              :data="dataList"
              class="main-table"
              stripe
              border
              row-key="batchCode"
              :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
              :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
            <el-table-column type="expand">
              <template #default="props">
                <div v-if="props.row.child && props.row.child.length>0">
                  <el-table :data="props.row.child" class="inner-table" border>
                    <el-table-column type="index" width="80px" label="序号" align="center"></el-table-column>
                    <el-table-column prop="bundleCode" label="捆号" align="center"></el-table-column>
                    <el-table-column prop="stockName" label="仓库名称" align="center"></el-table-column>
                    <el-table-column
                            prop="theoWeight"
                            label="入库理重(t)"
                            align="center"
                    >
                      <template #default="scope">
                        {{ scope.row.theoWeight ? parseFloat((scope.row.theoWeight/1000 ? scope.row.theoWeight/1000 : 0)).toFixed(3) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="actWeight" label="入库实重(t)" align="center">
                      <template #default="scope">
                        {{ scope.row.actWeight ? parseFloat((scope.row.actWeight/1000 ? scope.row.actWeight/1000 : 0)).toFixed(3) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column
                        prop="stockInType"
                        label="出库类型"
                        align="center">
                      <template #default="scope">
                        {{ scope.row.stockInType ? getFindLable(scope.row.stockInType, warehouseList) : '-' }}
                      </template>
                    </el-table-column>
                    <el-table-column prop="empName" label="出库执行人" align="center"></el-table-column>
                    <el-table-column
                        prop="stockTime"
                        label="出库时间"
                        align="center">
                      <template #default="scope">
                        {{ scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : '-' }}
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </template>
            </el-table-column>
            <el-table-column
                type="index"
                align="center"
                label="序号"
                :index="table_index"
                width="80">
            </el-table-column>
            <el-table-column
                prop="batchCode"
                label="批号"
                align="center">
            </el-table-column>
            <el-table-column
                prop="invName"
                label="物料名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="steelGradeName"
                label="钢种名称"
                align="center">
            </el-table-column>
            <el-table-column
                prop="invStd"
                label="规格"
                align="center">
            </el-table-column>
            <el-table-column
                prop="numbers"
                label="总捆数"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sumActWeight"
                label="批次总重(t)"
                align="center">
              <template #default="scope">
                {{ scope.row.sumActWeight ? parseFloat((scope.row.sumActWeight/1000 ? scope.row.sumActWeight/1000 : 0)).toFixed(3) : '-' }}
              </template>
            </el-table-column>
          </el-table>
          <el-pagination class="elp"
                         @size-change="handleSizeChange3"
                         @current-change="handleCurrentChange3"
                         :current-page="currPage3"
                         :page-sizes="[10,20,30,40]"
                         :page-size="pageSize3"
                         :total="total3"
                         layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        custom-class="dialogMain"
        title="垛位设置"
        v-model="dialogVisible"
        v-if="dialogVisible"
        width="40%">
      <el-form :model="processForm" :rules="rules" label-position="top" ref="processForm">
        <el-form-item label="库存地址" prop="stockAddrId">
          <el-select v-model="processForm.stockAddrId" @change="changeSearch($event, true)"
                     placeholder="请选择库存地址"
                     size="mini" clearable>
            <el-option v-for="(item,index) in addressList" :key="index" :label="item.stockName"
                       :value="item.stockId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="垛位" prop="pileId">
          <el-select v-model="processForm.pileId" placeholder="请选择垛位" size="mini" clearable>
            <el-option v-for="(item,index) in stackingList" :key="index" :label="item.pileName"
                       :value="item.pileId"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" size="mini" @click="submitBtnClick('processForm',drawerAction)">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {stockList} from '../common/baseData'; //仓库
import {qualifiedList} from '../common/baseData'; //合格类型
import {classNameIdList} from '../common/baseData'; //班组
import {stockTypeList} from '../common/baseData';//入库类型
import {warehouseList} from '../common/baseData'//出库类型
const _stockList = stockList;
const _qualifiedList = qualifiedList;
const _classNameIdList = classNameIdList;
const _stockTypeList = stockTypeList;
const _warehouseList = warehouseList;

export default {
  name: "StockQuery",
  data() {
    return {
      ruleForm: {
        recordTime: "",
      },
      activeName: 'first',
      //入库
      queryForm: {
        productId: '',
        batchCode: '',
        classNameId: '',
        stockAddrId: '',
        stockInType: '',
        startTime: '',
        vestingBeginDate: null,
        vestingEndDate: null,
        endTime: '',
        vestingDate: []
      },
      queryList: [],
      total: 2,
        numbers1: '',
        numbers2: '',
        numbers3: '',

      sumActWeight1: '',
      sumActWeight2: '',
      sumActWeight3: '',
      avgActWeight1: '',
      avgActWeight2: '',
      avgActWeight3: '',
      currPage: 1,
      pageSize: 10,
      //实时
      total2: 2,
      currPage2: 1,
      pageSize2: 10,
      tbProductInfos: [],
      cribList: [],
      selectForm: {
        batchCode: '',
        pileId: '',
        stockAddrId: '',
        qualified: '',
        classNameId: '',
        productId: '',
        stockStatus: '',
        startStockTime: '',
        endStockTime: '',
        vestingBeginDate:null,
        vestingEndDate: null,
          vestingDate: [],
      },
      drawerAction: 'add',
      addressList: [],
      tbStockList: [],
      stackingList: [],
      stockList: _stockList,
      qualifiedList: _qualifiedList,
      classNameIdList: _classNameIdList,
      stockTypeList: _stockTypeList,
      warehouseList: _warehouseList,
      dialogVisible: false,
      title: '',
      processForm: {
        id: '',
        stockAddrId: '',
        pileId: '',
        isReverse: '',
      },
      loading: false,
      rules: {
        stockAddrId: [
          {
            required: true,
            message: '请选择库存地址',
            trigger: 'change'
          }
        ],
        pileId: [
          {
            required: true,
            message: '请选择垛位',
            trigger: 'change'
          }
        ],
      },
      multipleSelection: null,
      //出库
      warehouseForm: {
        productId: '',
        batchCode: '',
        stockAddrId: '',
        stockInType: '',
        startTime: '',
        endTime: ''
      },
      dataList: [],
      total3: 2,
      currPage3: 1,
      pageSize3: 10,
    }
  },
  computed: {
    weight: function () {
      let a = 0;
      if (this.multipleSelection.length > 0) {
        this.multipleSelection.forEach(info => {
          a += info.actWeight
        })
        return a;
      }
      console.log(this.multipleSelection.length)
      return 0;
    },
  },
  mounted() {
    this.getNowTime();
    this.currentPageChanged(this.currPage, this.pageSize);
    // this.getCategory();
    this.getAddress();
    this.materialCode();
    this.getCribList();

  },
  methods: {
    //下载库存明细数据
    downloadStockOutData(){
      let requestForm = {}
      for (let key in this.warehouseForm) {
        if (this.warehouseForm[key]) {
          if (key == 'startTime') {
            requestForm[key] = this.warehouseForm[key] ? this.dateYMDhmsFormat(this.warehouseForm[key]) : ''
          } else if (key == 'endTime') {
            requestForm[key] = this.warehouseForm[key] ? this.dateYMDhmsFormat(this.warehouseForm[key]) : ''
          } else {
            requestForm[key] = this.warehouseForm[key]
          }
        }
      }
      this.postDownload("/bus/tb-stock-real/exportStockoutExcel",requestForm).then(res=>{
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', '出库明细信息表')
        a.click()
      });
    },
    //下载实时库存数据
    downloadRtStockData(){
      let requestForm = {}
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          if (key == 'startTime') {
            requestForm[key] = this.selectForm[key] ? this.dateYMDhmsFormat(this.selectForm[key]) : ''
          } else if (key == 'endTime') {
            requestForm[key] = this.selectForm[key] ? this.dateYMDhmsFormat(this.selectForm[key]) : ''
          } else if (key == 'vestingDate') {
            if(this.selectForm[key][0]){
              requestForm['vestingBeginDate'] =  this.dateYMDFormat(this.selectForm[key][0]);
            }

            if(this.selectForm[key][1]){
              requestForm['vestingEndDate'] =  this.dateYMDFormat(this.selectForm[key][1]);
            }
          } else {
              requestForm[key] = this.selectForm[key]
          }
        }
      }
      this.postDownload("/bus/tb-stock-real/exportRtStockExcel",requestForm).then(res=>{
        const url = window.URL.createObjectURL(res)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', '实时库存信息表')
        a.click()
      });
    },
    //导出入库明细数据
      downloadData(){
          let requestForm = {}
          for (let key in this.queryForm) {
              if (this.queryForm[key]) {
                  if (key == 'startTime') {
                      requestForm[key] = this.queryForm[key] ? this.dateYMDhmsFormat(this.queryForm[key]) : ''
                  } else if (key == 'endTime') {
                      requestForm[key] = this.queryForm[key] ? this.dateYMDhmsFormat(this.queryForm[key]) : ''
                  } else if (key == 'vestingDate') {
                    if(this.queryForm[key][0]){
                      requestForm['vestingBeginDate'] =  this.dateYMDFormat(this.queryForm[key][0]);
                    }

                    if(this.queryForm[key][1]){
                      requestForm['vestingEndDate'] =  this.dateYMDFormat(this.queryForm[key][1]);
                    }
                  } else {
                      requestForm[key] = this.queryForm[key]
                  }
              }
          }
          this.postDownload("/bus/tb-stock-in/exportStockInExcel",requestForm).then(res=>{
              const url = window.URL.createObjectURL(res)
              const a = document.createElement('a')
              a.setAttribute('href', url)
              a.setAttribute('download', '库存信息表')
              a.click()
          });
      },
    getNowTime() {
      let date = new Date()
      let dates = new Date(date.getTime() - 24 * 60 * 60 * 1000)
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      let r = date.getDate()
      let s = "07:20:00"

      let ys = dates.getFullYear()
      let ms = dates.getMonth() + 1
      let rs = dates.getDate()


      let end = y + '-' + m + '-' + r + ' ' + s
      let start = ys + '-' + ms + '-' + rs + ' ' + s
     /* this.queryForm.startTime = start
      this.queryForm.endTime = end*/
        this.queryForm.vestingDate[0] = dates
      this.queryForm.vestingDate[1] = date
     /* this.selectForm.startStockTime = start
      this.selectForm.endStockTime = end*/
        this.selectForm.vestingDate[0] = dates
      this.selectForm.vestingDate[1] = date
      this.warehouseForm.startTime = start
      this.warehouseForm.endTime = end
    },

    //Tabs切换
    handleClick(tab) {
      if (tab.index == 0) {
        this.handleSizeChange(this.pageSize);
      } else if (tab.index == 1) {
        this.handleSizeChange2(this.pageSize2);
      } else {
        this.handleSizeChange3(this.pageSize3);
      }
    },
    //入库查询
    queryData() {
      this.handleCurrentChange(1);
    },
    //入库查询清除
    queryChange() {
      this.handleCurrentChange(1);
    },
    //入库分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.currentPageChanged(this.currPage, this.pageSize);
    },
    currentPageChanged: function (currPage, pageSize) {
      let requestForm = {}
      requestForm['current'] = currPage;
      requestForm['size'] = pageSize;
      for (let key in this.queryForm) {
        if (this.queryForm[key]) {
          if (key == 'startTime') {
            requestForm[key] = this.queryForm[key] ? this.dateYMDhmsFormat(this.queryForm[key]) : ''
          } else if (key == 'endTime') {
            requestForm[key] = this.queryForm[key] ? this.dateYMDhmsFormat(this.queryForm[key]) : ''
          } else if (key == 'vestingDate') {
              //requestForm[key] = this.queryForm[key] ? this.dateYMDFormat(this.queryForm[key]) : ''
            if(this.queryForm[key][0]){
              requestForm['vestingBeginDate'] =  this.dateYMDFormat(this.queryForm[key][0]);
            }

            if(this.queryForm[key][1]){
              requestForm['vestingEndDate'] =  this.dateYMDFormat(this.queryForm[key][1]);
            }
          } else {
            requestForm[key] = this.queryForm[key]
          }
        }
      }
      console.log(requestForm);
      this.postFormRequest("/bus/tb-stock-in/storageRecord", requestForm).then(res => {
        if (res.success) {
          this.queryList = res.data.list.records
          this.total = res.data.list.total
          if (res.data.sumAndAverage[0]) {
            this.sumActWeight1 = res.data.sumAndAverage[0].actWeight / 1000
            this.avgActWeight1 = res.data.sumAndAverage[0].avgactWeight / 1000
            this.numbers1 = res.data.sumAndAverage[0].numbers
          }

        }
      })
    },
    //实时库存
    //清空查询自动加载列表
    readData() {
      this.handleCurrentChange2(1);
    },
    //清空查询自动加载列表
    clearChange() {
      this.handleCurrentChange2(1);
    },
    //实时分页
    table_index2(index) {
      return (this.currPage2 - 1) * this.pageSize2 + index + 1
    },
    handleSizeChange2: function (pageSize) {
      this.pageSize2 = pageSize;
      this.handleCurrentChange2(this.currPage2);
    },
    handleCurrentChange2: function (currPage) {
      this.currPage2 = currPage;
      this.currentPageChanged2(this.currPage2, this.pageSize2);
    },
    currentPageChanged2: function (currPage, pageSize) {
      let requestForm = {}
      requestForm['current'] = currPage;
      requestForm['size'] = pageSize;
      for (let key in this.selectForm) {
        if (this.selectForm[key]) {
          if (key == 'endStockTime') {
            requestForm[key] = this.selectForm[key] ? this.dateYMDhmsFormat(this.selectForm[key]) : ''
          } else if (key == 'startStockTime') {
            requestForm[key] = this.selectForm[key] ? this.dateYMDhmsFormat(this.selectForm[key]) : ''
          }else if (key == 'vestingDate') {
              //requestForm[key] = this.selectForm[key] ? this.dateYMDFormat(this.selectForm[key]) : ''
            if(this.selectForm[key][0]){
              requestForm['vestingBeginDate'] =  this.dateYMDFormat(this.selectForm[key][0]);
            }

            if(this.selectForm[key][1]){
              requestForm['vestingEndDate'] =  this.dateYMDFormat(this.selectForm[key][1]);
            }
          } else {
              requestForm[key] = this.selectForm[key]
          }
        }
      }
      this.postFormRequest("/bus/tb-stock-real/listByPage", requestForm).then(res => {
        if (res.success) {
          this.tbStockList = res.data.pages.records
          this.total2 = res.data.pages.total
          if (res.data.sumAndAverage[0]) {
            this.sumActWeight2 = res.data.sumAndAverage[0].actWeight / 1000,
                this.avgActWeight2 = res.data.sumAndAverage[0].avgactWeight / 1000
          }else {
              this.sumActWeight2 = 0,
                  this.avgActWeight2 = 0
          }

        }
      })
    },
    // 分页 end
    //查询品类
    // getCategory() {
    //   this.postFormRequest("/bus/tb-stock-in/selectStackTheStorageSpace").then(res => {
    //     if (res.success) {
    //       this.categoryList = res.data.tbCategoryInfos;
    //     }
    //   })
    // },
    //查询仓库和垛位
    getAddress() {
      this.postFormRequest("/bus/tb-stock-carry-forward/realTimeInventoryMonitoring").then(res => {
        if (res.success) {
          this.addressList = res.data;
        }
      })
    },
    //垛位
    getCribList() {
      this.postFormRequest("/tb-pile-info/cribList").then(res => {
        if (res.success) {
          this.cribList = res.data;
        }
      })
    },
    //查询编码名称和规格
    materialCode() {
      this.postFormRequest("/bus/tb-stock-real/materialCode").then(res => {
        if (res.success) {
          this.tbProductInfos = res.data;
        }
      })
    },
    // 处理序号=》倒序
    indexMethods(index) {
      // currentpage当前页码，this.tableData.length总条数，index索引值
      if (this.tableData.length < 10) {
        return (this.tableData.length - index)
      } else {
        return (this.tableData.length - ((this.currentpage - 1) * 10) - index)
      }
    },
    //点击事件
    changeSearch($event, isSelectChange) {
      this.addressList.map((v) => {
        if (v.stockId === $event) {
          this.stackingList = v.children;
          isSelectChange && (this.processForm.pileId = "");

        }
      });
    },
    //展示抽屉的方式
    showDialog(action, row) {
      this.getAddress();
      if (action == 'add') {
        this.drawerAction = 'add'
      } else {
        this.drawerAction = 'edit'
      }
      this.dialogVisible = true;//展开抽屉
      if (row) {
        this.processForm['id'] = row['id'];
        this.processForm['stockAddrId'] = row['stockAddrId'];
        this.processForm['pileId'] = row['pileId'];
        this.changeSearch(this.processForm['stockAddrId']);
        this.processForm['isReverse'] = row['isReverse'];
        this.processForm['pileName'] = row['pileName'];
      } else {
        this.processForm = {
          id: '',
          stockAddrId: '',
          pileId: '',
          isReverse: '',
        }
      }
    },
    handleSelectionAll(selection) {
      if (selection.length > 0) {
        this.multipleSelection = [];
        let unselectItems = [];
        selection.forEach(item => {
          if (item.stockStatus != 0) {
            unselectItems.push(item);
          } else {
            this.multipleSelection.push(item);
          }
        })
        unselectItems.forEach(item => {
          this.$refs.tableList.toggleRowSelection(item, false);
        })
      } else {
        this.multipleSelection = [];
      }
    },
    //获取列表勾选框信息
    handleSelectionChange(selection, row) {
      if (selection.length > 0) {
        if (row.stockStatus != 0) {
          this.$refs.tableList.toggleRowSelection(row, false);
          return;
        }
        if (!this.multipleSelection) {
          this.multipleSelection = [];
        }
        this.multipleSelection.push(row);
      } else {
        this.multipleSelection.forEach((ele, index) => {
          if (ele.id == row.id) {
            this.multipleSelection.splice(index, 1);
          }
        })
      }
    },
    addHandle() {
      if (this.multipleSelection == null || this.multipleSelection.length == 0) {
        this.$message.warning("请勾选列表")
        return
      }
      let ids = [];
      let result = this.handleRepoAreaAndStatus();
      let existInValid = this.handleStack();
      if (result.hasDiffrent) {
        this.$message.warning("仓库地址不一致无法批量修改垛位")
        return
      }
      if (existInValid) {
        this.$message.warning("有垛位和没有垛位的不能一起批量修改垛位")
        return
      }
      if (result.existInvalid) {
        this.$message.warning("选中的有出库锁定或已出库的，请重新勾选")
        return
      }
      let pileName = [];
      let totalWeight = 0;
      this.multipleSelection.forEach((ele => {
        ids.push(ele.id);
        pileName.push(ele.pileName)
        totalWeight += ele.countNumber;
      }));
      let arr = this.stackingList.filter(ele => {
        return ele.pileId == this.processForm.pileId;
      })
      if (totalWeight > arr[0].upperLimit) {
        this.$message.warning("捆数超过上限");
        return;
      }
      this.postRequest("/bus/tb-stock-real/updateStockPileInfo", {
        id: ids,
        stockAddrId: this.processForm.stockAddrId,
        pileId: this.processForm.pileId,
        isReverse: (!(pileName == undefined || pileName == '' || pileName == null || false)),
      }).then(res => {
        if (res.success) {
          // 动画关闭需要一定的时间
          setTimeout(() => {
            this.loading = false;
            this.dialogVisible = false
            this.$message.success('修改成功')
            this.$refs.processForm.resetFields();
          }, 400);
          this.currPage = 1;
          this.currentPageChanged(this.currPage, this.pageSize);
        } else {
          this.loading = false;
          return false;
        }
      })
    },
    nonAddHandle() {
      let pileName = this.processForm.pileName;
      let ids = [];
      let totalWeight = 0;
      this.tbStockList.forEach(ele => {
        if (ele.id == this.processForm.id) {
          totalWeight = ele.upperLimit;
        }
      });
      let arr = this.stackingList.filter(ele => {
        return ele.pileId == this.processForm.pileId;
      })
      if (totalWeight > arr[0].upperLimit) {
        this.$message.warning("捆数超过上限");
        return;
      }
      ids.push(this.processForm.id);
      this.postRequest("/bus/tb-stock-real/updateStockPileInfo", {
        id: ids,
        stockAddrId: this.processForm.stockAddrId,
        pileId: this.processForm.pileId,
        isReverse: (!(pileName == '' || pileName == null || false)),
      }).then(res => {
        if (res.success) {
          // 动画关闭需要一定的时间
          setTimeout(() => {
            this.loading = false;
            this.dialogVisible = false;
            this.$refs.processForm.resetFields();
          }, 400);
          this.currPage = 1;
          this.currentPageChanged2(this.currPage, this.pageSize);
        } else {
          this.loading = false;
          return false;
        }
      })
    },
    //提交按钮被点击时
    submitBtnClick(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (action == 'add') {
            this.addHandle();
          } else {
            this.nonAddHandle();
          }
        }
      })
    },
    //判断选中的行中是否有不同库区的记录 和 有出库锁定或已出库的记录
    handleRepoAreaAndStatus() {
      let hasDiffrent = false, existInvalid = false;
      let name = null;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        let ele = this.multipleSelection[i];
        if (name == null) {
          name = ele.stockName
        } else {
          !hasDiffrent && (hasDiffrent = name != ele.stockName);
          if (hasDiffrent && existInvalid) {
            break;
          }
        }
        let status = ele.stockStatus
        if ((status == 1 || status == 2) && !existInvalid) {
          existInvalid = true;
          if (hasDiffrent) {
            break;
          }
        }
      }
      return {hasDiffrent: hasDiffrent, existInvalid: existInvalid}
    },
    //判断选中的行中是否有垛位有名称和没有名称共存的情况
    handleStack() {
      let existInvalid = false;
      let name = null;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        let ele = this.multipleSelection[i];
        if (i == 0) {
          name = ele.pileName;
        } else {
          existInvalid = name ? (ele.pileName ? false : true) : (ele.pileName ? true : false);
          if (existInvalid) {
            break;
          }
        }
      }
      return existInvalid
    },
    //库存改判
    changeClick(id) {
      if (id >= 0) {
        this.$confirm('确认要改判吗?改判后信息将改成不合格', '改判提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.postFormRequest("/bus/tb-stock-real/changeProductStatus", {
            id: id
          }).then(res => {
            if (res.success) {
              this.$message.success("改判成功");
              this.readData();
            }
          })
        }).catch(() => {
        })
      }
    },
    //取消按钮
    cancelForm() {
      this.form = {}
      this.loading = false;
      this.dialogVisible = false;
      this.$refs.processForm.resetFields();
    },
    //出库查询
    warehouseData() {
      this.handleCurrentChange3(1);
    },
    //出库查询清除
    warehouseChange() {
      this.handleCurrentChange3(1);
    },
    //出库分页
    table_index3(index) {
      return (this.currPage3 - 1) * this.pageSize3 + index + 1
    },
    handleSizeChange3: function (pageSize) {
      this.pageSize3 = pageSize;
      this.handleCurrentChange3(this.currPage3);
    },
    handleCurrentChange3: function (currPage) {
      this.currPage3 = currPage;
      this.currentPageChanged3(this.currPage3, this.pageSize3);
    },
    currentPageChanged3: function (currPage, pageSize) {
      let requestForm = {}
      requestForm['current'] = currPage;
      requestForm['size'] = pageSize;
      for (let key in this.warehouseForm) {
        if (this.warehouseForm[key]) {
          if (key == 'startTime') {
            requestForm[key] = this.warehouseForm[key] ? this.dateYMDhmsFormat(this.warehouseForm[key]) : ''
          } else if (key == 'endTime') {
            requestForm[key] = this.warehouseForm[key] ? this.dateYMDhmsFormat(this.warehouseForm[key]) : ''
          } else {
            requestForm[key] = this.warehouseForm[key]
          }
        }
      }
      this.postFormRequest("/bus/tb-stock-real/stockOutRecord", requestForm).then(res => {
        if (res.success) {
          this.dataList = res.data.pages.records
          this.total3 = res.data.pages.total
          if (res.data.sumAndAverage[0]) {
            this.sumActWeight3 = res.data.sumAndAverage[0].actWeight / 1000
            this.avgActWeight3 = res.data.sumAndAverage[0].avgactWeight / 1000
              this.numbers3 = res.data.sumAndAverage[0].sumbers
          }

        }
      })
    },
  },
  watch: {
    dialogVisible: function () {
      if (this.$refs["processForm"] != undefined) {
        this.$refs["processForm"].resetFields();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.elp {
  text-align: right;
}

/deep/ .inner-table {
  margin: 0;
  padding: 0;

  th, tr, td {
    margin: 0;
    padding: 0;
    background: #e1e1e1;
  }
}

.main-table {
  height: calc(100vh - 15rem);
  overflow-y: scroll;
}

.main-table::before {
  display: none;
}

.main-table-nopage {
  height: calc(100vh - 9.5rem);
}

.main-table-nopage-nosubmain {
  height: calc(100vh - 5.5rem);
}

.no-empty {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

}

.table-header {
  background: #DEEBF6;
  color: #000000;
}

/deep/ .el-table__expanded-cell {
  padding: 0;
}
</style>

