<template>
    <div style="width: 100%">
        <div class="subMain">
            <el-form :inline="true" :model="selectForm" class="el-form-inline" size="mini">
                <el-form-item class="row" label="标牌名称">
                    <el-select v-model="selectForm.signName" placeholder="请选择标牌名称" size="mini"
                               @change="clearChange"
                               clearable>
                        <el-option v-for="(item,index) in signNames" :key="index" :label="item.signName"
                                   :value="item.signName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="row" label="打印机名称:">
                    <el-select v-model="selectForm.printName" placeholder="请选择打印机名称" size="mini"
                               @change="clearChange"
                               clearable>
                        <el-option v-for="(item,index) in printNames" :key="index" :label="item.printName"
                                   :value="item.printName"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="btn" type="primary" size="mini" @click="clearChange">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main">
            <div style="text-align: right">
                <el-button class="addButton" type="primary" @click="showDrawerFunc('add')" size="mini">新增标牌信息
                </el-button>
            </div>
            <el-table
                    :data="tbLabelInfoList"
                    stripe
                    border
                    :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
                    :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
                <el-table-column
                        type="index"
                        align="center"
                        label="序号"
                        fixed="left"
                        :index="table_index"
                        width="80">
                </el-table-column>
                <!--<el-table-column
                    prop="labelsName"
                    label="标牌类型"
                    align="center">
                </el-table-column>-->
                <el-table-column
                        prop="fieldName"
                        label="项目类型"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="fieldAbscissa"
                        label="字段横坐标"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="fieldOrdinate"
                        label="字段纵坐标"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="plantName"
                        label="加工中心名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="font"
                        label="字体样式"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="size"
                        label="字体大小"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="printCode"
                        label="打印机编号"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="printName"
                        label="打印机名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="signName"
                        label="标牌名称"
                        align="center">
                </el-table-column>
                <el-table-column
                        prop="updateTime"
                        label="更新时间"
                        align="center">
                    <template #default="scope">
                        {{ scope.row.updateTime ? dateYMDFormat(scope.row.updateTime) : '-' }}
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        width="120"
                        fixed="right"
                        label="操作">
                    <template v-slot="scope">
                        <el-button @click="showDrawerFunc('edit',scope.row)" type="text" size="small">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="elp"
                           @size-change="handleSizeChange"
                           @current-change="handleCurrentChange"
                           :current-page="currPage"
                           :page-sizes="[10,20,30,40]"
                           :page-size="pageSize"
                           :total="total"
                           layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>

        <el-drawer custom-class="drawerMain"
                   v-model="showDrawer"
                   v-if="showDrawer"
                   :title="title"
                   size="520px"
                   direction="rtl">
            <div class="drawer_content">
                <el-form :model="tbLabelInfoForm" :rules="rules" label-position="top" ref="tbLabelInfoForm">
                    <!--  <el-form-item label="标牌类型" prop="labelsName">
                        <el-input v-model="tbLabelInfoForm.labelsName" placeholder="请填写标牌类型" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                      </el-form-item>-->
                    <el-form-item label="字段名称" prop="fieldName">
                        <el-input v-model="tbLabelInfoForm.fieldName" placeholder="请填写字段名称" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="字段纵坐标" prop="fieldOrdinate">
                        <el-input v-model="tbLabelInfoForm.fieldOrdinate" placeholder="请填写字段纵坐标" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="字段横坐标" prop="fieldAbscissa">
                        <el-input v-model="tbLabelInfoForm.fieldAbscissa" placeholder="请填写字段横坐标" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>

                    <el-form-item label="加工中心名称" prop="plantId">

                        <el-select v-model="tbLabelInfoForm.plantId" placeholder="请选择加工中心名称" size="mini"
                                   clearable>
                            <el-option v-for="(item,index) in tbCnclist" :key="index" :label="item.cncName"
                                       :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="字体样式" prop="font">
                        <el-input v-model="tbLabelInfoForm.font" placeholder="请填写字体样式" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="字体大小" prop="size">
                        <el-input v-model="tbLabelInfoForm.size" placeholder="请填写字体大小" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="打印机编号" prop="printCode">
                        <el-input v-model="tbLabelInfoForm.printCode" placeholder="请填写打印机编号" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                    <el-form-item label="打印机名称" prop="printName">
                        <el-input v-model="tbLabelInfoForm.printName" placeholder="请填写打印机名称" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>

                    <el-form-item label="标牌名称" prop="signName">
                        <el-input v-model="tbLabelInfoForm.signName" placeholder="请填写标牌名称" clearable type="text"
                                  size="mini" maxlength="20"></el-input>
                    </el-form-item>
                </el-form>
                <div class="drawer-footer-height"></div>
                <div class="dialog-footer">
                    <el-button @click="cancelForm" size="mini">取 消</el-button>
                    <el-button type="primary" size="mini" @click="submitBtnClick('tbLabelInfoForm',drawerAction)"
                               :loading="loading">{{ loading ? '提交中...' : '确 定' }}
                    </el-button>
                </div>
            </div>

        </el-drawer>
    </div>
</template>

<script>
    export default {
        name: "TbLabelInfoPage",
        data() {
            const blurText = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('标题不能为空'))
                } else {
                    if (this.rowInfo) {
                        if (this.rowInfo.labelsName == value) {
                            callback()
                        }
                    }
                    this.postFormRequest("/tb-label-info/exists", {labelsName: value}).then(data => {
                        if (data.data) {
                            callback(new Error('标题存在'))
                        } else {
                            callback()
                        }
                    });
                }
            }
            return {
                total: 2,
                currPage: 1,
                pageSize: 10,
                tbLabelInfoList: [],
                rowInfo: null,
                showDrawer: false,
                tbCnclist: [],
                printNames: [],
                signNames: [],
                title: '',
                tbLabelInfoForm: {
                    id: '',
                    labelsName: '',
                    fieldName: '',
                    fieldOrdinate: '',
                    fieldAbscissa: '',
                    signName: '',
                    plantId: '',
                    font: '',
                    size: '',
                    printCode: '',
                    printName: '',
                },
                selectForm: {
                    signName: '',
                    printName: ''
                },
                loading: false,
                drawerAction: 'add',
                rules: {
                    labelsName: [
                        {
                            required: true,
                            message: '请输入类型',
                            trigger: 'blur'
                        },
                        {validator: blurText, trigger: 'blur'}
                    ],
                    fieldName: [
                        {
                            required: true,
                            message: '请输入字段名称',
                            trigger: 'blur'
                        }
                    ],
                    fieldOrdinate: [
                        {
                            required: true,
                            message: '请填写字段纵坐标',
                            trigger: 'blur'
                        }
                    ],
                    fieldAbscissa: [
                        {
                            required: true,
                            message: '请填写字段横坐标',
                            trigger: 'blur'
                        }
                    ],
                    plantId: [
                        {
                            required: true,
                            message: '请选择加工中心名称',
                            trigger: 'blur'
                        }
                    ],

                    font: [
                        {
                            required: true,
                            message: '请填写字体样式',
                            trigger: 'blur'
                        }
                    ],
                    size: [
                        {
                            required: true,
                            message: '请填写字体大小',
                            trigger: 'blur'
                        }
                    ],
                    printCode: [
                        {
                            required: true,
                            message: '请填写打印机编号',
                            trigger: 'blur'
                        }
                    ],
                    printName: [
                        {
                            required: true,
                            message: '请填写打印机名称',
                            trigger: 'blur'
                        }
                    ],
                    signName: [
                        {
                            required: true,
                            message: '请填写标牌名称',
                            trigger: 'blur'
                        }
                    ]
                }
            }
        },

        mounted() {
            this.queryConditionLists();
        },
        methods: {
            //清空查询自动加载列表
            clearChange() {
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            //查询
            readData() {
                this.handleCurrentChange(1);
            },
            listCncInfo() {
                this.postFormRequest("/tb-label-info/listCncInfo").then(res => {
                    if (res.success) {
                        this.tbCnclist = res.data
                    }

                })
            },

            //查询条件
            queryConditionLists() {
                this.postFormRequest("/tb-label-info/queryCondition").then(res => {
                    if (res.success) {
                        this.printNames = res.data.printName;
                        this.signNames = res.data.signNames;
                        if (this.printNames[0]){
                            this.selectForm.printName = this.printNames[0].printName
                        }
                        this.readData();
                    }

                })
            },
            // 删除
            handleDelete(id) {
                if (id >= 0) {
                    this.$confirm('确认要删除吗?删除后信息将不可恢复', '删除提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.getRequest("/tb-label-info/delete/" + id).then(res => {
                            if (res.success) {
                                this.$message.success('删除成功');
                                this.currentPageChanged(this.currPage, this.pageSize);
                            }
                        })
                    }).catch(() => {
                    })
                }
            },
            //展示抽屉的方式
            showDrawerFunc(action, row) {
                this.rowInfo = null;
                if (action == 'add') {
                    this.title = '新增信息'
                    this.drawerAction = 'add'
                } else {
                    this.title = '修改信息'
                    this.drawerAction = 'edit'
                }
                this.listCncInfo();
                this.showDrawer = true;//展开抽屉
                if (row) {
                    this.rowInfo = row;
                    this.tbLabelInfoForm['id'] = row['id'];
                    this.tbLabelInfoForm['labelsName'] = row['labelsName'];
                    this.tbLabelInfoForm['fieldName'] = row['fieldName'];
                    this.tbLabelInfoForm['fieldOrdinate'] = row['fieldOrdinate'];
                    this.tbLabelInfoForm['fieldAbscissa'] = row['fieldAbscissa'];
                    this.tbLabelInfoForm['plantId'] = row['plantId'];
                    this.tbLabelInfoForm['font'] = row['font'];
                    this.tbLabelInfoForm['size'] = row['size'];
                    this.tbLabelInfoForm['signName'] = row['signName'];
                    this.tbLabelInfoForm['printCode'] = row['printCode'];
                    this.tbLabelInfoForm['printName'] = row['printName'];
                } else {
                    this.tbLabelInfoForm = {
                        id: '',
                        labelsName: '',
                        fieldName: '',
                        fieldOrdinate: '',
                        fieldAbscissa: '',
                        plantId: '',
                        font: '',
                        size: '',
                        printCode: '',
                        printName: '',
                        signName: '',
                    }
                }
            },
            //提交按钮被点击时
            submitBtnClick(formName, action) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        if (action == 'add') {
                            this.postFormRequest("/tb-label-info/save", this.tbLabelInfoForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false
                                        this.$message.success('添加成功')
                                        this.$refs.tbLabelInfoForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        } else {
                            this.postFormRequest("/tb-label-info/updateById", this.tbLabelInfoForm).then(res => {
                                if (res.success) {
                                    // 动画关闭需要一定的时间
                                    setTimeout(() => {
                                        this.loading = false;
                                        this.showDrawer = false;
                                        this.$refs.tbLabelInfoForm.resetFields();
                                    }, 400);
                                    this.currPage = 1;
                                    this.currentPageChanged(this.currPage, this.pageSize);
                                } else {
                                    this.loading = false;
                                    return false;
                                }
                            })
                        }
                    }
                })
            },
            //取消按钮
            cancelForm() {
                this.form = {}
                this.loading = false;
                this.showDrawer = false;
                this.$refs.tbLabelInfoForm.resetFields();
            },
            // 分页
            table_index(index) {
                return (this.currPage - 1) * this.pageSize + index + 1
            },
            handleSizeChange: function (pageSize) {
                this.pageSize = pageSize;
                this.handleCurrentChange(this.currPage);
            },
            handleCurrentChange: function (currPage) {
                this.currPage = currPage;
                this.currentPageChanged(this.currPage, this.pageSize);
            },
            currentPageChanged: function (currPage, pageSize) {
                this.selectForm['current'] = currPage
                this.selectForm['size'] = pageSize
                let requestForm = {}
                for (let key in this.selectForm) {
                    if (this.selectForm[key]) {
                        requestForm[key] = this.selectForm[key]
                    }
                }
                this.postFormRequest("/tb-label-info/listByPage", requestForm).then(res => {
                    if (res.success) {
                        this.tbLabelInfoList = res.data.records
                        this.total = res.data.total
                    }

                })
            },
            // 分页 end
        },
        watch: {
            showDrawer: function () {
                if (this.$refs["tbLabelInfoForm"] != undefined) {
                    this.$refs["tbLabelInfoForm"].resetFields();
                }
            }
        }
    }
</script>

<style scoped>
    /*抽屉主体样式*/
    .drawer_content {
        margin: 10px;
        /*height: 100vh;*/
        height: calc(100% - 200px);
    }

    .elp {
        text-align: right;
    }


</style>

