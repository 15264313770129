<template>
  <div>
    <div ref="Columnar" class="columnar" @click="detailsClick"></div>
    <!--    明细-->
    <el-dialog
        custom-class="dialogMain"
        title="库存明细"
        v-model="dialogVisible"
        :show-close="false"
        v-if="dialogVisible"
        fullscreen>
      <div style="float: right">
        <el-button type="primary" size="small" class="addButton" @click="dialogVisible=false">关闭</el-button>
      </div>
      <el-table
          :data="dataList"
          stripe
          border
          max-height="600px"
          :header-row-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}"
          :header-cell-style="{background:'#DEEBF6',color:'#000000',height:'50px',fontWeight:'Medium'}">
        <el-table-column
            type="index"
            :index="table_index"
            label="序号"
            align="center"
            width="80px">
        </el-table-column>
        <el-table-column
            prop="batchCode"
            label="批号"
            align="center">
        </el-table-column>
        <el-table-column
            align="center"
            label="捆号"
            prop="bundleCode"
            width="130">
        </el-table-column>
        <el-table-column
            prop="materialName"
            label="物料名称"
            align="center"
            width="120">
        </el-table-column>
        <el-table-column
            prop="cinvStd"
            label="规格"
            align="center"
            width="130">
        </el-table-column>
        <el-table-column
            prop="steelGradeName"
            label="钢种名称"
            align="center">
        </el-table-column>
        <el-table-column
                prop="theoWeight"
                label="入库理重(t)"
                align="center"
                width="120"
        >
          <template #default="scope">
            {{ scope.row.theoWeight ? parseFloat((scope.row.theoWeight/1000 ? scope.row.theoWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>

        <el-table-column
            prop="actWeight"
            label="入库实重(t)"
            align="center"
            width="120">
          <template #default="scope">
            {{ scope.row.actWeight ? parseFloat((scope.row.actWeight/1000 ? scope.row.actWeight/1000 : 0)).toFixed(3) : '-' }}
          </template>
        </el-table-column>
        <el-table-column
            prop="length"
            label="长度"
            align="center">
        </el-table-column>
        <el-table-column
            prop="branchCount"
            label="支数"
            align="center">
        </el-table-column>
        <el-table-column
            prop="pileName"
            label="垛位"
            align="center">
        </el-table-column>
        <el-table-column
            prop="stockName"
            label="库存名称"
            align="center"
            width="100">
        </el-table-column>
        <el-table-column
            prop="stockTime"
            label="入库时间"
            align="center"
            width="170">
          <template #default="scope">
            {{ scope.row.stockTime ? dateYMDhmsFormat(scope.row.stockTime) : '-' }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="elp"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="currPage"
                     :page-sizes="[10,20,30,40]"
                     :page-size="pageSize"
                     :total="total"
                     layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
const echarts = require('echarts')
export default {
  name: "pieChart",
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      currPage: 1,
      pageSize: 10
    }
  },
  props: {
    pieChartData: {
      type: Array,
      default: () => [
        {value: 0, name: '库存余量'},
        {value: 0, name: '已用库存'}
      ]
    },
    pileId: {
      type: [Number, String]
    },
    stockAddrId: {
      type: [Number, String]
    }
  },
  watch: {
    pieChartData: {
      handler(newVal, oldVal) {
        console.log(newVal)
        this.dataColumnar();
      },
      immediate: false,
      deep: true
    }
  },
  mounted() {
    this.dataColumnar();
  },
  methods: {
    //饼状图
    dataColumnar() {
      //初始化图表，this.$refs.homeEcharts获取到图表容器
      console.log(this.Columnar)
      if (this.Columnar) {
        this.Columnar && this.Columnar.dispose();
      }
      let Columnar = echarts.init(this.$refs.Columnar);
      this.Columnar = Columnar;
      // 绘制图表
      Columnar.setOption({
        tooltip: {

          trigger: 'item'
        },

        series: [
          {
            name: '库存',
            type: 'pie',
            radius: '50%',
            data: this.pieChartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
        ],
      });
    },
    //详情
    detailsClick() {
      this.getDetails(1, 10);
      this.dialogVisible = true;
    },
    // 分页
    table_index(index) {
      return (this.currPage - 1) * this.pageSize + index + 1
    },
    handleSizeChange: function (pageSize) {
      this.pageSize = pageSize;
      this.handleCurrentChange(this.currPage);
    },
    handleCurrentChange: function (currPage) {
      this.currPage = currPage;
      this.getDetails(this.currPage, this.pageSize);
    },
    getDetails(currPage, pageSize) {
      this.postRequest("/bus/tb-stock-carry-forward/realTimeInventoryMonitoringDetails", {
        current: currPage,
        size: pageSize,
        pileId: this.pileId,
        stockAddrId: this.stockAddrId
      }).then(res => {
        if (res.success) {
          this.dataList = res.data.records;
          this.total = res.data.total
          this.$message.success('查询成功')
        }
      })
    }
  }
}
</script>

<style scoped>
.columnar {
  height: 230px;
  width: 280px;
  float: left;
}
</style>
